import { styled } from '@mui/material';

export const WeatherSlideWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1.5),
  borderRadius: '12px',
  backgroundColor: theme.palette.white.main,

  [theme.breakpoints.down('desktop')]: {
    '& .MuiTypography-bodyXLStrong': {
      fontSize: '16px',
    },
  },
}));
