import { useContext } from 'react';

import {
  ComparisonChartContext,
  ComparisonChartFormValues,
} from './ComparisonChartContext';
import { ComparisonChartProvider } from './ComparisonChartProvider';

const useComparisonChartContext = () => {
  const context = useContext(ComparisonChartContext);

  if (typeof context === 'undefined' || context === null) {
    throw new Error(
      'useComparisonChartContext must be used within a ComparisonChartProvider',
    );
  }

  return context;
};

export default useComparisonChartContext;
export { ComparisonChartProvider };
export type { ComparisonChartFormValues };
