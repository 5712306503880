import { styled } from '@mui/material';

import publicPath from 'utils/publicPath';

export const NotFoundWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  margin: theme.spacing(0, -7.5),
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${publicPath('/images/not-found-background.png')})`,

  [theme.breakpoints.down('desktop')]: {
    margin: 0,
    padding: theme.spacing(0, 6.5),
    backgroundImage: `url(${publicPath(
      '/images/not-found-background-tablet.png',
    )})`,
  },

  [theme.breakpoints.down('tablet')]: {
    padding: theme.spacing(0, 2.5),
    backgroundImage: `url(${publicPath(
      '/images/not-found-background-mobile.png',
    )})`,
  },
}));
