import { styled } from '@mui/material';

export const WeatherDailyItem = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '33% 24% minmax(30%, auto)',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(0, 4),
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },

  '& > *:nth-child(1)': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    [theme.breakpoints.down('desktop')]: {
      gap: theme.spacing(2),
    },
  },

  '& > *:nth-child(2)': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    [theme.breakpoints.down('desktop')]: {
      display: 'none',
    },
  },

  '& > *:nth-child(3)': {
    display: 'grid',
    gridTemplateColumns: '50% 17.5% 17.5%',
    gap: theme.spacing(2),
    '& > *:not(:first-of-type)': {
      [theme.breakpoints.down('desktop')]: {
        paddingLeft: 0,
      },
    },

    [theme.breakpoints.down('desktop')]: {
      gridTemplateColumns: '60px 60px',
      justifyContent: 'flex-end',
      columnGap: theme.spacing(2),
      '& > * > *:nth-child(2)': {
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
      },
    },
  },

  [theme.breakpoints.down('desktop')]: {
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(1.5),
    padding: theme.spacing(0, 3),
  },

  [theme.breakpoints.down('tablet')]: {
    gridTemplateColumns: '140px auto',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1, 2),
  },
}));
