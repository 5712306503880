import { TranslationFunction } from 'types/general';

export function convertWh(
  w: number,
  t: TranslationFunction,
  precision: number = 3,
): { value: string; label: string } {
  if (Math.abs(w) <= 1000)
    return { value: w.toFixed(precision), label: t('converters.Wh') };
  if (Math.abs(w) < 1_000_000)
    return {
      value: (w / 1_000).toFixed(precision),
      label: t('converters.kWh'),
    };
  if (Math.abs(w) < 1_000_000_000_000)
    return {
      value: (w / 1_000_000).toFixed(precision),
      label: t('converters.MWh'),
    };
  else
    return {
      value: (w / 1_000_000_000_000).toFixed(precision),
      label: t('converters.TWh'),
    };
}
