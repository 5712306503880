import { Typography } from '@mui/material';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useNotificationNavigation } from './helpers';
import { WTLink } from './WTLink';
import CommonButton from 'components/common/CommonButton';
import routePaths from 'constants/routePaths';
import { AssetErrorFiredDto } from 'openapi-api/admin-service';
import { getStringFromISODate } from 'utils/functions/getStringFromISODate';
import { getWindTurbinePageLink } from 'utils/functions/getWindTurbinePageLink';

interface Props {
  details: AssetErrorFiredDto;
  extended: boolean;
  locationId?: number | string;
}

const TemplateAssetErrorFired: FC<Props> = ({
  details,
  extended,
  locationId,
}) => {
  const { publicId, eventDateTime, assetNameForAdmin } = details;
  const { t } = useTranslation();

  const navigateFromNotification = useNotificationNavigation(
    routePaths.viewTags(publicId || '', eventDateTime || ''),
    extended,
  );

  if (!publicId) {
    return null;
  }

  return (
    <>
      <Typography variant={extended ? 'bodyM' : 'bodyS'}>
        <Trans
          i18nKey="notifications.assetErrorFired"
          values={{ eventDateTime: getStringFromISODate(eventDateTime) }}
          components={[
            <WTLink
              key={`assetErrorFired-link-${publicId}`}
              link={
                getWindTurbinePageLink({
                  assetLocationId: locationId,
                  assetPublicId: publicId,
                }).statuses
              }
              publicId={publicId}
              linkText={assetNameForAdmin}
              newTab={extended}
            />,
          ]}
        />
      </Typography>
      <CommonButton
        sx={{ width: 'fit-content' }}
        size="medium"
        onClick={navigateFromNotification}
      >
        {t('buttons.viewTags')}
      </CommonButton>
    </>
  );
};

export default TemplateAssetErrorFired;
