import { alpha, styled } from '@mui/material';

export const WarningTextBlockWrapper = styled('div')(({ theme }) => ({
  width: 'fit-content',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: '39px',
  backgroundColor: alpha(theme.palette.warning.main, 0.2),

  [theme.breakpoints.down('tablet')]: {
    padding: theme.spacing(0.5, 1),
    borderRadius: '6px',
  },
}));
