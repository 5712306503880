import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  IMasterAccountContext,
  MasterAccountContext,
} from './MasterAccountContext';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import { isNotFoundError } from 'contexts/auth/helpers';
import { UserDetailsDtoStatusEnum } from 'openapi-api/admin-service';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

export const MasterAccountProvider: FC<PropsWithChildren> = ({ children }) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { masterAccountControllerApi } = useApi();
  const { t } = useTranslation();

  const getMasterAccountDetails = useCallback(async () => {
    if (!userId) return;

    try {
      return (
        await masterAccountControllerApi.getMasterAccountDetailsById({
          id: Number(userId),
        })
      ).data;
    } catch (error) {
      if (isNotFoundError(error)) {
        navigate(routePaths.notFound);
      }
    }
  }, [userId, masterAccountControllerApi, navigate]);

  const {
    resource: masterAccount,
    fetch,
    isLoading,
  } = useAsyncResource({
    fetchResource: getMasterAccountDetails,
  });

  const username = useMemo(() => {
    if (masterAccount?.status === UserDetailsDtoStatusEnum.DELETED) {
      return t('anonymousUser');
    }

    return masterAccount?.username || 'undefined';
  }, [masterAccount, t]);

  const contextValue = useMemo(
    (): IMasterAccountContext => ({
      masterAccount: {
        ...masterAccount,
        username,
      },
      isLoading,
      fetch,
    }),
    [masterAccount, username, fetch, isLoading],
  );

  return (
    <MasterAccountContext.Provider value={contextValue}>
      {children}
    </MasterAccountContext.Provider>
  );
};
