import { SxProps, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { WarningCoordinatesUnsetWrapper } from './styles';
import CommonButton from 'components/common/CommonButton';

interface Props {
  onButtonClick?: () => void;
  sx?: SxProps;
  dataTestId?: string;
}

export const WarningCoordinatesUnset: FC<Props> = ({
  onButtonClick,
  sx,
  dataTestId,
}) => {
  const { t } = useTranslation();

  return (
    <WarningCoordinatesUnsetWrapper sx={sx} data-testid={dataTestId}>
      <Typography variant="h5">
        {t('pages.parkDetails.coordinatesUnsetWarningText')}
      </Typography>
      <CommonButton
        onClick={onButtonClick}
        size="small"
        sx={{
          backgroundColor: 'warning.main',
          '&:hover': { backgroundColor: 'warning.main' },
        }}
      >
        {t('pages.parkDetails.editParkDetails.title')}
      </CommonButton>
    </WarningCoordinatesUnsetWrapper>
  );
};
