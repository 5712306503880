import { Box, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from './styles';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { WindTurbinesTable } from 'components/shared/WindTurbinesTable';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';

export const UnconfiguredWindTurbines: FC = () => {
  const { t } = useTranslation();
  const { locationControllerApi } = useApi();

  const getUnconfiguredWindTurbinesCount = useCallback(async () => {
    try {
      return (await locationControllerApi.getLocations({ size: 1 })).data
        .unconfiguredTurbineCount;
    } catch {
      return 0;
    }
  }, [locationControllerApi]);

  const { resource: unconfiguredWindTurbinesCount } =
    useAsyncResourceWithPulling({
      fetchResource: getUnconfiguredWindTurbinesCount,
      pullingInterval: 30,
    });

  const windTurbineCounterText = useMemo(() => {
    if (unconfiguredWindTurbinesCount === 1) {
      return `1 ${t('pages.parkDetails.windTurbinesCounter.singular')}`;
    }

    return `${unconfiguredWindTurbinesCount || 0} ${t(
      'pages.parkDetails.windTurbinesCounter.plural',
    )}`;
  }, [unconfiguredWindTurbinesCount, t]);

  return (
    <Wrapper>
      <Breadcrumbs
        items={[
          {
            label: t('pages.parks.title'),
            path: routePaths.parks.root,
          },
          {
            label: t('pages.unconfiguredWindTurbines.title'),
            path: routePaths.parks.unconfigured().root,
          },
        ]}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap={{ mobile: 3, desktop: 4 }}
        marginTop={{ mobile: 3, desktop: 5 }}
      >
        <Box>
          <Typography variant="subheading">{windTurbineCounterText}</Typography>
          <Typography variant="h1" sx={{ mt: 1.5 }}>
            {t('pages.unconfiguredWindTurbines.title')}
          </Typography>
        </Box>
        <WindTurbinesTable showUnconfigured />
      </Box>
    </Wrapper>
  );
};
