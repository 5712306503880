import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { defaultValues } from './constants';
import {
  ConnectMasterAccountDialogWrapper,
  ButtonsWrapper,
  FormWrapper,
} from './styles';
import { FormValues } from './types';
import { validationSchema } from './validationSchema';
import useApi from '../../../contexts/api';
import CommonButton from '../CommonButton';
import { NotificationPopupVariants } from '../NotificationPopup/types';
import { SelectOrCreateCustomer } from '../SelectOrCreateCustomer';
import { UserCredentials } from 'components/shared';
import useResponsePopup from 'contexts/responsePopup';
import { MasterAccountCreateResponseDto } from 'openapi-api/admin-service';
import { useUniqueEntityValidators } from 'utils/hooks/useUniqueEntityValidators';

type Props = {
  assetId?: string;
  locationName?: string;
  onClose: (e?: React.MouseEvent<Element, MouseEvent>) => void;
  onSuccess: (e?: React.MouseEvent<Element, MouseEvent>) => void;
};

export const ConnectMasterAccount: FC<Props> = ({
  assetId,
  locationName,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [showSuccess, setShowSuccess] = useState(false);
  const { openPopup, closePopup } = useResponsePopup();
  const { assetOnboardingControllerApi, masterAccountControllerApi } = useApi();
  const [createdUser, setCreatedUser] =
    useState<MasterAccountCreateResponseDto | null>(null);

  const { isUsernameExist } = useUniqueEntityValidators();

  const schema = useMemo(() => {
    return validationSchema(t, isUsernameExist);
  }, [isUsernameExist, t]);

  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    formState: { isDirty },
    handleSubmit,
    trigger,
  } = form;

  const handleClose = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      reset();
      onClose(e);
    },
    [reset, onClose],
  );

  const createMasterAccount = useCallback(
    async (username: string) => {
      if (!username) return;

      const { data } = await masterAccountControllerApi.createMasterAccount({
        masterAccountCreateRequestDto: { name: username },
      });

      setCreatedUser(data);
    },
    [masterAccountControllerApi],
  );

  const formSubmit = async (data: FormValues) => {
    try {
      if (!createdUser && data.newCustomer) {
        await createMasterAccount(data.newCustomer);
      }

      const masterAccountName = (data.existingCustomer ||
        data.newCustomer) as string;

      await assetOnboardingControllerApi.addMappingNew({
        customerMappingNewDto: {
          masterAccountName,
          ...(assetId && { publicIds: [assetId] }),
          ...(locationName && { locationNames: [locationName] }),
        },
      });
      setShowSuccess(true);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.message) {
        const message: string = e.response.data.message;
        toast.error(message);

        if (message.endsWith('already exists.')) {
          if (message.startsWith('Customer')) {
            await trigger('newCustomer');
          }
        }
      }
    }
  };

  useEffect(() => {
    if (showSuccess) {
      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('components.masterAccountConnectionSuccess.title'),
        children: createdUser ? (
          <UserCredentials
            variant="customer"
            username={createdUser.name}
            password={createdUser.password}
          />
        ) : undefined,
        primaryButton: {
          text: t('continueSession'),
          onClick: () => {
            closePopup();
            setShowSuccess(false);
            setCreatedUser(null);
            onSuccess();
          },
        },
      });
    }
  }, [showSuccess, t, openPopup, closePopup, createdUser, onSuccess]);

  return (
    <ConnectMasterAccountDialogWrapper
      open={(!!assetId || !!locationName) && !showSuccess}
      onClose={handleClose}
    >
      <FormProvider {...form}>
        <FormWrapper onSubmit={handleSubmit(formSubmit)}>
          <Box height="100%" display="flex" flexDirection="column">
            <Typography variant="h2" sx={{ mb: 5 }} color="black.600">
              {t('components.connectMasterAccount.title')}
            </Typography>

            <Typography
              variant="bodyM"
              color="black.600"
              component="div"
              sx={{ pb: 3 }}
            >
              {t('components.assetOnboarding.subTitleMasterAccount')}
            </Typography>

            <SelectOrCreateCustomer />

            <ButtonsWrapper
              padding={2}
              display="flex"
              gap={2}
              justifyContent="center"
            >
              <CommonButton
                variant="contained"
                data-testid="submit"
                type="submit"
                sx={{ width: { mobile: '226px', tablet: 'fit-content' } }}
                disabled={!isDirty}
              >
                {t('buttons.submit')}
              </CommonButton>
              <CommonButton
                variant="outlined"
                onClick={handleClose}
                data-testid="close-button"
              >
                {t('cancel')}
              </CommonButton>
            </ButtonsWrapper>
          </Box>
        </FormWrapper>
      </FormProvider>
    </ConnectMasterAccountDialogWrapper>
  );
};
