import { Grid, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { ErrorReportWrapper } from './styles';
import { AssetItem } from '../Reports/components/ReportContent/styles';
import { ReportWidgets } from '../Reports/components/ReportContent/types';
import useApi from 'contexts/api';
import useAuth from 'contexts/auth';
import { getGrafanaWidgetLink } from 'utils/functions/getGrafanaWidgetLink';
import unixMsToHumanReadable from 'utils/functions/unixMsToHumanReadable';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

const errorsCount = 69;
const errorsHeight = errorsCount * 56;

export const ErrorReport = () => {
  const { publicId } = useParams();
  const [searchParams] = useSearchParams();
  const { assetControllerApi } = useApi();
  const { accessData } = useAuth();
  const { t } = useTranslation();

  const getReportPanels = useCallback(async () => {
    if (!publicId) return undefined;

    try {
      return (
        await assetControllerApi.getAdminReportGrafanaPanels({
          requestBody: [publicId],
        })
      ).data;
    } catch {}
  }, [publicId, assetControllerApi]);

  const { resource: reportPanels } = useAsyncResource({
    defaultValue: undefined,
    fetchResource: getReportPanels,
  });

  const getAsset = useCallback(async () => {
    if (!publicId) return undefined;

    try {
      return (
        await assetControllerApi.getAssetDetails({
          assetPublicIdsDto: { publicIds: [publicId] },
        })
      ).data;
    } catch {}
  }, [publicId, assetControllerApi]);

  const { resource: assets } = useAsyncResource({
    defaultValue: undefined,
    fetchResource: getAsset,
  });

  const reportPanel = useMemo(
    () => reportPanels?.[ReportWidgets.Errors]?.[0],
    [reportPanels],
  );

  return (
    <ErrorReportWrapper>
      <Grid
        container
        justifyContent="space-between"
        sx={{ mb: 4 }}
        gap={4}
        flexDirection={{ mobile: 'column', tablet: 'row' }}
      >
        <Grid>
          <Typography variant="h5" sx={{ mb: 2.5 }}>
            {t('pages.reports.content.occurredErrors')}
          </Typography>
          <Typography variant="h1">{assets?.[0]?.turbineName}</Typography>
        </Grid>
        <Grid
          display="flex"
          flexDirection="column"
          alignItems={{ mobile: 'flex-start', tablet: 'flex-end' }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            {t('pages.reports.errorPage.reportFor', {
              customerName: searchParams.get('customer'),
            })}
          </Typography>
          <Grid display="flex" gap={1.5} sx={{ mb: 1 }}>
            <Typography variant="bodySStrong">
              {t('pages.reports.errorPage.locationName')}
            </Typography>
            <Typography variant="bodyS">{assets?.[0]?.locationName}</Typography>
          </Grid>
          <Grid display="flex" gap={1.5}>
            <Typography variant="bodySStrong">
              {t('pages.reports.errorPage.reportPeriod')}
            </Typography>
            <Typography variant="bodyS">
              {unixMsToHumanReadable(searchParams.get('from'))}
              {' - '}
              {unixMsToHumanReadable(searchParams.get('to'))}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <AssetItem variant="header">
        <Typography variant="bodyS" color="grey.400">
          {t('pages.reports.errorPage.description')}
        </Typography>
        <Typography variant="bodyS" color="grey.400">
          {t('pages.reports.errorPage.percentage')}
        </Typography>
      </AssetItem>
      <iframe
        title={ReportWidgets.Errors}
        src={getGrafanaWidgetLink({
          panelId: ReportWidgets.Errors,
          panelParameters: reportPanel || {},
          authToken: accessData?.accessToken || '',
          from: Number(searchParams.get('from')),
          to: Number(searchParams.get('to')),
        })}
        height={errorsHeight}
      />
    </ErrorReportWrapper>
  );
};
