import { FC } from 'react';

import { ThreeDotsButton } from './styles';
import { DropdownMenu, MenuItemType } from 'components/common/DropdownMenu';
import { ThreeDots } from 'components/icons';

interface Props {
  items: MenuItemType[];
}

export const DesktopMenuActions: FC<Props> = ({ items }) => {
  return (
    <DropdownMenu
      title={
        <ThreeDotsButton
          variant="outlined"
          sx={{ ml: 'auto' }}
          isMobile={false}
          data-testid="desktop-actions"
        >
          <ThreeDots />
        </ThreeDotsButton>
      }
      withExpandIcon={false}
      items={items}
    />
  );
};
