import { Typography, styled } from '@mui/material';

export const NotFoundSubtitle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2.5, 0, 6.5),
  fontWeight: '400',
  textAlign: 'center',
  color: theme.palette.white.main,

  [theme.breakpoints.down('desktop')]: {
    margin: theme.spacing(1.5, 0, 5),
    fontSize: '20px',
  },
}));
