import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import axios from 'axios';
import { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FormValues } from './types';
import validationSchema from './validationSchema';
import CommonButton from 'components/common/CommonButton';
import TextField from 'components/common/form/TextField';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import { Popup } from 'components/common/Popup';
import { IPopupProps } from 'components/common/Popup/types';
import useApi from 'contexts/api';
import useResponsePopup from 'contexts/responsePopup';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

interface Props extends IPopupProps {
  locationId: number;
  onSuccess?: () => void;
}

export const EditParkDetailsPopup: FC<Props> = ({
  isOpen,
  onClose,
  locationId,
  onSuccess,
  dataTestId,
}) => {
  const { t } = useTranslation();
  const { locationControllerApi } = useApi();
  const { openPopup, closePopup } = useResponsePopup();

  const getLocation = useCallback(async () => {
    try {
      return (
        await locationControllerApi.getLocationWindTurbineDetails({
          locationId,
        })
      ).data;
    } catch {}
  }, [locationId, locationControllerApi]);

  const { resource: location } = useAsyncResource({
    fetchResource: getLocation,
    disableGlobalLoader: true,
  });

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema(t)),
    defaultValues: {
      name: '',
      coordinates: '',
    },
  });
  const {
    handleSubmit,
    register,
    reset,
    setError,
    formState: { isDirty, isSubmitSuccessful },
  } = form;

  useEffect(() => {
    if (location) {
      const name = location.locationName;
      const coordinates =
        typeof location.latitude !== 'undefined' &&
        typeof location.longitude !== 'undefined'
          ? `${location.latitude},${location.longitude}`
          : '';

      reset({ name, coordinates });
    }
  }, [location, reset]);

  const handlePopupClose = useCallback(() => {
    reset({ name: '', coordinates: '' });
    onClose();
  }, [reset, onClose]);

  const onSubmit = handleSubmit(async ({ name, coordinates }: FormValues) => {
    const [latitudeUpdated, longitudeUpdated] = coordinates
      .replace(/ /g, '')
      ?.split(',')
      .map((coordinateStr) => Number(coordinateStr));

    try {
      await locationControllerApi.update({
        locationId,
        locationUpdateDto: {
          name,
          latitude: latitudeUpdated,
          longitude: longitudeUpdated,
        },
      });

      onSuccess?.();

      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.parkDetails.editParkDetails.successTitle'),
        primaryButton: {
          text: t('buttons.continueSession'),
          onClick: closePopup,
        },
      });
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.message) {
        const message: string = e.response.data.message;

        if (
          message.startsWith('Location with name') &&
          message.endsWith('already exists.')
        ) {
          setError('name', { message: t('form.errors.uniqueParkName') });
          return;
        }

        toast.error(message);
      }
    }
  });

  useEffect(() => {
    if (!isSubmitSuccessful) return;

    handlePopupClose();
  }, [isSubmitSuccessful, handlePopupClose]);

  return (
    <Popup
      isOpen={isOpen}
      onClose={handlePopupClose}
      dataTestId="edit-park-details-popup"
    >
      <FormProvider {...form}>
        <Form style={{ height: '100%' }} onSubmit={onSubmit}>
          <Popup.InnerWrapper>
            <Popup.Content>
              <Typography variant="h2" textAlign="left" sx={{ mb: 5 }}>
                {t('pages.parkDetails.editParkDetails.title')}
              </Typography>
              <TextField
                label={t('pages.parkDetails.editParkDetails.parkNameLabel')}
                fullWidth
                {...register('name')}
                testId={`${dataTestId}-name-input`}
                sx={{ mb: 4 }}
              />
              <TextField
                label={t('pages.parkDetails.editParkDetails.coordinatesLabel')}
                fullWidth
                {...register('coordinates')}
                testId={`${dataTestId}-coordinates-input`}
              />
            </Popup.Content>
            <Popup.Buttons>
              <CommonButton
                disabled={!isDirty}
                type="submit"
                data-testid={`${dataTestId}-submit-button`}
                sx={{ width: { mobile: '226px', tablet: 'auto' } }}
              >
                {t('buttons.save')}
              </CommonButton>
              <CommonButton variant="outlined" onClick={handlePopupClose}>
                {t('buttons.cancel')}
              </CommonButton>
            </Popup.Buttons>
          </Popup.InnerWrapper>
        </Form>
      </FormProvider>
    </Popup>
  );
};
