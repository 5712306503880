import { styled } from '@mui/material';

export const UnassignedBlockWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
  padding: theme.spacing(3, 4),
  borderRadius: '12px',
  backgroundColor: theme.palette.secondaryGreen[50],

  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    gap: theme.spacing(3),
  },
}));
