import * as yup from 'yup';

import { TranslationFunction } from '../../../../types/general';

export const validationSchemaStep1 = (
  t: TranslationFunction,
  isUsernameExist: (username: string) => Promise<boolean>,
) =>
  yup.object().shape(
    {
      existingCustomer: yup
        .string()
        .when('newCustomer', ([newCustomer], schema) => {
          if (!newCustomer)
            return schema.required(t('form.errors.fieldRequired'));
          return schema;
        }),
      newCustomer: yup
        .string()
        .when('existingCustomer', ([existingCustomer], schema) => {
          if (!existingCustomer)
            return schema
              .required(t('form.errors.fieldRequired'))
              .matches(
                /^[a-zA-Z0-9][a-zA-Z0-9-_]{8,30}[a-zA-Z0-9]$/,
                t('form.errors.incorrectUsername'),
              )
              .test({
                message: t('form.errors.uniqueUsername'),
                test: async (username = '') => {
                  try {
                    const usernameExists = await isUsernameExist(username);
                    return !usernameExists;
                  } catch (e) {
                    return false;
                  }
                },
              });
          return schema;
        }),
      existingParks: yup.array(),
      existingMacAddresses: yup.array(),
      newMacAddresses: yup.array(),
    },
    [['newCustomer', 'existingCustomer']],
  );

export const validationSchemaStep2 = (t: TranslationFunction) =>
  yup.object().shape(
    {
      existingCustomer: yup.string(),
      newCustomer: yup.string(),
      existingParks: yup
        .array()
        .when(
          ['existingMacAddresses', 'newMacAddresses'],
          ([existingMacAddresses, newMacAddresses], schema) => {
            const filledNewMacsAmount = (
              newMacAddresses as { value: string }[]
            )?.filter(({ value }) => !!value).length;
            if (existingMacAddresses?.length + filledNewMacsAmount === 0)
              return schema
                .of(yup.string())
                .min(1, t('form.errors.fieldRequired'));
            return schema;
          },
        ),
      existingMacAddresses: yup
        .array()
        .when(
          ['existingParks', 'newMacAddresses'],
          ([existingParks, newMacAddresses], schema) => {
            if (
              !existingParks?.length &&
              (!newMacAddresses?.length ||
                (newMacAddresses as { value: string }[]).every(
                  ({ value }) => !value,
                ))
            )
              return schema
                .of(
                  yup
                    .string()
                    .required(t('form.errors.fieldRequired'))
                    .matches(
                      /^[0-9a-f]{2}([\\.:-])[0-9a-f]{2}([\\.:-])[0-9a-f]{2}([\\.:-])[0-9a-f]{2}([\\.:-])[0-9a-f]{2}([\\.:-])[0-9a-f]{2}$/,
                      t('form.errors.incorrectMac'),
                    ),
                )
                .min(1, t('form.errors.fieldRequired'));
            return schema;
          },
        ),
      newMacAddresses: yup
        .array()
        .when(
          ['existingParks', 'existingMacAddresses'],
          ([existingParks, existingMacAddresses], schema) => {
            if (!existingParks?.length && !existingMacAddresses?.length)
              return schema
                .of(
                  yup.object({
                    value: yup
                      .string()
                      .required(t('form.errors.fieldRequired'))
                      .matches(
                        /^[0-9a-f]{2}([\\.:-])[0-9a-f]{2}([\\.:-])[0-9a-f]{2}([\\.:-])[0-9a-f]{2}([\\.:-])[0-9a-f]{2}([\\.:-])[0-9a-f]{2}$/,
                        t('form.errors.incorrectMac'),
                      ),
                  }),
                )
                .min(1, t('form.errors.fieldRequired'));
            return schema;
          },
        )
        .test({
          message: t('form.errors.uniqueMac'),
          test: (newMacAddresses, context) => {
            if (!newMacAddresses?.length) return true;

            const allMacs = [
              ...context.parent.existingMacAddresses,
              ...newMacAddresses.map(({ value }) => value),
            ];
            if (!allMacs.length || !allMacs.every((value) => !!value)) {
              return true;
            }

            const indexesOfDuplicatedMacs = [];
            for (let i = 0; i < newMacAddresses.length; i++) {
              if (
                allMacs.findIndex((mac) => mac === newMacAddresses[i].value) !==
                allMacs.findLastIndex((mac) => mac === newMacAddresses[i].value)
              ) {
                indexesOfDuplicatedMacs.push(i);
              }
            }

            if (!indexesOfDuplicatedMacs.length) return true;

            return new yup.ValidationError(
              indexesOfDuplicatedMacs.map(
                (macIndex) =>
                  new yup.ValidationError([
                    context.createError({
                      path: `${context.path}.${macIndex}.value`,
                    }),
                  ]),
              ),
            );
          },
        }),
    },
    [
      ['existingParks', 'existingMacAddresses'],
      ['existingParks', 'newMacAddresses'],
      ['newMacAddresses', 'existingMacAddresses'],
    ],
  );

export const validationSchemas = [validationSchemaStep1, validationSchemaStep2];
