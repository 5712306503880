import { alpha, styled } from '@mui/material';

export const WeatherPreviewWrapper = styled('div', {
  shouldForwardProp: (propName) => propName !== 'noData',
})<{ noData: boolean }>(({ theme, noData }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  padding: theme.spacing(2, 3.5),
  backgroundColor: noData
    ? alpha(theme.palette.grey[900], 0.05)
    : 'transparent',

  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(1.5, 3),
  },
}));
