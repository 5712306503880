import { styled } from '@mui/material/styles';
import { Form } from 'react-router-dom';

export const FormWrapper = styled(Form)(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  [theme.breakpoints.down('tablet')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));
