import { styled } from '@mui/material';

import CommonButton from 'components/common/CommonButton';

export const CommonButtonStyled = styled(CommonButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondaryGreen[100],

  '&:hover': {
    backgroundColor: theme.palette.secondaryGreen[200],
  },
}));
