import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ConditionIconStyled,
  HumidityIcon,
  ParametersWrapper,
  UviIcon,
  WeatherHourlyWrapper,
  WindIcon,
} from './styles';
import { getUvIndexTitle } from '../WeatherDaily/helpers';
import { WeatherSlide } from '../WeatherSlide';
import { WeatherSlider } from '../WeatherSlider';
import {
  ConditionTitle,
  DegreesCelcius,
  WindDirection,
} from 'components/shared/Weather';
import { useParkContext } from 'contexts/park';

export const WeatherHourly: FC = () => {
  const { t } = useTranslation();
  const { weather } = useParkContext();

  const getWeatherUvTitle = useCallback(
    (index?: number) => getUvIndexTitle({ index, t }),
    [t],
  );

  const slides = useMemo(
    () =>
      weather?.hourForecast?.map((item) => ({
        key: item.datetime,
        component: <WeatherSlide {...item} />,
      })),
    [weather?.hourForecast],
  );

  const sliderStartIndex = useMemo(() => {
    return (
      weather?.hourForecast?.findIndex((item) => {
        return dayjs(item.datetime).isSame(weather.datetime, 'hour');
      }) || 0
    );
  }, [weather]);

  return (
    <WeatherHourlyWrapper>
      <Box display="flex" flexDirection="column" gap={2.5}>
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              alignItems="center"
              gap={{ mobile: 1, desktop: 1.75 }}
            >
              <DegreesCelcius
                degrees={weather?.temperatureC}
                variant="h2"
                fontSize="36px"
              />
              <ConditionIconStyled
                iconLink={weather?.conditionIcon}
                isDay={weather?.isDay}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              alignSelf={{ mobile: 'flex-start', desktop: 'auto' }}
            >
              <Typography variant="bodyXS" color="grey.500">
                {dayjs(weather?.datetime).format('HH:mm:ss')}
              </Typography>
              <ConditionTitle
                variant="bodyM"
                conditionCode={weather?.conditionCode}
                isDay={weather?.isDay}
                sx={{ display: { mobile: 'none', desktop: 'block' } }}
              />
            </Box>
          </Box>
          <Box
            display={{ mobile: 'flex', desktop: 'none' }}
            alignItems="center"
            gap={1.5}
            sx={{ mt: 1 }}
          >
            <ConditionTitle
              variant="bodyS"
              conditionCode={weather?.conditionCode}
              isDay={weather?.isDay}
            />
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography variant="bodyM">
                {`${weather?.windDegree}\u00b0`}
              </Typography>
              <WindDirection
                direction={weather?.windDirection}
                degree={weather?.windDegree}
                typographySx={{ typography: 'bodyS' }}
              />
            </Box>
          </Box>
        </Box>
        <ParametersWrapper>
          <WindIcon sx={{ flexShrink: 0 }} />
          <Box display="flex" gap={1.5} sx={{ ml: 1.5 }}>
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography
                sx={{ typography: { mobile: 'bodyM', desktop: 'bodyL' } }}
              >
                {`${weather?.windSpeedMps} ${t('weather.windSpeedMps')}`}
              </Typography>
              <Typography variant="bodyXS" color="grey.600">
                {t('weather.windSpeed')}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography
                sx={{ typography: { mobile: 'bodyM', desktop: 'bodyL' } }}
              >
                {`${weather?.gustMps} ${t('weather.windSpeedMps')}`}
              </Typography>
              <Typography variant="bodyXS" color="grey.600">
                {t('weather.gust')}
              </Typography>
            </Box>
            <Box
              display={{ mobile: 'none', desktop: 'flex' }}
              alignItems="center"
              gap={0.5}
            >
              <Typography
                sx={{ typography: { mobile: 'bodyM', desktop: 'bodyL' } }}
              >
                {`${weather?.windDegree}\u00b0`}
              </Typography>
              <WindDirection
                direction={weather?.windDirection}
                degree={weather?.windDegree}
                typographySx={{ typography: 'bodyL' }}
              />
            </Box>
          </Box>
          <Box display="flex" gap={4} sx={{ ml: 4 }}>
            <Box display="flex" alignItems="center" gap={1.5}>
              <HumidityIcon />
              <Typography
                sx={{ typography: { mobile: 'bodyM', desktop: 'bodyL' } }}
              >{`${weather?.humidity}%`}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1.5}>
              <UviIcon />
              <Typography
                sx={{ typography: { mobile: 'bodyM', desktop: 'bodyL' } }}
              >
                {`${weather?.uv} ${getWeatherUvTitle(weather?.uv)}`}
              </Typography>
            </Box>
          </Box>
        </ParametersWrapper>
      </Box>
      <Box maxWidth="100%">
        <WeatherSlider
          slides={slides || []}
          options={{
            active: true,
            containScroll: 'trimSnaps',
            startIndex: sliderStartIndex,
          }}
        />
      </Box>
    </WeatherHourlyWrapper>
  );
};
