import { styled } from '@mui/material';

export const TimeFrameSubtitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2.5),

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    alignItems: 'baseline',
  },
}));
