import { SxProps, Typography } from '@mui/material';
import { PropsWithChildren, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyIdBlockWrapper } from './styles';
import { Copy, CopySuccess } from 'components/icons';
import { useCopyToClipboard } from 'utils/hooks/useCopyToClipboard';

interface Props {
  label: string;
  value: string;
  sx?: SxProps;
  className?: string;
}

export const CopyIdBlock = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ label, value, sx, className, ...other }, ref) => {
    const { t } = useTranslation();
    const { copied, copyText } = useCopyToClipboard(value);

    if (!value) return null;

    return (
      <CopyIdBlockWrapper
        onClick={(e) => {
          e.preventDefault();
          copyText();
        }}
        sx={sx}
        className={className}
        ref={ref}
        {...other}
      >
        <Typography variant="bodySStrong">
          {copied ? t('copied') : label}
        </Typography>
        {copied ? (
          <CopySuccess height={20} width={20} />
        ) : (
          <Copy height={20} width={20} />
        )}
      </CopyIdBlockWrapper>
    );
  },
);
