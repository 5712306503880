import {
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC, useCallback, MouseEvent, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { WarningEditParkDetails, WeatherPreview } from './components';
import {
  BadgeStyled,
  MainContentWrapper,
  ParkItemsCounter,
  Wrapper,
} from './styles';
import { ParkItemState } from './types';
import { EnergyCounter } from '../EnergyCounter';
import { BadgeSize, BadgeVariant } from 'components/common/Badge/types';
import CommonButton from 'components/common/CommonButton';
import { ChevronRightL } from 'components/icons';
import { RolePermissionWrapper } from 'components/shared';
import { CopyIdBlock } from 'components/shared/CopyIdBlock';
import { WarningTextBlock } from 'components/shared/WarningTextBlock';
import routePaths from 'constants/routePaths';
import {
  LocationWithWindTurbineMetricsDto,
  UserDetailsDtoUserRoleEnum,
  WindTurbineDtoOperationalStatusEnum,
  WindTurbineDtoStatusEnum,
} from 'openapi-api/admin-service';
import { convertWh } from 'utils/functions/converters';
import { getShortUUID } from 'utils/functions/getShortUUID';

const ParkStatesBadgeMapping = {
  [ParkItemState.Active]: BadgeVariant.Active,
  [ParkItemState.Error]: BadgeVariant.Error,
  [ParkItemState.Inactive]: BadgeVariant.Inactive,
};

interface Props {
  data: LocationWithWindTurbineMetricsDto;
  isUnassigned?: boolean;
  onEditParkDetailsButtonClick: (locationId?: number) => void;
  onConnectMasterAccountClick?: (locationName?: string) => void;
}

export const ParkCard: FC<Props> = ({
  data,
  isUnassigned,
  onEditParkDetailsButtonClick,
  onConnectMasterAccountClick,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    locationId,
    locationName,
    turbineCount,
    activeStatusCount,
    inactiveStatusCount,
    errorStatusCount,
    totalActiveEnergy,
    totalLostEnergy,
    orangePiId,
  } = data;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  const onBadgeClick = useCallback(
    (e: MouseEvent<HTMLElement>, search: string) => {
      e.preventDefault();
      navigate({
        pathname: routePaths.parks.specific(locationId as number).root,
        search,
      });
    },
    [navigate, locationId],
  );

  const activeEnergy = useMemo(
    () => convertWh(totalActiveEnergy || 0, t),
    [totalActiveEnergy, t],
  );

  const lostEnergy = useMemo(
    () => convertWh(totalLostEnergy || 0, t),
    [totalLostEnergy, t],
  );

  const badges = useMemo(
    () =>
      !activeStatusCount && !inactiveStatusCount && !errorStatusCount ? null : (
        <Box
          display="flex"
          gap={{ mobile: 1, desktop: 0.5 }}
          mt={{ mobile: 3, desktop: 0 }}
        >
          {!!activeStatusCount && (
            <BadgeStyled
              key={ParkItemState.Active}
              variant={ParkStatesBadgeMapping[ParkItemState.Active]}
              size={BadgeSize.Meduim}
              text={
                isDesktop ? (
                  <Trans
                    i18nKey={`pages.parks.card.itemsStates.${[
                      ParkItemState.Active,
                    ]}`}
                    values={{ count: activeStatusCount }}
                  />
                ) : (
                  activeStatusCount
                )
              }
              onClick={(e) =>
                onBadgeClick(
                  e,
                  `?operationalStatus=${WindTurbineDtoOperationalStatusEnum.ACTIVATED}`,
                )
              }
              dataTestId="park-active-badge"
            />
          )}
          {!!inactiveStatusCount && (
            <BadgeStyled
              key={ParkItemState.Inactive}
              variant={ParkStatesBadgeMapping[ParkItemState.Inactive]}
              size={BadgeSize.Meduim}
              text={
                isDesktop ? (
                  <Trans
                    i18nKey={`pages.parks.card.itemsStates.${[
                      ParkItemState.Inactive,
                    ]}`}
                    values={{ count: inactiveStatusCount }}
                  />
                ) : (
                  inactiveStatusCount
                )
              }
              onClick={(e) =>
                onBadgeClick(
                  e,
                  `?operationalStatus=${WindTurbineDtoOperationalStatusEnum.DEACTIVATED}`,
                )
              }
              dataTestId="park-inactive-badge"
            />
          )}
          {!!errorStatusCount && (
            <BadgeStyled
              key={ParkItemState.Error}
              variant={ParkStatesBadgeMapping[ParkItemState.Error]}
              size={BadgeSize.Meduim}
              text={
                isDesktop ? (
                  <Trans
                    i18nKey={`pages.parks.card.itemsStates.${[
                      ParkItemState.Error,
                    ]}`}
                    values={{ count: errorStatusCount }}
                  />
                ) : (
                  errorStatusCount
                )
              }
              onClick={(e) =>
                onBadgeClick(e, `?status=${WindTurbineDtoStatusEnum.ERROR}`)
              }
              dataTestId="park-error-badge"
            />
          )}
        </Box>
      ),
    [
      activeStatusCount,
      inactiveStatusCount,
      errorStatusCount,
      onBadgeClick,
      isDesktop,
    ],
  );

  const isParkNameUnset = useMemo(
    () => locationName === orangePiId,
    [locationName, orangePiId],
  );

  return (
    <Wrapper isParkNameUnset={isParkNameUnset}>
      <MainContentWrapper>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {isDesktop && badges}
          <ParkItemsCounter>
            <Typography variant="bodyMStrong">
              <Trans
                i18nKey="pages.parks.card.items"
                values={{ itemsCount: turbineCount }}
              />
            </Typography>
            <ChevronRightL />
          </ParkItemsCounter>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={1.5}
          sx={{ mt: { mobile: 1, desktop: 3 } }}
        >
          <Box
            display="flex"
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems={{ mobile: 'flex-start', desktop: 'center' }}
            gap={1.5}
          >
            <Typography variant="h3">
              {isParkNameUnset
                ? getShortUUID(locationName || 'undefined')
                : locationName}
            </Typography>
            {isParkNameUnset && (
              <WarningTextBlock>{t('warningDefaultParkName')}</WarningTextBlock>
            )}
          </Box>
          {orangePiId && (
            <Tooltip title={orangePiId}>
              <CopyIdBlock
                label={`ID: ${getShortUUID(orangePiId)}`}
                value={orangePiId}
                sx={{ backgroundColor: 'unset', padding: 0 }}
              />
            </Tooltip>
          )}
        </Box>
        {!isDesktop && badges}
        <Box
          display="grid"
          gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
          gap={0.75}
          mt={{ mobile: 3, desktop: 2.5 }}
        >
          <EnergyCounter
            variant="parkCard"
            counterType="activeEnergy"
            label={`${t('pages.parks.card.activeEnergy')}, ${
              activeEnergy.label
            }`}
            value={activeEnergy.value}
            tooltipTitle={t('pages.parkDetails.activeEnergyTooltip')}
          />
          <EnergyCounter
            variant="parkCard"
            counterType="lostEnergy"
            label={`${t('pages.parks.card.lostEnergy')}, ${lostEnergy.label}`}
            value={lostEnergy.value}
            tooltipTitle={t('pages.parkDetails.lostEnergyTooltip')}
          />
        </Box>
        {isUnassigned && (
          <RolePermissionWrapper
            disallowedRoles={[UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER]}
          >
            <CommonButton
              size="small"
              onClick={(e) => {
                e.preventDefault();
                onConnectMasterAccountClick?.(data.locationName);
              }}
              sx={{ width: 'fit-content', mt: { mobile: 3, desktop: 2.5 } }}
            >
              {t('buttons.connectMasterAccount')}
            </CommonButton>
          </RolePermissionWrapper>
        )}
      </MainContentWrapper>
      {isParkNameUnset ? (
        <WarningEditParkDetails
          onButtonClick={() => onEditParkDetailsButtonClick(data.locationId)}
        />
      ) : (
        <WeatherPreview weather={data.weather} />
      )}
    </Wrapper>
  );
};
