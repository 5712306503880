import { UserDetailsDtoStatusEnum } from 'openapi-api/admin-service';
import { TranslationFunction } from 'types/general';

export const getUserStatusTranslate = ({
  status,
  t,
}: {
  status: UserDetailsDtoStatusEnum;
  t: TranslationFunction;
}): string => {
  switch (status) {
    case UserDetailsDtoStatusEnum.ACTIVE:
      return t('pages.users.statuses.active');
    case UserDetailsDtoStatusEnum.INACTIVE:
      return t('pages.users.statuses.inactive');
    case UserDetailsDtoStatusEnum.DELETED:
      return t('pages.users.statuses.deleted');
    default:
      return 'undefined';
  }
};
