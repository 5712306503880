import { ReactNode } from 'react';

import { MenuItemType } from '../DropdownMenu/DropdownMenu';

export enum TabsComponentVariant {
  Menu = 'menu',
  Tabs = 'tabs',
}

export type TabType = {
  value: string;
  label: ReactNode;
  disabled?: boolean;
  submenu?: MenuItemType[];
  onClick?: () => void;
};
