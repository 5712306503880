import { styled } from '@mui/material';

export const AccessDeniedWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  margin: theme.spacing(0, -7.5),

  [theme.breakpoints.down('desktop')]: {
    margin: 0,
    padding: theme.spacing(0, 6.5),
  },

  [theme.breakpoints.down('tablet')]: {
    padding: theme.spacing(0, 2.5),
  },
}));
