import {
  Box,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC } from 'react';

import { WindTurbineParametersWrapper } from './styles';
import { WIDGETS } from '../../constants';
import { GrafanaIframe } from '../GrafanaIframe';
import { InfoTooltip } from 'components/common/InfoTooltip';

interface Props {
  name: string;
  widgetsInfoItems: { [key: string]: { title: string; tooltip: string } }[];
  widgets: WIDGETS[];
  widgetsMobile: WIDGETS[];
  sx?: SxProps;
}

export const WindTurbineParameters: FC<Props> = ({
  name,
  widgets,
  widgetsInfoItems,
  widgetsMobile,
  sx,
}) => {
  const theme = useTheme();

  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <Box display="flex" flexDirection="column" gap={{ mobile: 3, desktop: 5 }}>
      <Typography variant="h4">{name}</Typography>
      <Box display="grid" gap={{ mobile: 4, desktop: 5 }} sx={sx}>
        {(isTabletOrMobile ? widgetsMobile : widgets).map((widget, index) => {
          return (
            <WindTurbineParametersWrapper key={widget}>
              <Box display="flex" flexDirection="column" gap={0.375}>
                {Object.values(widgetsInfoItems[index]).map((item) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                    height="71px"
                  >
                    <Typography variant="h5">{item.title}</Typography>
                    <InfoTooltip title={item.tooltip} />
                  </Box>
                ))}
              </Box>
              <Box overflow="hidden">
                <GrafanaIframe panelId={widget} />
              </Box>
            </WindTurbineParametersWrapper>
          );
        })}
      </Box>
    </Box>
  );
};
