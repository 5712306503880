import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { IAdminUserContext, AdminUserContext } from './AdminUserContext';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import { isNotFoundError } from 'contexts/auth/helpers';
import { UserDetailsDtoStatusEnum } from 'openapi-api/admin-service';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

export const AdminUserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { adminUserControllerApi } = useApi();
  const { t } = useTranslation();

  const getAdminUserDetails = useCallback(async () => {
    if (!userId) return;

    try {
      return (
        await adminUserControllerApi.getAdminUserDetailsById({
          id: Number(userId),
        })
      ).data;
    } catch (error) {
      if (isNotFoundError(error)) {
        navigate(routePaths.notFound);
      }
    }
  }, [userId, adminUserControllerApi, navigate]);

  const {
    resource: adminUser,
    fetch,
    isLoading,
  } = useAsyncResource({
    fetchResource: getAdminUserDetails,
  });

  const username = useMemo(() => {
    if (adminUser?.status === UserDetailsDtoStatusEnum.DELETED) {
      return t('anonymousUser');
    }

    return adminUser?.username || 'undefined';
  }, [adminUser, t]);

  const contextValue = useMemo(
    (): IAdminUserContext => ({
      adminUser: {
        ...adminUser,
        username,
      },
      isLoading,
      fetch,
    }),
    [adminUser, username, fetch, isLoading],
  );

  return (
    <AdminUserContext.Provider value={contextValue}>
      {children}
    </AdminUserContext.Provider>
  );
};
