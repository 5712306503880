import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { IReportContext, IReportValues, ReportContext } from './ReportContext';
import useApi from 'contexts/api';
import { HistoryLogsExistCheckResponseDto } from 'openapi-api/admin-service';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

const ReportProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    assetControllerApi,
    historyLogControllerApi,
    reportRequestControllerApi,
  } = useApi();
  const [reportValues, setReportValues] = useState<IReportValues>();

  const assetPublicIds = useMemo(
    (): string[] =>
      reportValues?.assets
        .map((asset) => asset.publicId)
        .filter((publicAssetId): publicAssetId is string => !!publicAssetId) ||
      [],
    [reportValues?.assets],
  );

  const getReportPanels = useCallback(async () => {
    if (!reportValues) return undefined;

    try {
      return (
        await assetControllerApi.getAdminReportGrafanaPanels({
          requestBody: assetPublicIds,
        })
      ).data;
    } catch {}
  }, [reportValues, assetPublicIds, assetControllerApi]);

  const { resource: reportPanels } = useAsyncResource({
    defaultValue: undefined,
    fetchResource: getReportPanels,
  });

  const getReportMissingDataIntervals = useCallback(async () => {
    if (!reportValues?.period.from && !reportValues?.period.to) {
      return [];
    }

    try {
      return (
        await reportRequestControllerApi.getReportMissingDataIntervals({
          reportMissingDataCheckDto: {
            assetPublicIds,
            startDatetime: reportValues?.period.from,
            endDatetime: reportValues?.period.to,
          },
        })
      ).data;
    } catch {
      return [];
    }
  }, [reportValues, assetPublicIds, reportRequestControllerApi]);

  const { resource: reportMissingDataIntervals } = useAsyncResource({
    defaultValue: [],
    fetchResource: getReportMissingDataIntervals,
  });

  const getReportAssetsHasHistoryLogs = useCallback(async () => {
    if (!reportValues?.period.from && !reportValues?.period.to) {
      return {};
    }

    try {
      const hasHistoryLogsResponse = (
        await historyLogControllerApi.hasHistoryLogs({
          historyLogsRequestDto: {
            assetPublicIds,
            fromCreateDatetime: reportValues?.period.from,
            toCreateDatetime: reportValues?.period.to,
          },
          type: 'STATUSES_AND_ERRORS',
        })
      ).data;

      return hasHistoryLogsResponse.reduce(
        (
          hasHistoryLogs: {
            [publicId: string]: boolean;
          },
          hasHistoryLogResponseItem: HistoryLogsExistCheckResponseDto,
        ) =>
          hasHistoryLogResponseItem.publicId
            ? {
                ...hasHistoryLogs,
                [hasHistoryLogResponseItem.publicId]:
                  !!hasHistoryLogResponseItem.hasHistoryLog,
              }
            : hasHistoryLogs,
        {},
      );
    } catch {
      return {};
    }
  }, [
    reportValues?.period.from,
    reportValues?.period.to,
    historyLogControllerApi,
    assetPublicIds,
  ]);

  const { resource: reportAssetsHasHistoryLogs } = useAsyncResource({
    defaultValue: {},
    fetchResource: getReportAssetsHasHistoryLogs,
  });

  const contextValue = useMemo(
    (): IReportContext => ({
      reportValues,
      setReportValues,
      reportPanels,
      reportMissingDataIntervals,
      reportAssetsHasHistoryLogs,
      assetPublicIds,
    }),
    [
      reportValues,
      reportPanels,
      reportMissingDataIntervals,
      reportAssetsHasHistoryLogs,
      assetPublicIds,
    ],
  );

  return (
    <ReportContext.Provider value={contextValue}>
      {children}
    </ReportContext.Provider>
  );
};

export default ReportProvider;
