import { styled } from '@mui/material';

import { Badge } from 'components/common/Badge';

export const BadgeStyled = styled(Badge)(({ theme }) => ({
  height: '24px',
  gap: theme.spacing(1),
  padding: theme.spacing(0, 1.5, 0, 0.625),

  [theme.breakpoints.down('desktop')]: {
    height: 'auto',
    gap: theme.spacing(1.5),
    padding: theme.spacing(0.5, 1.5, 0.5, 1),
  },
}));
