import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormValueLabel = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('desktop')]: {
    flexBasis: '160px',
  },
  [theme.breakpoints.down('tablet')]: {
    flexBasis: '112px',
  },
}));
