/* eslint-disable react/jsx-no-literals */
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'react-router-dom';

import CommonButton from '../../../common/CommonButton';
import DatePicker from 'components/common/DatePicker/DatePicker';
import DateRangePicker from 'components/common/DatePicker/DateRangePicker';
import { TimePicker } from 'components/common/DatePicker/TimePicker';
import { FormSelect } from 'components/common/form/Select';
import Switch from 'components/common/form/Switch';
import { NotificationPopup } from 'components/common/NotificationPopup';
import NotificationMessage from 'components/common/Notifications/NotificationMessage';
import Tabs from 'components/common/Tabs';
import { TabsComponentVariant } from 'components/common/Tabs/types';
import { TabsSection } from 'components/common/TabsSection/TabsSection';
import { ArrowRight } from 'components/icons';
import routePaths from 'constants/routePaths';
import { AssetActivePowerDeflectionErrorDtoAllOfEventEnum } from 'openapi-api/admin-service';
import { usePopup } from 'utils/hooks/usePopup';

const DesignSystem = () => {
  const [isOpen, openPopup, closePopup] = usePopup();

  const form = useForm<{ singleSelect: string; multipleSelect: [] }>({
    defaultValues: {
      singleSelect: '',
      multipleSelect: [],
    },
  });

  const items = [
    { label: 'option 1', value: '0' },
    { label: 'option 2', value: '1' },
    { label: 'option 3', value: '2' },
    { label: 'option 4', value: '3' },
    { label: 'option 5', value: '4' },
    { label: 'option 6', value: '5' },
    { label: 'option 72', value: '6' },
    { label: 'option 8', value: '7' },
    { label: 'option 9', value: '8' },
    { label: 'option 10', value: '9' },
  ];

  return (
    <Grid>
      <FormProvider {...form}>
        <Form>
          <FormSelect
            name="singleSelect"
            label={'single select'}
            items={items}
            fullWidth
            sx={{ maxWidth: '444px', m: 2 }}
          />
          <FormSelect
            name="multipleSelect"
            label={'multiple select'}
            items={items}
            fullWidth
            multiple
            sx={{ maxWidth: '444px', m: 2 }}
          />
          <DatePicker
            label="from"
            onChange={() => undefined}
            sx={{ maxWidth: '444px', m: 2 }}
          />
          <DateRangePicker sx={{ m: 2 }} />
          <TimePicker label="Time picker" onChange={() => {}} sx={{ m: 2 }} />
        </Form>
      </FormProvider>
      <NotificationMessage
        notification={{
          details: {
            publicId: '8c:f3:19:bd:b5:1b',
            event: AssetActivePowerDeflectionErrorDtoAllOfEventEnum.ASSET_ADDED,
            eventDateTime: '2024-01-15T10:09:48.137Z',
          },
          markedAsRead: false,
        }}
        openConnectMAPopup={() => {}}
      />
      <NotificationMessage
        notification={{
          details: {
            publicId: '8c:f3:19:bd:b5:1b',
            event:
              AssetActivePowerDeflectionErrorDtoAllOfEventEnum.ASSET_CONNECTION_ERROR,
            eventDateTime: '2024-01-15T10:09:48.137Z',
          },
        }}
        openConnectMAPopup={() => {}}
      />
      <NotificationMessage
        notification={{
          details: {
            publicId: '8c:f3:19:bd:b5:1b',
            event:
              AssetActivePowerDeflectionErrorDtoAllOfEventEnum.ASSET_ERROR_FIRED,
            eventDateTime: '2024-01-15T10:09:48.137Z',
          },
          markedAsRead: false,
        }}
        openConnectMAPopup={() => {}}
      />
      <NotificationMessage
        notification={{
          details: {
            publicId: '8c:f3:19:bd:b5:1b',
            event:
              AssetActivePowerDeflectionErrorDtoAllOfEventEnum.ASSET_ERROR_RESOLVED,
            eventDateTime: '2024-01-15T10:09:48.137Z',
          },
        }}
        openConnectMAPopup={() => {}}
      />
      <NotificationPopup
        title="Are you sure you want to disconnect customer?"
        isOpen={isOpen}
        primaryButton={{ text: 'Yes, disconnect', onClick: () => {} }}
        secondaryButton={{
          text: 'Cancel',
          onClick: () => {},
        }}
        onClose={closePopup}
      >
        <Typography variant="bodyM">
          A new wind turbine{' '}
          <Typography variant="bodyM" color="green.600">
            8c:f3:19:bd:b5:1b
          </Typography>{' '}
          is added at 11:23:34 13.09.2023!
        </Typography>
      </NotificationPopup>
      <Grid container gap={2} marginTop={3}>
        <Tabs
          componentVariant={TabsComponentVariant.Tabs}
          tabs={[
            {
              label: 'General information',
              value: routePaths.design.main + '/1',
            },
            {
              label: 'Statuses and errors',
              value: routePaths.design.main + '/2',
            },
            {
              label: 'Disabled tab',
              value: routePaths.design.main + '/3',
              disabled: true,
            },
          ]}
        />
      </Grid>
      <Grid container gap={2} marginTop={3}>
        <CommonButton variant="contained" disabled>
          Button
        </CommonButton>

        <CommonButton
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={openPopup}
        >
          Open popup
        </CommonButton>
        <CommonButton variant="contained" endIcon={<ArrowRight />} />
      </Grid>
      <Grid container gap={2} marginTop={3}>
        <Switch defaultChecked />
        <Switch />
        <Switch defaultChecked disabled />
        <Switch disabled />
      </Grid>
      <Grid gap={1} container flexDirection="column" marginTop={3}>
        <Typography variant="h1">Heading 1 Bold</Typography>
        <Typography variant="h2">Heading 2 Bold</Typography>
        <Typography variant="h3">Heading 3 Bold</Typography>
        <Typography variant="h4">Heading 4 Bold</Typography>
        <Typography variant="h5">Heading 5 Bold</Typography>
        <Typography variant="h6">Heading 6 Bold</Typography>
        <Typography variant="subheading">Heading Bold</Typography>
      </Grid>
      <Grid display="grid" gridTemplateColumns="1fr 1fr" marginTop={3}>
        <Grid container flexDirection="column">
          <Typography variant="bodyXXL">Body XXL</Typography>
          <Typography variant="bodyXL">Body XL</Typography>
          <Typography variant="bodyL">Body L</Typography>
          <Typography variant="bodyM">Body M</Typography>
          <Typography variant="bodyS">Body S</Typography>
          <Typography variant="bodyXS">Body XS</Typography>
        </Grid>
        <Grid container flexDirection="column">
          <Typography variant="bodyXXLStrong">Body XXL Strong</Typography>
          <Typography variant="bodyXLStrong">Body XL Strong</Typography>
          <Typography variant="bodyLStrong">Body L Strong</Typography>
          <Typography variant="bodyMStrong">Body M Strong</Typography>
          <Typography variant="bodySStrong">Body S Strong</Typography>
          <Typography variant="bodyXSStrong">Body XS Strong</Typography>
        </Grid>
      </Grid>
      <Grid gap={1} container flexDirection="column" marginTop={3}>
        <Typography variant="buttonPrimary">Primary button</Typography>
        <Typography variant="buttonS">Secondary button</Typography>
      </Grid>
      <Grid
        container
        flexDirection="column"
        marginTop={3}
        p={3}
        gap={3}
        bgcolor="white.main"
      >
        <Typography textAlign="center" variant="h3">
          ParametersList
        </Typography>
        <Typography textAlign="center" variant="h3">
          WindTurbineControl
        </Typography>
        <Typography textAlign="center" variant="h3">
          TabsSection
        </Typography>
        <Grid width={350}>
          <TabsSection
            tabs={[
              {
                label: <Typography variant="bodyMStrong">ERRORS</Typography>,
                value: <>ERRORS content...</>,
                key: '0',
              },
              {
                label: <Typography variant="bodyMStrong">REMINDERS</Typography>,
                value: <>REMINDERS content...</>,
                key: '1',
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DesignSystem;
