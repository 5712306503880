import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { FC, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { AddVideoStreamPopup, VideoStreamCard } from './components';
import {
  AddLinkButton,
  ItemsBlock,
  PlusIcon,
  PreloaderBlock,
  TopBlock,
  Wrapper,
} from './styles';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import { RolePermissionWrapper } from 'components/shared';
import useApi from 'contexts/api';
import useResponsePopup from 'contexts/responsePopup';
import useWindTurbine from 'contexts/WindTurbine';
import { UserDetailsDtoUserRoleEnum } from 'openapi-api/admin-service';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';

const MAX_ITEMS_COUNT = 4;

export const WindTurbineStreaming: FC = () => {
  const { t: streamingT } = useTranslation('', {
    keyPrefix: 'pages.turbine.tabs.streaming',
  });
  const { t } = useTranslation();
  const { videoStreamControllerApi } = useApi();
  const { windTurbine } = useWindTurbine();
  const { openPopup, closePopup } = useResponsePopup();

  const [isAddStreamPopupOpen, setAddStreamPopupOpen] =
    useState<boolean>(false);

  const getVideoStreams = useCallback(async () => {
    if (!windTurbine?.publicId) return;

    try {
      return (
        await videoStreamControllerApi.getVideoStreams({
          assetPublicId: windTurbine.publicId,
        })
      ).data;
    } catch {}
  }, [windTurbine?.publicId, videoStreamControllerApi]);

  const { resource: items, fetch } = useAsyncResourceWithPulling({
    fetchResource: getVideoStreams,
    pullingInterval: 30,
  });

  const deleteStream = useCallback(
    async (id: number) => {
      try {
        await videoStreamControllerApi.deleteVideoStream({ id });

        await fetch();

        openPopup({
          variant: NotificationPopupVariants.Success,
          title: streamingT('deleteStreamPopup.successTitle'),
          primaryButton: {
            text: t('buttons.continueSession'),
            onClick: closePopup,
          },
        });
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.data.message) {
          toast.error(e.response.data.message);
        }
      }
    },
    [videoStreamControllerApi, streamingT, t, openPopup, closePopup, fetch],
  );

  const onDeleteStreamClick = useCallback(
    (id?: number) => {
      if (typeof id === 'undefined') return;

      openPopup({
        variant: NotificationPopupVariants.Error,
        title: streamingT('deleteStreamPopup.title'),
        primaryButton: {
          text: streamingT('deleteStreamPopup.submitButtonText'),
          onClick: () => deleteStream(id),
        },
        secondaryButton: {
          text: t('buttons.cancel'),
          onClick: closePopup,
        },
      });
    },
    [openPopup, streamingT, t, deleteStream, closePopup],
  );

  const noItems = useMemo(() => items?.length === 0, [items]);
  const itemsLimitReached = useMemo(
    () => items?.length === MAX_ITEMS_COUNT,
    [items],
  );

  if (typeof items === 'undefined') {
    return <PreloaderBlock data-testid="preloader-block" />;
  }

  return (
    <Wrapper>
      <RolePermissionWrapper
        disallowedRoles={[UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER]}
      >
        <TopBlock>
          {noItems && (
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="h3" color="black.900">
                {streamingT('content.title')}
              </Typography>
              <Typography variant="bodyM">
                {streamingT('content.subtitle')}
              </Typography>
            </Box>
          )}
          <Box display="flex" flexDirection="column" gap={2}>
            {!itemsLimitReached && (
              <AddLinkButton
                onClick={() => setAddStreamPopupOpen(true)}
                variant={noItems ? 'contained' : 'outlined'}
              >
                {streamingT('content.buttonText')}
                <PlusIcon />
              </AddLinkButton>
            )}
            <Typography variant="bodyS" color="grey.600">
              {noItems && (
                <Trans
                  t={streamingT}
                  i18nKey="content.noItemsText"
                  values={{ maxCount: MAX_ITEMS_COUNT }}
                />
              )}
              {!noItems && items.length < MAX_ITEMS_COUNT && (
                <Trans
                  t={streamingT}
                  i18nKey="content.itemsLeftText"
                  values={{
                    currentCount: MAX_ITEMS_COUNT - items.length,
                    maxCount: MAX_ITEMS_COUNT,
                  }}
                />
              )}
              {itemsLimitReached && (
                <Trans
                  t={streamingT}
                  i18nKey="content.limitReachedText"
                  values={{ maxCount: MAX_ITEMS_COUNT }}
                />
              )}
            </Typography>
          </Box>
        </TopBlock>
      </RolePermissionWrapper>
      <ItemsBlock>
        {items.map((item) => (
          <VideoStreamCard
            key={item.id}
            data={item}
            onDeleteClick={onDeleteStreamClick}
          />
        ))}
      </ItemsBlock>
      {isAddStreamPopupOpen && (
        <AddVideoStreamPopup
          refetch={fetch}
          onClose={() => setAddStreamPopupOpen(false)}
        />
      )}
    </Wrapper>
  );
};
