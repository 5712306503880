import { Box } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  AccessDeniedImage,
  AccessDeniedInnerWrapper,
  AccessDeniedSubtitle,
  AccessDeniedTitle,
  AccessDeniedWrapper,
} from './styles';
import CommonButton from 'components/common/CommonButton';
import routePaths from 'constants/routePaths';

export const AccessDenied: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate(routePaths.parks.root);
  }, [navigate]);

  return (
    <AccessDeniedWrapper>
      <AccessDeniedInnerWrapper>
        <AccessDeniedImage />
        <Box display="flex" flexDirection="column" alignItems="center">
          <AccessDeniedTitle variant="h2">
            {t('components.accessDenied.title')}
          </AccessDeniedTitle>
          <AccessDeniedSubtitle variant="h3">
            {t('components.accessDenied.subtitle')}
          </AccessDeniedSubtitle>
          <CommonButton onClick={onButtonClick}>
            {t('components.accessDenied.buttonText')}
          </CommonButton>
        </Box>
      </AccessDeniedInnerWrapper>
    </AccessDeniedWrapper>
  );
};
