import dayjs from 'dayjs';
import { FC, useMemo } from 'react';

import { HistoryLogItem } from 'components/shared/HistoryLog';
import {
  AccelerationStatusChangedTemplate,
  ErrorCodeReceivedTemplate,
  ErrorCodeResolvedTemplate,
  OnboardingInitiatedTemplate,
  OperationalStatusChangedTemplate,
  ParkDetailsChangedTemplate,
  StatusCodeReceivedTemplate,
  StatusCodeResolvedTemplate,
  UserConnectedTemplate,
  UserDisconnectedTemplate,
  WindTurbineDeletedTemplate,
  WindTurbineDetailsChangedTemplate,
  WindTurbineStatusUpdatedTemplate,
} from 'components/shared/HistoryLog/templates/asset';
import {
  ErrorCodeReceivedDto,
  ErrorCodeResolvedDto,
  HistoryLogDto,
  HistoryLogDtoNameEnum,
  StatusCodeReceivedDto,
  StatusCodeResolvedDto,
  UserChangedAssetAccelerationStatusDto,
  UserChangedAssetOperationalStatusDto,
  UserChangedParkDetailsDto,
  UserChangedWindTurbineNameDto,
  UserConnectedToWindTurbineDto,
  UserDeletedWindTurbineDto,
  UserDisconnectedFromWindTurbineDto,
  UserInitiatedWindTurbineOnboardingDto,
  WindTurbineStatusUpdatedDto,
} from 'openapi-api/admin-service';

interface IWindTurbineHistoryLogItemProps extends HistoryLogDto {
  noBadge?: boolean;
}

export const WindTurbineHistoryLogItem: FC<IWindTurbineHistoryLogItemProps> = ({
  name,
  type,
  createDatetime,
  details,
  noBadge,
}) => {
  const eventDateTime = useMemo(
    () => dayjs(details?.eventDateTime).format('DD/MM/YYYY HH:mm:ss'),
    [details?.eventDateTime],
  );

  const content = useMemo(() => {
    switch (name) {
      case HistoryLogDtoNameEnum.USER_INITIATED_WT_ONBOARDING:
        return (
          <OnboardingInitiatedTemplate
            {...(details as UserInitiatedWindTurbineOnboardingDto)}
            eventDateTime={eventDateTime}
          />
        );
      case HistoryLogDtoNameEnum.USER_CHANGED_WT_OPERATIONAL_STATUS:
        return (
          <OperationalStatusChangedTemplate
            {...(details as UserChangedAssetOperationalStatusDto)}
            eventDateTime={eventDateTime}
          />
        );
      case HistoryLogDtoNameEnum.USER_CHANGED_WT_ACCELERATION_STATUS:
        return (
          <AccelerationStatusChangedTemplate
            {...(details as UserChangedAssetAccelerationStatusDto)}
            eventDateTime={eventDateTime}
          />
        );
      case HistoryLogDtoNameEnum.USER_CHANGED_PARK_DETAILS:
        return (
          <ParkDetailsChangedTemplate
            {...(details as UserChangedParkDetailsDto)}
            eventDateTime={eventDateTime}
          />
        );
      case HistoryLogDtoNameEnum.USER_DISCONNECTED_FROM_WT:
        return (
          <UserDisconnectedTemplate
            {...(details as UserDisconnectedFromWindTurbineDto)}
            eventDateTime={eventDateTime}
          />
        );
      case HistoryLogDtoNameEnum.USER_CONNECTED_TO_WT:
        return (
          <UserConnectedTemplate
            {...(details as UserConnectedToWindTurbineDto)}
            eventDateTime={eventDateTime}
          />
        );
      case HistoryLogDtoNameEnum.USER_CHANGED_WT_NAME:
        return (
          <WindTurbineDetailsChangedTemplate
            {...(details as UserChangedWindTurbineNameDto)}
            eventDateTime={eventDateTime}
          />
        );
      case HistoryLogDtoNameEnum.WT_STATUS_UPDATED:
        return (
          <WindTurbineStatusUpdatedTemplate
            {...(details as WindTurbineStatusUpdatedDto)}
            eventDateTime={eventDateTime}
          />
        );
      case HistoryLogDtoNameEnum.USER_DELETED_WT:
        return (
          <WindTurbineDeletedTemplate
            {...(details as UserDeletedWindTurbineDto)}
            eventDateTime={eventDateTime}
          />
        );
      case HistoryLogDtoNameEnum.STATUS_CODE_RECEIVED:
        return (
          <StatusCodeReceivedTemplate
            {...(details as StatusCodeReceivedDto)}
            eventDateTime={eventDateTime}
          />
        );
      case HistoryLogDtoNameEnum.STATUS_CODE_RESOLVED:
        return (
          <StatusCodeResolvedTemplate
            {...(details as StatusCodeResolvedDto)}
            eventDateTime={eventDateTime}
          />
        );
      case HistoryLogDtoNameEnum.ERROR_CODE_RECEIVED:
        return (
          <ErrorCodeReceivedTemplate
            {...(details as ErrorCodeReceivedDto)}
            eventDateTime={eventDateTime}
          />
        );
      case HistoryLogDtoNameEnum.ERROR_CODE_RESOLVED:
        return (
          <ErrorCodeResolvedTemplate
            {...(details as ErrorCodeResolvedDto)}
            eventDateTime={eventDateTime}
          />
        );
      default:
        return 'event name is undefined';
    }
  }, [name, details, eventDateTime]);

  return (
    <HistoryLogItem type={type} datetime={createDatetime} noBadge={noBadge}>
      {content}
    </HistoryLogItem>
  );
};
