import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { InvestigationArea, ComparisonChartForm } from './components';
import { FormPartContainer, ComparisonChartPageWrapper } from './styles';
import { ComparisonChartProvider } from 'contexts/comparisonChart';

export const ComparisonChart = () => {
  const { t } = useTranslation();

  return (
    <ComparisonChartProvider>
      <ComparisonChartPageWrapper>
        <FormPartContainer>
          <Typography
            variant="h1"
            color="black.900"
            marginBottom={{ mobile: 2, desktop: 2.5 }}
          >
            {t('pages.comparisonChart.title')}
          </Typography>
          <Typography
            variant="bodyM"
            color="grey.700"
            display="block"
            marginBottom={{ mobile: 3, desktop: 4 }}
          >
            {t('pages.comparisonChart.subtitle')}
          </Typography>
          <ComparisonChartForm />
        </FormPartContainer>
        <InvestigationArea />
      </ComparisonChartPageWrapper>
    </ComparisonChartProvider>
  );
};
