import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { History, GenerateInvestigationForm } from './components';
import { FormPartContainer, InvestigationPageWrapper } from './styles';
import { InvestigationProvider } from 'contexts/investigation';

const Investigation = () => {
  const { t } = useTranslation();

  return (
    <InvestigationProvider>
      <InvestigationPageWrapper>
        <FormPartContainer>
          <Typography
            variant="h1"
            color="white.main"
            marginBottom={{ mobile: 4, desktop: 5 }}
            sx={{ mb: 5 }}
          >
            {t('pages.investigation.title')}
          </Typography>
          <GenerateInvestigationForm />
        </FormPartContainer>
        <History />
      </InvestigationPageWrapper>
    </InvestigationProvider>
  );
};

export default Investigation;
