import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { BreadcrumbItem } from 'components/common/Breadcrumbs/types';
import { MenuItemType } from 'components/common/DropdownMenu';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import { UserCredentials } from 'components/shared';
import {
  ProfileContent,
  ProfilePageWrapper,
} from 'components/shared/ProfilePage';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import { useMasterAccountContext } from 'contexts/masterAccount';
import useResponsePopup from 'contexts/responsePopup';
import { UserDetailsDtoStatusEnum } from 'openapi-api/admin-service';

export const MasterAccountProfile: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { masterAccountControllerApi } = useApi();
  const { masterAccount, fetch, isLoading } = useMasterAccountContext();

  const { openPopup, closePopup } = useResponsePopup();

  const breadcrumbItems = useMemo((): BreadcrumbItem[] => {
    return [
      {
        label: t('pages.users.title'),
        path: routePaths.users.customerList.root,
      },
      ...(!isLoading && !!masterAccount?.id
        ? [
            {
              label: masterAccount.username as string,
              path: routePaths.users.customerList.profile(masterAccount?.id)
                .root,
            },
          ]
        : []),
    ];
  }, [t, isLoading, masterAccount]);

  const resetPassword = useCallback(async () => {
    if (typeof masterAccount?.id === 'undefined') return;

    try {
      const { data } =
        await masterAccountControllerApi.updateMasterAccountPassword({
          id: masterAccount.id,
        });

      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.users.resetPasswordPopup.successTitle'),
        subtitle: t('pages.users.resetPasswordPopup.successSubtitle'),
        children: (
          <UserCredentials
            variant="customer"
            username={masterAccount.username}
            password={data.password}
          />
        ),
        primaryButton: {
          text: t('continueSession'),
          onClick: closePopup,
        },
      });
    } catch {}
  }, [masterAccountControllerApi, masterAccount, openPopup, closePopup, t]);

  const deleteMasterAccount = useCallback(async () => {
    if (typeof masterAccount?.id === 'undefined') return;

    try {
      await masterAccountControllerApi.deleteMasterAccount({
        id: masterAccount.id,
      });
      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.users.deleteUserPopup.successTitle'),
        primaryButton: {
          onClick: () => {
            closePopup();
            navigate(routePaths.users.customerList.root);
          },
          text: t('buttons.continueSession'),
        },
      });
    } catch {}
  }, [
    masterAccount?.id,
    masterAccountControllerApi,
    openPopup,
    closePopup,
    navigate,
    t,
  ]);

  const restoreAccount = useCallback(async () => {
    if (typeof masterAccount?.id === 'undefined') return;

    try {
      await masterAccountControllerApi.restoreMasterAccount({
        id: masterAccount.id,
      });
      await fetch();
      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.users.restoreUserPopup.successTitle'),
        primaryButton: {
          text: t('buttons.continueSession'),
          onClick: closePopup,
        },
      });
    } catch {
      openPopup({
        variant: NotificationPopupVariants.Error,
        title: t('pages.users.restoreUserPopup.failTitle'),
        subtitle: t('pages.users.restoreUserPopup.failSubtitle'),
        secondaryButton: {
          onClick: closePopup,
          text: t('back'),
        },
      });
    }
  }, [
    masterAccount?.id,
    masterAccountControllerApi,
    fetch,
    openPopup,
    closePopup,
    t,
  ]);

  const onResetPasswordClick = useCallback(() => {
    openPopup({
      variant: NotificationPopupVariants.Error,
      title: t('pages.users.resetPasswordPopup.title'),
      subtitle: t('pages.users.resetPasswordPopup.subtitle'),
      primaryButton: {
        onClick: resetPassword,
        text: t('pages.users.resetPasswordPopup.primaryButtonText'),
      },
      secondaryButton: {
        onClick: closePopup,
        text: t('buttons.cancel'),
      },
    });
  }, [openPopup, closePopup, t, resetPassword]);

  const onDeleteAccountClick = useCallback(() => {
    openPopup({
      variant: NotificationPopupVariants.Error,
      title: t('pages.users.deleteUserPopup.customerTitle'),
      subtitle: t('pages.users.deleteUserPopup.customerSubtitle'),
      primaryButton: {
        onClick: deleteMasterAccount,
        text: t('pages.users.deleteUserPopup.primaryButtonText'),
      },
      secondaryButton: {
        onClick: closePopup,
        text: t('buttons.cancel'),
      },
    });
  }, [deleteMasterAccount, openPopup, closePopup, t]);

  const onRestoreAccountClick = useCallback(() => {
    openPopup({
      variant: NotificationPopupVariants.Error,
      title: t('pages.users.restoreUserPopup.title'),
      subtitle: t('pages.users.restoreUserPopup.customerSubtitle'),
      primaryButton: {
        text: t('pages.users.restoreUserPopup.submitButtonText'),
        onClick: restoreAccount,
      },
      secondaryButton: {
        text: t('buttons.cancel'),
        onClick: closePopup,
      },
    });
  }, [t, openPopup, closePopup, restoreAccount]);

  const actionsMenuItems = useMemo((): MenuItemType[] => {
    if (masterAccount?.status === UserDetailsDtoStatusEnum.DELETED) {
      return [];
    }

    return [
      {
        label: t('pages.users.resetPassword'),
        onClick: onResetPasswordClick,
      },
      ...(masterAccount?.status === UserDetailsDtoStatusEnum.ACTIVE
        ? [
            {
              label: t('pages.users.deleteAccount'),
              onClick: onDeleteAccountClick,
              color: 'error.main',
            },
          ]
        : [
            {
              label: t('pages.users.restoreAccount'),
              onClick: onRestoreAccountClick,
            },
          ]),
    ];
  }, [
    t,
    masterAccount?.status,
    onResetPasswordClick,
    onDeleteAccountClick,
    onRestoreAccountClick,
  ]);

  return (
    <ProfilePageWrapper>
      <Breadcrumbs items={breadcrumbItems} />
      <ProfileContent
        userDetails={masterAccount}
        actionsMenuItems={actionsMenuItems}
      />
    </ProfilePageWrapper>
  );
};
