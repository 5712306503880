import { styled } from '@mui/material';

export const NotFoundInnerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(15),
  marginTop: theme.spacing(-5.25),

  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'row',
    gap: theme.spacing(6),
    marginTop: theme.spacing(-3),
  },

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    gap: theme.spacing(11.5),
    marginTop: theme.spacing(-7.5),
  },
}));
