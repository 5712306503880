/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PageUserDetailsDto } from '../models';
// @ts-ignore
import { UserCreateRequestDto } from '../models';
// @ts-ignore
import { UserCreateResponseDto } from '../models';
// @ts-ignore
import { UserDetailsDto } from '../models';
// @ts-ignore
import { UserUpdateDto } from '../models';
// @ts-ignore
import { UserUpdatePasswordResponseDto } from '../models';
/**
 * AdminUserControllerApi - axios parameter creator
 * @export
 */
export const AdminUserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserCreateRequestDto} userCreateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminUser: async (userCreateRequestDto: UserCreateRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateRequestDto' is not null or undefined
            assertParamExists('createAdminUser', 'userCreateRequestDto', userCreateRequestDto)
            const localVarPath = `/admin-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminUser: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminUser', 'id', id)
            const localVarPath = `/admin-user/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<'ADMIN_PORTAL_SUPER_ADMIN' | 'ADMIN_PORTAL_ADMIN' | 'ADMIN_PORTAL_VIEWER' | 'USER_PORTAL_SUPER_ADMIN' | 'USER_PORTAL_ADMIN' | 'USER_PORTAL_VIEWER'>} [userRole] 
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>} [status] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserDetails: async (userRole?: Array<'ADMIN_PORTAL_SUPER_ADMIN' | 'ADMIN_PORTAL_ADMIN' | 'ADMIN_PORTAL_VIEWER' | 'USER_PORTAL_SUPER_ADMIN' | 'USER_PORTAL_ADMIN' | 'USER_PORTAL_VIEWER'>, status?: Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>, search?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userRole) {
                localVarQueryParameter['userRole'] = userRole;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserDetailsById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminUserDetailsById', 'id', id)
            const localVarPath = `/admin-user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAdminUser: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('restoreAdminUser', 'id', id)
            const localVarPath = `/admin-user/{id}/restore`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UserUpdateDto} userUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminUserDetails: async (id: number, userUpdateDto: UserUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdminUserDetails', 'id', id)
            // verify required parameter 'userUpdateDto' is not null or undefined
            assertParamExists('updateAdminUserDetails', 'userUpdateDto', userUpdateDto)
            const localVarPath = `/admin-user/{id}/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword2: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePassword2', 'id', id)
            const localVarPath = `/admin-user/{id}/password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUserControllerApi - functional programming interface
 * @export
 */
export const AdminUserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UserCreateRequestDto} userCreateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdminUser(userCreateRequestDto: UserCreateRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdminUser(userCreateRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminUser(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<'ADMIN_PORTAL_SUPER_ADMIN' | 'ADMIN_PORTAL_ADMIN' | 'ADMIN_PORTAL_VIEWER' | 'USER_PORTAL_SUPER_ADMIN' | 'USER_PORTAL_ADMIN' | 'USER_PORTAL_VIEWER'>} [userRole] 
         * @param {Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>} [status] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminUserDetails(userRole?: Array<'ADMIN_PORTAL_SUPER_ADMIN' | 'ADMIN_PORTAL_ADMIN' | 'ADMIN_PORTAL_VIEWER' | 'USER_PORTAL_SUPER_ADMIN' | 'USER_PORTAL_ADMIN' | 'USER_PORTAL_VIEWER'>, status?: Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminUserDetails(userRole, status, search, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminUserDetailsById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminUserDetailsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreAdminUser(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreAdminUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UserUpdateDto} userUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminUserDetails(id: number, userUpdateDto: UserUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminUserDetails(id, userUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword2(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUpdatePasswordResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminUserControllerApi - factory interface
 * @export
 */
export const AdminUserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminUserControllerApiCreateAdminUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminUser(requestParameters: AdminUserControllerApiCreateAdminUserRequest, options?: AxiosRequestConfig): AxiosPromise<UserCreateResponseDto> {
            return localVarFp.createAdminUser(requestParameters.userCreateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUserControllerApiDeleteAdminUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminUser(requestParameters: AdminUserControllerApiDeleteAdminUserRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAdminUser(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUserControllerApiGetAdminUserDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserDetails(requestParameters: AdminUserControllerApiGetAdminUserDetailsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageUserDetailsDto> {
            return localVarFp.getAdminUserDetails(requestParameters.userRole, requestParameters.status, requestParameters.search, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUserControllerApiGetAdminUserDetailsByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserDetailsById(requestParameters: AdminUserControllerApiGetAdminUserDetailsByIdRequest, options?: AxiosRequestConfig): AxiosPromise<UserDetailsDto> {
            return localVarFp.getAdminUserDetailsById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUserControllerApiRestoreAdminUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAdminUser(requestParameters: AdminUserControllerApiRestoreAdminUserRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.restoreAdminUser(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUserControllerApiUpdateAdminUserDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminUserDetails(requestParameters: AdminUserControllerApiUpdateAdminUserDetailsRequest, options?: AxiosRequestConfig): AxiosPromise<UserDetailsDto> {
            return localVarFp.updateAdminUserDetails(requestParameters.id, requestParameters.userUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUserControllerApiUpdatePassword2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword2(requestParameters: AdminUserControllerApiUpdatePassword2Request, options?: AxiosRequestConfig): AxiosPromise<UserUpdatePasswordResponseDto> {
            return localVarFp.updatePassword2(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAdminUser operation in AdminUserControllerApi.
 * @export
 * @interface AdminUserControllerApiCreateAdminUserRequest
 */
export interface AdminUserControllerApiCreateAdminUserRequest {
    /**
     * 
     * @type {UserCreateRequestDto}
     * @memberof AdminUserControllerApiCreateAdminUser
     */
    readonly userCreateRequestDto: UserCreateRequestDto
}

/**
 * Request parameters for deleteAdminUser operation in AdminUserControllerApi.
 * @export
 * @interface AdminUserControllerApiDeleteAdminUserRequest
 */
export interface AdminUserControllerApiDeleteAdminUserRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminUserControllerApiDeleteAdminUser
     */
    readonly id: number
}

/**
 * Request parameters for getAdminUserDetails operation in AdminUserControllerApi.
 * @export
 * @interface AdminUserControllerApiGetAdminUserDetailsRequest
 */
export interface AdminUserControllerApiGetAdminUserDetailsRequest {
    /**
     * 
     * @type {Array<'ADMIN_PORTAL_SUPER_ADMIN' | 'ADMIN_PORTAL_ADMIN' | 'ADMIN_PORTAL_VIEWER' | 'USER_PORTAL_SUPER_ADMIN' | 'USER_PORTAL_ADMIN' | 'USER_PORTAL_VIEWER'>}
     * @memberof AdminUserControllerApiGetAdminUserDetails
     */
    readonly userRole?: Array<'ADMIN_PORTAL_SUPER_ADMIN' | 'ADMIN_PORTAL_ADMIN' | 'ADMIN_PORTAL_VIEWER' | 'USER_PORTAL_SUPER_ADMIN' | 'USER_PORTAL_ADMIN' | 'USER_PORTAL_VIEWER'>

    /**
     * 
     * @type {Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>}
     * @memberof AdminUserControllerApiGetAdminUserDetails
     */
    readonly status?: Array<'ACTIVE' | 'INACTIVE' | 'DELETED'>

    /**
     * 
     * @type {string}
     * @memberof AdminUserControllerApiGetAdminUserDetails
     */
    readonly search?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof AdminUserControllerApiGetAdminUserDetails
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof AdminUserControllerApiGetAdminUserDetails
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof AdminUserControllerApiGetAdminUserDetails
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getAdminUserDetailsById operation in AdminUserControllerApi.
 * @export
 * @interface AdminUserControllerApiGetAdminUserDetailsByIdRequest
 */
export interface AdminUserControllerApiGetAdminUserDetailsByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminUserControllerApiGetAdminUserDetailsById
     */
    readonly id: number
}

/**
 * Request parameters for restoreAdminUser operation in AdminUserControllerApi.
 * @export
 * @interface AdminUserControllerApiRestoreAdminUserRequest
 */
export interface AdminUserControllerApiRestoreAdminUserRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminUserControllerApiRestoreAdminUser
     */
    readonly id: number
}

/**
 * Request parameters for updateAdminUserDetails operation in AdminUserControllerApi.
 * @export
 * @interface AdminUserControllerApiUpdateAdminUserDetailsRequest
 */
export interface AdminUserControllerApiUpdateAdminUserDetailsRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminUserControllerApiUpdateAdminUserDetails
     */
    readonly id: number

    /**
     * 
     * @type {UserUpdateDto}
     * @memberof AdminUserControllerApiUpdateAdminUserDetails
     */
    readonly userUpdateDto: UserUpdateDto
}

/**
 * Request parameters for updatePassword2 operation in AdminUserControllerApi.
 * @export
 * @interface AdminUserControllerApiUpdatePassword2Request
 */
export interface AdminUserControllerApiUpdatePassword2Request {
    /**
     * 
     * @type {number}
     * @memberof AdminUserControllerApiUpdatePassword2
     */
    readonly id: number
}

/**
 * AdminUserControllerApi - object-oriented interface
 * @export
 * @class AdminUserControllerApi
 * @extends {BaseAPI}
 */
export class AdminUserControllerApi extends BaseAPI {
    /**
     * 
     * @param {AdminUserControllerApiCreateAdminUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public createAdminUser(requestParameters: AdminUserControllerApiCreateAdminUserRequest, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).createAdminUser(requestParameters.userCreateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUserControllerApiDeleteAdminUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public deleteAdminUser(requestParameters: AdminUserControllerApiDeleteAdminUserRequest, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).deleteAdminUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUserControllerApiGetAdminUserDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public getAdminUserDetails(requestParameters: AdminUserControllerApiGetAdminUserDetailsRequest = {}, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).getAdminUserDetails(requestParameters.userRole, requestParameters.status, requestParameters.search, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUserControllerApiGetAdminUserDetailsByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public getAdminUserDetailsById(requestParameters: AdminUserControllerApiGetAdminUserDetailsByIdRequest, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).getAdminUserDetailsById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUserControllerApiRestoreAdminUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public restoreAdminUser(requestParameters: AdminUserControllerApiRestoreAdminUserRequest, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).restoreAdminUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUserControllerApiUpdateAdminUserDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public updateAdminUserDetails(requestParameters: AdminUserControllerApiUpdateAdminUserDetailsRequest, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).updateAdminUserDetails(requestParameters.id, requestParameters.userUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUserControllerApiUpdatePassword2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public updatePassword2(requestParameters: AdminUserControllerApiUpdatePassword2Request, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).updatePassword2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
