import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { WeatherPreviewWrapper } from './styles';
import { TextWithTooltip } from 'components/common/TextWithTooltip';
import { Humidity, Wind } from 'components/icons/weather';
import { ConditionIcon, WindDirection } from 'components/shared/Weather';
import { LocationWeatherShortDto } from 'openapi-api/admin-service';

interface Props {
  weather?: LocationWeatherShortDto;
}

export const WeatherPreview: FC<Props> = ({ weather }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const { t } = useTranslation();

  const noData = !weather;
  const { temperatureC, humidity, gustMps, windDegree } = weather || {
    temperatureC: '-',
    humidity: '-',
    gustMps: '-',
    windDegree: '-',
  };

  const textMainColor = noData
    ? theme.palette.grey[500]
    : theme.palette.black[500];
  const textSecondColor = noData
    ? theme.palette.grey[400]
    : theme.palette.grey[600];

  return (
    <WeatherPreviewWrapper noData={noData}>
      <Box display="flex" flexDirection="column" gap={0.5}>
        {isDesktop && (
          <TextWithTooltip
            textProps={{ variant: 'bodyM', color: { textMainColor } }}
            text={
              noData
                ? t('weather.conditions.noData')
                : t([
                    `weather.conditions.${weather.conditionCode}.${
                      weather.isDay ? 'day' : 'night'
                    }`,
                    `weather.conditions.${weather.conditionCode}`,
                  ])
            }
          />
        )}
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="h4" color={textMainColor}>
            {`${temperatureC}\u00b0C`}
          </Typography>
          <ConditionIcon
            iconLink={weather?.conditionIcon}
            isDay={weather?.isDay}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        gap={1.125}
        flexShrink={0}
      >
        {isDesktop && (
          <Typography variant="bodyS" color="grey.500">
            {weather?.datetime
              ? dayjs(weather?.datetime).format('HH:mm:ss')
              : '-'}
          </Typography>
        )}
        <Box
          display="flex"
          alignItems="center"
          gap={{ mobile: 1.5, desktop: 2.5 }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Humidity />
            <Typography variant="bodyM" color={textMainColor}>
              {`${humidity}%`}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Wind />
            <Typography variant="bodyM" color={textMainColor} sx={{ ml: 0.5 }}>
              {`${gustMps} ${t('weather.windSpeedMps')}`}
            </Typography>
            <Typography variant="bodyS" color={textSecondColor}>
              {t('weather.gust')}
            </Typography>
          </Box>
          {isDesktop && (
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography variant="bodyM" color={textMainColor}>
                {`${windDegree}\u00b0`}
              </Typography>
              {noData ? (
                <Typography variant="bodyM" color={textMainColor}>
                  {'-'}
                </Typography>
              ) : (
                <WindDirection
                  direction={weather.windDirection}
                  degree={weather.windDegree}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </WeatherPreviewWrapper>
  );
};
