import { Dispatch, SetStateAction, createContext } from 'react';

import {
  AssetDetailsDto,
  CustomerDto,
  GrafanaPanelParametersDto,
  LocationDto,
} from 'openapi-api/admin-service';
import { ReportMissingDataIntervalDto } from 'openapi-api/report-service';

export interface IReportPeriod {
  from: string;
  to: string;
}

export interface IReportValues {
  customer: CustomerDto;
  locations: LocationDto[];
  assets: AssetDetailsDto[];
  period: IReportPeriod;
}

export interface IReportContext {
  reportValues?: IReportValues;
  setReportValues: Dispatch<SetStateAction<IReportValues | undefined>>;
  reportPanels?: { [key: string]: GrafanaPanelParametersDto[] };
  reportMissingDataIntervals: ReportMissingDataIntervalDto[];
  reportAssetsHasHistoryLogs: { [publicId: string]: boolean };
  assetPublicIds: string[];
}

export const ReportContext = createContext<IReportContext>(null!);
