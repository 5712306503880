import { Typography } from '@mui/material';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { UserLink } from './styles';
import { WTLink } from './WTLink';
import routePaths from 'constants/routePaths';
import useUser from 'contexts/user';
import { AssetMasterAccountReplacementDto } from 'openapi-api/admin-service';
import { getStringFromISODate } from 'utils/functions/getStringFromISODate';

interface Props {
  details: AssetMasterAccountReplacementDto;
  extended: boolean;
  locationId?: number | string;
}

export const TemplateAssetMasterAccountReplacement: FC<Props> = ({
  details,
  extended,
  locationId,
}) => {
  const {
    publicId,
    eventDateTime,
    assetNameForAdmin,
    currentMasterAccountId,
    currentMasterAccountName,
    previousMasterAccountId,
    previousMasterAccountName,
  } = details;
  const { isAdmin, isSuperAdmin } = useUser();

  if (!publicId) {
    return null;
  }

  return (
    <>
      <Typography variant={extended ? 'bodyM' : 'bodyS'}>
        <Trans
          i18nKey="notifications.assetMasterAccountReplacement"
          values={{
            eventDateTime: getStringFromISODate(eventDateTime),
            previousMasterAccountName,
            currentMasterAccountName,
          }}
          components={[
            <WTLink
              key={`assetMasterAccountReplacement-link-${publicId}`}
              locationId={locationId}
              publicId={publicId}
              linkText={assetNameForAdmin}
              newTab={extended}
            />,
            isAdmin || isSuperAdmin ? (
              <UserLink
                to={
                  routePaths.users.customerList.profile(
                    previousMasterAccountId!,
                  ).root
                }
              />
            ) : (
              <strong />
            ),
            isAdmin || isSuperAdmin ? (
              <UserLink
                to={
                  routePaths.users.customerList.profile(currentMasterAccountId!)
                    .root
                }
              />
            ) : (
              <strong />
            ),
          ]}
        />
      </Typography>
    </>
  );
};
