import { styled } from '@mui/material';

export const GroupItems = styled('ul')(({ theme }) => ({
  padding: 0,
  display: 'grid',
  overflowWrap: 'anywhere',
  gridTemplateColumns: 'repeat(2, 1fr)',

  [theme.breakpoints.down('desktop')]: {
    gridTemplateColumns: '1fr',
  },

  '& li': {
    overflow: 'hidden',
  },
}));
