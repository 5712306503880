export const parksSortingName = 'sort';
export const parksFilterByStatusName = 'status';

export const fieldsToSend = [
  'status',
  'customerId',
  'search',
  'sort',
  'page',
  'size',
  'hasCoordinates',
];
