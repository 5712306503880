import { Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActionsItem,
  ActionsList,
  CloseButton,
  ContentHead,
  ContentWrapper,
  DialogStyled,
} from './styles';
import { ActionsMenuButton } from '../HeadBlock/styles';
import { Close, ThreeDots } from 'components/icons';
import useWindTurbine from 'contexts/WindTurbine';
import { usePopup } from 'utils/hooks/usePopup';

export type MobileMenuItem = { label: string; onClick: () => void };

interface Props {
  onEditAssetDetailsClick: () => void;
  onDisconnectMasterAccountClick: () => void;
  onDeleteAssetClick: () => void;
}

export const MobileMenuActions: FC<Props> = ({
  onEditAssetDetailsClick,
  onDisconnectMasterAccountClick,
  onDeleteAssetClick,
}) => {
  const [isOpen, openPopup, closePopup] = usePopup();
  const { showDeleteButton, windTurbine } = useWindTurbine();
  const { t } = useTranslation();

  const actions: MobileMenuItem[] = useMemo(
    () => [
      ...(windTurbine?.location
        ? [
            {
              label: t('pages.turbine.editAssetDetails'),
              onClick: onEditAssetDetailsClick,
            },
          ]
        : []),
      ...(windTurbine?.connected
        ? [
            {
              label: t('buttons.disconnectMasterAccount'),
              onClick: onDisconnectMasterAccountClick,
            },
          ]
        : []),
      ...(showDeleteButton
        ? [
            {
              label: t('pages.turbines.deleteAsset'),
              onClick: onDeleteAssetClick,
            },
          ]
        : []),
    ],
    [
      onDeleteAssetClick,
      onDisconnectMasterAccountClick,
      onEditAssetDetailsClick,
      showDeleteButton,
      t,
      windTurbine?.connected,
      windTurbine?.location,
    ],
  );

  const onItemClick = useCallback(
    (action: (() => void) | undefined) => {
      closePopup();
      action?.();
    },
    [closePopup],
  );

  return (
    <>
      {!!actions.length && (
        <ActionsMenuButton variant="outlined" onClick={openPopup} isMobile>
          <ThreeDots />
        </ActionsMenuButton>
      )}
      <DialogStyled open={isOpen} onClose={closePopup}>
        <CloseButton onClick={closePopup}>
          <Close />
        </CloseButton>
        <ContentWrapper>
          <ContentHead>
            <Typography variant="h5">
              {windTurbine?.name || windTurbine?.publicId}
            </Typography>
          </ContentHead>
          <ActionsList>
            {actions.map((item) => (
              <ActionsItem
                key={`mobile-menu-action-${item.label}`}
                onClick={() => onItemClick(item.onClick)}
              >
                <Typography variant="bodyL">{item.label}</Typography>
              </ActionsItem>
            ))}
          </ActionsList>
        </ContentWrapper>
      </DialogStyled>
    </>
  );
};
