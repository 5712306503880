import { Typography, styled } from '@mui/material';

export const NotFoundTitle = styled(Typography)(({ theme }) => ({
  fontSize: '54px',
  lineHeight: '110%',
  textAlign: 'center',
  color: theme.palette.white.main,

  [theme.breakpoints.down('desktop')]: {
    fontSize: '40px',
  },
}));
