import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getRequestParams } from './helpers';
import { EditParkDetailsPopup } from '../Park/components';
import { Wrapper } from '../Park/styles';
import { ParkCardsList } from '../Parks/components';
import { parksSortingName } from '../Parks/constants';
import { getSortingItems } from '../Parks/helpers';
import { ParksSortingOrder, ParksSortingValues } from '../Parks/types';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { ConnectMasterAccount } from 'components/common/ConnectMasterAccount';
import { Sorting } from 'components/common/Sorting';
import {
  Search,
  TablePagination,
  TablePaginationVariants,
} from 'components/common/Table/CommonTable';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import { getRowsPerPage } from 'utils/functions/getRowsPerPage';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';
import useDataGrid from 'utils/hooks/useDataGrid';

export const UnassignedParks: FC = () => {
  const { t } = useTranslation();
  const { locationControllerApi } = useApi();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const {
    searchParams,
    setSearchParams,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onSearch,
  } = useDataGrid();

  const [locationIdToEdit, setLocationIdToEdit] = useState<number | undefined>(
    undefined,
  );
  const [locationNameToConnect, setLocationNameToConnect] = useState<
    string | undefined
  >(undefined);

  const getUnassignedParks = useCallback(async () => {
    const params = getRequestParams(searchParams);

    try {
      return (
        await locationControllerApi.getLocations({
          ...params,
          unassigned: true,
        })
      ).data;
    } catch {}
  }, [searchParams, locationControllerApi]);

  const { resource: unassignedParks, fetch: refetchUnassignedParks } =
    useAsyncResourceWithPulling({
      fetchResource: getUnassignedParks,
      pullingInterval: 30,
    });

  const sortingItems = useMemo(() => getSortingItems(t), [t]);

  const rowsPerPageOptions = useMemo(
    () => getRowsPerPage(t, !isDesktop),
    [isDesktop, t],
  );

  const onEditParkDetailsButtonClick = useCallback((locationId?: number) => {
    setLocationIdToEdit(locationId);
  }, []);

  const onConnectMasterAccountClick = useCallback((locationName?: string) => {
    setLocationNameToConnect(locationName);
  }, []);

  useEffect(() => {
    setSearchParams(
      (prev) => {
        if (prev.get(parksSortingName) === null) {
          prev.set(
            parksSortingName,
            `${ParksSortingValues.addedDatetime},${ParksSortingOrder.desc}`,
          );
        }
        return prev;
      },
      { replace: true },
    );
  }, [setSearchParams]);

  return (
    <Wrapper>
      <Breadcrumbs
        items={[
          {
            label: t('pages.parks.title'),
            path: routePaths.parks.root,
          },
          {
            label: t('pages.unassignedParks.title'),
            path: routePaths.parks.unassignedParks,
          },
        ]}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap={{ mobile: 3, desktop: 4 }}
        marginTop={{ mobile: 3, desktop: 5 }}
      >
        <Box>
          <Typography variant="subheading">
            {t('pages.unassignedParks.counter', {
              count: unassignedParks?.unassignedLocationCount,
            })}
          </Typography>
          <Typography variant="h1" sx={{ mt: 1.5 }}>
            {t('pages.unassignedParks.title')}
          </Typography>
        </Box>
        <Box>
          <Box
            display="grid"
            gridTemplateColumns={{
              mobile: 'auto min-content',
              desktop: '1fr 1fr',
            }}
            gap={{ mobile: 2, desktop: 3 }}
          >
            <Search onSearch={onSearch} light sx={{ width: '100%' }} />
            <Sorting
              sortingName={parksSortingName}
              items={sortingItems}
              onSortModelChange={onSortModelChange}
              light
              sx={{ width: 'min-content', ml: 'auto' }}
            />
          </Box>
          <ParkCardsList
            items={unassignedParks?.content}
            isUnassigned
            onEditParkDetailsButtonClick={onEditParkDetailsButtonClick}
            onConnectMasterAccountClick={onConnectMasterAccountClick}
          />
          <TablePagination
            variant={TablePaginationVariants.DEFAULT}
            count={unassignedParks?.totalElements}
            page={paginationModel.page}
            rowsPerPage={paginationModel.pageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            onPaginationModelChange={onPaginationModelChange}
            sx={{
              mt: 1.5,
              ...(!isDesktop && {
                '& .MuiTablePagination-input': { width: 66 },
              }),
            }}
          />
        </Box>
      </Box>
      {typeof locationIdToEdit !== 'undefined' && (
        <EditParkDetailsPopup
          isOpen
          onClose={() => setLocationIdToEdit(undefined)}
          locationId={locationIdToEdit}
          onSuccess={refetchUnassignedParks}
        />
      )}
      {typeof locationNameToConnect !== 'undefined' && (
        <ConnectMasterAccount
          locationName={locationNameToConnect}
          onSuccess={() => {
            refetchUnassignedParks();
            setLocationNameToConnect(undefined);
          }}
          onClose={() => setLocationNameToConnect(undefined)}
        />
      )}
    </Wrapper>
  );
};
