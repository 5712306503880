import { styled } from '@mui/material';

export const EnergyCounterWrapper = styled('div', {
  shouldForwardProp: (propName) => propName !== 'variant',
})<{ variant: 'parkCard' | 'parkPage' }>(({ theme, variant }) => {
  if (variant === 'parkCard') {
    return {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      padding: theme.spacing(1),
      borderRadius: '12px',
      backgroundColor: theme.palette.grey[50],
    };
  }

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.75),
    padding: theme.spacing(2.5, 4),
    borderRadius: '25px',
    backgroundColor: theme.palette.grey[50],
  };
});
