import { Box, Grid, Theme, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { NotificationsList } from './components/NotificationsList';
import { TabTitle } from './components/TabTitle';
import { fieldsToSend, notificationsFilterValues } from './constants';
import {
  MarkAllAsReadButton,
  TabSectionsWrapper,
  TopLineWrapper,
} from './styles';
import { ITab } from '../../../common/TabsSection/TabsSection';
import { PageWrapper } from '../WindTurbine/styles';
import { dateStringToObject } from 'components/common/DatePicker';
import { NoData } from 'components/common/NoData';
import {
  FilterMenu,
  Search,
  TablePagination,
} from 'components/common/Table/CommonTable';
import { CheckMarkDouble } from 'components/icons';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import useNotificationsContext from 'contexts/notifications';
import {
  NotificationControllerApiGetNotificationsRequest,
  NotificationDto,
  NotificationDtoTypeEnum,
} from 'openapi-api/admin-service';
import { FilterItem } from 'types/general';
import { formatObjFromQuery } from 'utils/functions/formatQuery';
import { getRowsPerPage } from 'utils/functions/getRowsPerPage';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';
import useDataGrid from 'utils/hooks/useDataGrid';

const Notifications = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { type } = useParams();
  const { notificationControllerApi } = useApi();
  const { unreadNotificationsCount, fetchUnreadNotificationsCount } =
    useNotificationsContext();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('desktop'),
  );
  const {
    searchParams,
    paginationModel,
    onPaginationModelChange,
    onSearch,
    onFilterModelChange,
  } = useDataGrid(20);

  const checkedFilterValues: Record<string, FilterItem> = useMemo(
    () => ({
      status: { value: searchParams.get('status') },
      dateRange: { value: searchParams.get('dateRange') },
    }),
    [searchParams],
  );

  const loadNotifications = useCallback(async () => {
    const objFromQuery = formatObjFromQuery(fieldsToSend, searchParams);
    const dateObj = dateStringToObject(objFromQuery.dateRange as string);

    const params: NotificationControllerApiGetNotificationsRequest = {
      page: +(objFromQuery.page ?? 0),
      size: +(objFromQuery.size ?? 20),
      ...(type !== 'all' ? { type: type as NotificationDtoTypeEnum } : {}),
      ...(objFromQuery.status && objFromQuery.status !== 'all'
        ? { markedAsRead: objFromQuery.status === 'read' }
        : {}),
      searchBy: (objFromQuery.search as string) || undefined,
      ...(dateObj?.from && dateObj?.to
        ? {
            fromCreateDatetime: dateObj.from,
            toCreateDatetime: dateObj.to,
          }
        : {}),
    };

    let result;
    try {
      result = (await notificationControllerApi.getNotifications(params)).data;
    } catch {
      result = {};
    }

    return result;
  }, [type, searchParams, notificationControllerApi]);

  const {
    resource: notifications,
    fetch,
    isLoading,
  } = useAsyncResourceWithPulling({
    fetchResource: loadNotifications,
    pullingInterval: 30,
  });

  const handleReadNotification = useCallback(
    async (notification: NotificationDto) => {
      if (!notification?.id) return;
      try {
        await notificationControllerApi.markAsRead({
          notificationIds: [notification.id],
        });
        fetchUnreadNotificationsCount();
        fetch();
      } catch {}
    },
    [notificationControllerApi, fetchUnreadNotificationsCount, fetch],
  );

  const handleReadAllNotifications = useCallback(async () => {
    try {
      await notificationControllerApi.markAsReadByType({
        ...(type !== 'all' && { type: type as NotificationDtoTypeEnum }),
      });
      fetchUnreadNotificationsCount();
      fetch();
    } catch {}
  }, [notificationControllerApi, type, fetchUnreadNotificationsCount, fetch]);

  const unreadNotificationsCurrentTab = useMemo(() => {
    if (type === 'all') {
      return (
        unreadNotificationsCount[NotificationDtoTypeEnum.ERROR] +
        unreadNotificationsCount[NotificationDtoTypeEnum.WARNING] +
        unreadNotificationsCount[NotificationDtoTypeEnum.REMINDER]
      );
    }

    return unreadNotificationsCount[type as NotificationDtoTypeEnum];
  }, [type, unreadNotificationsCount]);

  const listContent = useMemo(() => {
    if (!isLoading && !notifications?.content?.length) {
      return <NoData text={t('pages.notifications.noNotifications')} />;
    }
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        mt={unreadNotificationsCurrentTab === 0 ? 3 : 0}
      >
        {unreadNotificationsCurrentTab !== 0 && (
          <MarkAllAsReadButton
            variant="text"
            size="text"
            startIcon={<CheckMarkDouble />}
            onClick={handleReadAllNotifications}
          >
            {t('pages.notifications.markAllAsRead')}
          </MarkAllAsReadButton>
        )}
        <NotificationsList
          notifications={notifications?.content || []}
          readNotification={handleReadNotification}
        />
      </Box>
    );
  }, [
    isLoading,
    notifications?.content,
    unreadNotificationsCurrentTab,
    handleReadAllNotifications,
    t,
    handleReadNotification,
  ]);

  const tabs: ITab[] = [
    {
      label: (
        <TabTitle
          title={t('pages.notifications.filterAll')}
          active={pathname === routePaths.notifications.specific('all')}
          count={
            unreadNotificationsCount[NotificationDtoTypeEnum.ERROR] +
            unreadNotificationsCount[NotificationDtoTypeEnum.WARNING] +
            unreadNotificationsCount[NotificationDtoTypeEnum.REMINDER]
          }
        />
      ),
      value: listContent,
      key: 'all',
    },
    {
      label: (
        <TabTitle
          title={t('notifications.errors')}
          active={
            pathname ===
            routePaths.notifications.specific(NotificationDtoTypeEnum.ERROR)
          }
          count={unreadNotificationsCount[NotificationDtoTypeEnum.ERROR]}
        />
      ),
      value: listContent,
      key: NotificationDtoTypeEnum.ERROR,
    },
    {
      label: (
        <TabTitle
          title={t('notifications.warnings')}
          active={
            pathname ===
            routePaths.notifications.specific(NotificationDtoTypeEnum.WARNING)
          }
          count={unreadNotificationsCount[NotificationDtoTypeEnum.WARNING]}
        />
      ),
      value: listContent,
      key: NotificationDtoTypeEnum.WARNING,
    },
    {
      label: (
        <TabTitle
          title={t('notifications.reminders')}
          active={
            pathname ===
            routePaths.notifications.specific(NotificationDtoTypeEnum.REMINDER)
          }
          count={unreadNotificationsCount[NotificationDtoTypeEnum.REMINDER]}
        />
      ),
      value: listContent,
      key: NotificationDtoTypeEnum.REMINDER,
    },
  ];

  const onTabChange = useCallback(
    (tab: ITab) => {
      const newSearchParams = {
        ...Object.fromEntries([...searchParams]),
        page: '0',
      };
      navigate({
        pathname: routePaths.notifications.specific(tab.key),
        search: new URLSearchParams(newSearchParams).toString(),
      });
    },
    [navigate, searchParams],
  );

  const rowsPerPageOptions = useMemo(
    () => getRowsPerPage(t, !isDesktop),
    [isDesktop, t],
  );

  return (
    <PageWrapper>
      <TopLineWrapper>
        <Typography variant="h1">{t('pages.notifications.title')}</Typography>
        <Grid display="flex" gap={2} alignItems="center">
          <Search onSearch={onSearch} light />
          <FilterMenu
            checkedFilterValues={checkedFilterValues}
            filterValues={notificationsFilterValues(t)}
            onFilterModelChange={onFilterModelChange}
            light
          />
        </Grid>
      </TopLineWrapper>
      <TabSectionsWrapper
        tabs={tabs}
        onChange={onTabChange}
        standardWidthFrom="tablet"
        initialTab={tabs.findIndex(({ key }) => key === type)}
      />
      <TablePagination
        count={notifications?.totalElements}
        page={paginationModel.page}
        rowsPerPage={paginationModel.pageSize}
        rowsPerPageOptions={rowsPerPageOptions}
        onPaginationModelChange={onPaginationModelChange}
        sx={{
          ...(!isDesktop && { '& .MuiTablePagination-input': { width: 66 } }),
        }}
      />
    </PageWrapper>
  );
};

export default Notifications;
