import { Box, useMediaQuery, useTheme } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { generateColumns } from './columns';
import {
  clickDisabledFields,
  fieldsToSend,
  sorting,
  turbinesFilterValues,
} from './constants';
import { TableAboveWrapper, Wrapper } from './styles';
import { WindTurbinesTableContext, WindTurbinesTableProps } from './types';
import { AppliedFilters } from 'components/common/AppliedFilters';
import {
  ConnectMasterAccount,
  OpenConnectMAProps,
} from 'components/common/ConnectMasterAccount';
import {
  CommonTable,
  FilterMenu,
  Search,
  TablePagination,
} from 'components/common/Table/CommonTable';
import useApi from 'contexts/api';
import useUser from 'contexts/user';
import {
  WindTurbineControllerApiAllWindTurbinesRequest,
  WindTurbineDtoOperationalStatusEnum,
  WindTurbineDtoStatusEnum,
} from 'openapi-api/admin-service';
import { FilterItem } from 'types/general';
import { formatObjFromQuery } from 'utils/functions/formatQuery';
import { getRowsPerPage } from 'utils/functions/getRowsPerPage';
import { getWindTurbinePageLink } from 'utils/functions/getWindTurbinePageLink';
import { ignoreDoubleFilter } from 'utils/functions/ignoreDoubleFilters';
import { sortingMapping } from 'utils/functions/sortingMapping';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';
import useDataGrid from 'utils/hooks/useDataGrid';
import { useWindTurbineActions } from 'utils/hooks/useWindTurbineActions';

export const WindTurbinesTable: FC<WindTurbinesTableProps> = ({
  locationId,
  showUnconfigured,
  className,
  sx,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { windTurbineControllerApi } = useApi();
  const { isAdmin, isSuperAdmin } = useUser();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const {
    sortModel,
    onSortModelChange,
    searchParams,
    paginationModel,
    onPaginationModelChange,
    onSearch,
    onFilterModelChange,
  } = useDataGrid();
  const [connectMAPopupProps, setConnectMAPopupProps] =
    useState<OpenConnectMAProps>({});

  const tableContext: WindTurbinesTableContext | undefined = useMemo(() => {
    if (locationId !== undefined) {
      return WindTurbinesTableContext.Park;
    }

    if (showUnconfigured) {
      return WindTurbinesTableContext.Unconfigured;
    }
  }, [locationId, showUnconfigured]);

  const loadTurbines = useCallback(async () => {
    const objFromQuery = formatObjFromQuery(fieldsToSend, searchParams);
    const params: WindTurbineControllerApiAllWindTurbinesRequest = {
      ...objFromQuery,
      size: Number(objFromQuery.size) || 10,
      sort: sortingMapping(sorting, objFromQuery.sort as string | undefined),
      status: showUnconfigured
        ? [WindTurbineDtoStatusEnum.CREATED]
        : ((objFromQuery.status as string)?.split(
            ',',
          ) as WindTurbineDtoStatusEnum[]),
      ratedPower: undefined,
      operationalStatus: (objFromQuery.operationalStatus as string)?.split(
        ',',
      ) as WindTurbineDtoOperationalStatusEnum[],
      accelerationStatus: undefined,
      connected: ignoreDoubleFilter(objFromQuery.connected) as boolean,
      locationId,
    };
    let result;
    try {
      result = (await windTurbineControllerApi.allWindTurbines(params)).data;
    } catch {
      result = {};
    }

    return result;
  }, [searchParams, windTurbineControllerApi, locationId, showUnconfigured]);

  const {
    resource: turbines,
    fetch,
    isLoading,
  } = useAsyncResourceWithPulling({
    fetchResource: loadTurbines,
    pullingInterval: 30,
  });

  const checkedFilterValues: Record<string, FilterItem> = useMemo(
    () => ({
      connected: { value: searchParams.get('connected') },
      status: { value: searchParams.get('status') },
      operationalStatus: { value: searchParams.get('operationalStatus') },
    }),
    [searchParams],
  );

  const { onDeleteAssetClick, onDisconnectMasterAccountClick } =
    useWindTurbineActions();

  const wtActions = useMemo(
    () => ({
      disconnectMasterAccount: (publicId?: string) =>
        onDisconnectMasterAccountClick(publicId, fetch),
      delete: (publicId?: string) => onDeleteAssetClick(publicId, fetch),
    }),
    [fetch, onDeleteAssetClick, onDisconnectMasterAccountClick],
  );

  const columns = useMemo(
    () =>
      generateColumns({
        t,
        ...((isAdmin || isSuperAdmin) && { setConnectMAPopupProps }),
        ...((isAdmin || isSuperAdmin) && { wtActions }),
        tableContext,
      }),
    [isAdmin, isSuperAdmin, t, tableContext, wtActions],
  );

  const rowsPerPageOptions = useMemo(
    () => getRowsPerPage(t, !isDesktop),
    [isDesktop, t],
  );

  const onCellClick = useCallback(
    ({ row, field }: GridCellParams) => {
      if (clickDisabledFields.includes(field)) return;

      navigate(
        getWindTurbinePageLink({
          assetLocationId: row.location?.id,
          assetPublicId: row.publicId,
        }).root,
      );
    },
    [navigate],
  );

  const filterValues = useMemo(
    () => turbinesFilterValues(t, tableContext),
    [t, tableContext],
  );

  return (
    <Wrapper className={className} sx={sx}>
      <TableAboveWrapper>
        <Search onSearch={onSearch} light sx={{ width: '100%' }} />
        <FilterMenu
          checkedFilterValues={checkedFilterValues}
          filterValues={filterValues}
          onFilterModelChange={onFilterModelChange}
          light
          sx={{ ml: 'auto' }}
        />
      </TableAboveWrapper>
      <AppliedFilters
        checkedFilterValues={checkedFilterValues}
        filterValues={filterValues}
        onFilterModelChange={onFilterModelChange}
      />
      <Box>
        <CommonTable
          columns={columns}
          rows={turbines?.content || []}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          sortingMode="server"
          rowCount={turbines?.totalElements || 0}
          onCellClick={onCellClick}
          loading={isLoading}
          initialState={{
            columns: {
              columnVisibilityModel: {
                customerName: locationId === undefined,
                windSpeed: !showUnconfigured,
                activePower: !showUnconfigured,
                totalLostEnergy: !showUnconfigured,
                actions: locationId === undefined,
                linkToDetailedPage: !showUnconfigured,
              },
            },
          }}
          sx={{
            maxWidth: '100%',
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'grey.50',
              cursor: 'pointer',
            },
          }}
        />
        <TablePagination
          count={turbines?.totalElements}
          page={paginationModel.page}
          rowsPerPage={paginationModel.pageSize}
          rowsPerPageOptions={rowsPerPageOptions}
          onPaginationModelChange={onPaginationModelChange}
          sx={{
            ...(!isDesktop && {
              '& .MuiTablePagination-input': { width: 66 },
            }),
          }}
        />
      </Box>
      {(connectMAPopupProps.assetId || connectMAPopupProps.locationName) && (
        <ConnectMasterAccount
          onClose={() => setConnectMAPopupProps({})}
          onSuccess={() => {
            setConnectMAPopupProps({});
            fetch();
          }}
          {...connectMAPopupProps}
        />
      )}
    </Wrapper>
  );
};
