import { Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { SelectTimePeriods } from '../constants';
import { getFromDateInUnixMs } from '../helpers';
import {
  ChartCardWrapper,
  ViewTagsChartIframe,
  ViewTagsChartIframeWrapper,
} from '../styles';
import useAuth from 'contexts/auth';
import useWindTurbinePanels from 'contexts/WindTurbinePanels';
import getDateTimeInUnixMs from 'utils/functions/getDateTimeInUnixMs';
import { getGrafanaWidgetLink } from 'utils/functions/getGrafanaWidgetLink';

interface Props {
  title: string;
  panelId: number;
  timePeriod: SelectTimePeriods;
}

export const ChartCard: FC<Props> = ({ title, panelId, timePeriod }) => {
  const { errorTimestamp } = useParams();
  const { accessData } = useAuth();
  const { panels } = useWindTurbinePanels();

  const toDate = useMemo(
    () => getDateTimeInUnixMs(errorTimestamp as string),
    [errorTimestamp],
  );

  const fromDate = useMemo(
    () => getFromDateInUnixMs(toDate, timePeriod),
    [timePeriod, toDate],
  );

  return (
    <ChartCardWrapper>
      <Typography
        variant="bodyM"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {title}
      </Typography>
      <ViewTagsChartIframeWrapper>
        <ViewTagsChartIframe
          title={title}
          src={getGrafanaWidgetLink({
            panelId: panelId.toString(),
            panelParameters: panels?.[panelId] || {},
            authToken: accessData?.accessToken || '',
            from: fromDate,
            to: toDate,
          })}
        />
      </ViewTagsChartIframeWrapper>
    </ChartCardWrapper>
  );
};
