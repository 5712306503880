import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  IComparisonChartContext,
  ComparisonChartContext,
  IComparisonChartProviderFormValues,
} from './ComparisonChartContext';
import useApi from 'contexts/api';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

export const ComparisonChartProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { customerControllerApi } = useApi();

  const [formValues, setFormValues] = useState<
    IComparisonChartProviderFormValues | undefined
  >(undefined);

  const getCustomers = useCallback(async () => {
    try {
      return (
        await customerControllerApi.allCustomers({
          status: ['ACTIVE', 'INACTIVE'],
        })
      ).data;
    } catch {
      return [];
    }
  }, [customerControllerApi]);

  const { resource: customers } = useAsyncResource({
    defaultValue: [],
    fetchResource: getCustomers,
    disableGlobalLoader: true,
  });

  const contextValue = useMemo(
    (): IComparisonChartContext => ({
      customers,
      formValues,
      setFormValues,
    }),
    [customers, formValues],
  );

  return (
    <ComparisonChartContext.Provider value={contextValue}>
      {children}
    </ComparisonChartContext.Provider>
  );
};
