/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HistoryLogsExistCheckResponseDto } from '../models';
// @ts-ignore
import { HistoryLogsRequestDto } from '../models';
// @ts-ignore
import { HistoryLogsResponseDto } from '../models';
// @ts-ignore
import { PageHistoryLogDto } from '../models';
/**
 * HistoryLogControllerApi - axios parameter creator
 * @export
 */
export const HistoryLogControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {string} [assetPublicId] 
         * @param {number} [userId] 
         * @param {'USER_ACTIVITIES' | 'STATUSES_AND_ERRORS'} [type] 
         * @param {string} [fromCreateDatetime] 
         * @param {string} [toCreateDatetime] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryLogs: async (search?: string, assetPublicId?: string, userId?: number, type?: 'USER_ACTIVITIES' | 'STATUSES_AND_ERRORS', fromCreateDatetime?: string, toCreateDatetime?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/history-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (assetPublicId !== undefined) {
                localVarQueryParameter['assetPublicId'] = assetPublicId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (fromCreateDatetime !== undefined) {
                localVarQueryParameter['fromCreateDatetime'] = (fromCreateDatetime as any instanceof Date) ?
                    (fromCreateDatetime as any).toISOString() :
                    fromCreateDatetime;
            }

            if (toCreateDatetime !== undefined) {
                localVarQueryParameter['toCreateDatetime'] = (toCreateDatetime as any instanceof Date) ?
                    (toCreateDatetime as any).toISOString() :
                    toCreateDatetime;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {HistoryLogsRequestDto} historyLogsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusesAndErrorsHistoryLogs: async (xApiKey: string, historyLogsRequestDto: HistoryLogsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('getStatusesAndErrorsHistoryLogs', 'xApiKey', xApiKey)
            // verify required parameter 'historyLogsRequestDto' is not null or undefined
            assertParamExists('getStatusesAndErrorsHistoryLogs', 'historyLogsRequestDto', historyLogsRequestDto)
            const localVarPath = `/history-log/statuses-and-errors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiKey != null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(historyLogsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HistoryLogsRequestDto} historyLogsRequestDto 
         * @param {'USER_ACTIVITIES' | 'STATUSES_AND_ERRORS'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasHistoryLogs: async (historyLogsRequestDto: HistoryLogsRequestDto, type?: 'USER_ACTIVITIES' | 'STATUSES_AND_ERRORS', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'historyLogsRequestDto' is not null or undefined
            assertParamExists('hasHistoryLogs', 'historyLogsRequestDto', historyLogsRequestDto)
            const localVarPath = `/history-log/exist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(historyLogsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HistoryLogControllerApi - functional programming interface
 * @export
 */
export const HistoryLogControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HistoryLogControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {string} [assetPublicId] 
         * @param {number} [userId] 
         * @param {'USER_ACTIVITIES' | 'STATUSES_AND_ERRORS'} [type] 
         * @param {string} [fromCreateDatetime] 
         * @param {string} [toCreateDatetime] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoryLogs(search?: string, assetPublicId?: string, userId?: number, type?: 'USER_ACTIVITIES' | 'STATUSES_AND_ERRORS', fromCreateDatetime?: string, toCreateDatetime?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageHistoryLogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoryLogs(search, assetPublicId, userId, type, fromCreateDatetime, toCreateDatetime, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xApiKey 
         * @param {HistoryLogsRequestDto} historyLogsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatusesAndErrorsHistoryLogs(xApiKey: string, historyLogsRequestDto: HistoryLogsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistoryLogsResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatusesAndErrorsHistoryLogs(xApiKey, historyLogsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {HistoryLogsRequestDto} historyLogsRequestDto 
         * @param {'USER_ACTIVITIES' | 'STATUSES_AND_ERRORS'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasHistoryLogs(historyLogsRequestDto: HistoryLogsRequestDto, type?: 'USER_ACTIVITIES' | 'STATUSES_AND_ERRORS', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistoryLogsExistCheckResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasHistoryLogs(historyLogsRequestDto, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HistoryLogControllerApi - factory interface
 * @export
 */
export const HistoryLogControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HistoryLogControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {HistoryLogControllerApiGetHistoryLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryLogs(requestParameters: HistoryLogControllerApiGetHistoryLogsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageHistoryLogDto> {
            return localVarFp.getHistoryLogs(requestParameters.search, requestParameters.assetPublicId, requestParameters.userId, requestParameters.type, requestParameters.fromCreateDatetime, requestParameters.toCreateDatetime, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HistoryLogControllerApiGetStatusesAndErrorsHistoryLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusesAndErrorsHistoryLogs(requestParameters: HistoryLogControllerApiGetStatusesAndErrorsHistoryLogsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<HistoryLogsResponseDto>> {
            return localVarFp.getStatusesAndErrorsHistoryLogs(requestParameters.xApiKey, requestParameters.historyLogsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HistoryLogControllerApiHasHistoryLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasHistoryLogs(requestParameters: HistoryLogControllerApiHasHistoryLogsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<HistoryLogsExistCheckResponseDto>> {
            return localVarFp.hasHistoryLogs(requestParameters.historyLogsRequestDto, requestParameters.type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getHistoryLogs operation in HistoryLogControllerApi.
 * @export
 * @interface HistoryLogControllerApiGetHistoryLogsRequest
 */
export interface HistoryLogControllerApiGetHistoryLogsRequest {
    /**
     * 
     * @type {string}
     * @memberof HistoryLogControllerApiGetHistoryLogs
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof HistoryLogControllerApiGetHistoryLogs
     */
    readonly assetPublicId?: string

    /**
     * 
     * @type {number}
     * @memberof HistoryLogControllerApiGetHistoryLogs
     */
    readonly userId?: number

    /**
     * 
     * @type {'USER_ACTIVITIES' | 'STATUSES_AND_ERRORS'}
     * @memberof HistoryLogControllerApiGetHistoryLogs
     */
    readonly type?: 'USER_ACTIVITIES' | 'STATUSES_AND_ERRORS'

    /**
     * 
     * @type {string}
     * @memberof HistoryLogControllerApiGetHistoryLogs
     */
    readonly fromCreateDatetime?: string

    /**
     * 
     * @type {string}
     * @memberof HistoryLogControllerApiGetHistoryLogs
     */
    readonly toCreateDatetime?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof HistoryLogControllerApiGetHistoryLogs
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof HistoryLogControllerApiGetHistoryLogs
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof HistoryLogControllerApiGetHistoryLogs
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getStatusesAndErrorsHistoryLogs operation in HistoryLogControllerApi.
 * @export
 * @interface HistoryLogControllerApiGetStatusesAndErrorsHistoryLogsRequest
 */
export interface HistoryLogControllerApiGetStatusesAndErrorsHistoryLogsRequest {
    /**
     * 
     * @type {string}
     * @memberof HistoryLogControllerApiGetStatusesAndErrorsHistoryLogs
     */
    readonly xApiKey: string

    /**
     * 
     * @type {HistoryLogsRequestDto}
     * @memberof HistoryLogControllerApiGetStatusesAndErrorsHistoryLogs
     */
    readonly historyLogsRequestDto: HistoryLogsRequestDto
}

/**
 * Request parameters for hasHistoryLogs operation in HistoryLogControllerApi.
 * @export
 * @interface HistoryLogControllerApiHasHistoryLogsRequest
 */
export interface HistoryLogControllerApiHasHistoryLogsRequest {
    /**
     * 
     * @type {HistoryLogsRequestDto}
     * @memberof HistoryLogControllerApiHasHistoryLogs
     */
    readonly historyLogsRequestDto: HistoryLogsRequestDto

    /**
     * 
     * @type {'USER_ACTIVITIES' | 'STATUSES_AND_ERRORS'}
     * @memberof HistoryLogControllerApiHasHistoryLogs
     */
    readonly type?: 'USER_ACTIVITIES' | 'STATUSES_AND_ERRORS'
}

/**
 * HistoryLogControllerApi - object-oriented interface
 * @export
 * @class HistoryLogControllerApi
 * @extends {BaseAPI}
 */
export class HistoryLogControllerApi extends BaseAPI {
    /**
     * 
     * @param {HistoryLogControllerApiGetHistoryLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoryLogControllerApi
     */
    public getHistoryLogs(requestParameters: HistoryLogControllerApiGetHistoryLogsRequest = {}, options?: AxiosRequestConfig) {
        return HistoryLogControllerApiFp(this.configuration).getHistoryLogs(requestParameters.search, requestParameters.assetPublicId, requestParameters.userId, requestParameters.type, requestParameters.fromCreateDatetime, requestParameters.toCreateDatetime, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HistoryLogControllerApiGetStatusesAndErrorsHistoryLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoryLogControllerApi
     */
    public getStatusesAndErrorsHistoryLogs(requestParameters: HistoryLogControllerApiGetStatusesAndErrorsHistoryLogsRequest, options?: AxiosRequestConfig) {
        return HistoryLogControllerApiFp(this.configuration).getStatusesAndErrorsHistoryLogs(requestParameters.xApiKey, requestParameters.historyLogsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HistoryLogControllerApiHasHistoryLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoryLogControllerApi
     */
    public hasHistoryLogs(requestParameters: HistoryLogControllerApiHasHistoryLogsRequest, options?: AxiosRequestConfig) {
        return HistoryLogControllerApiFp(this.configuration).hasHistoryLogs(requestParameters.historyLogsRequestDto, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }
}
