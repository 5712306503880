import { styled } from '@mui/material';

export const ParametersFieldWrapper = styled('div')<{ visible?: boolean }>(
  ({ theme, visible }) => ({
    minHeight: visible ? '120px' : '0px',
    margin: theme.spacing(0, -3, -3),
    padding: theme.spacing(visible ? 4 : 0),
    borderRadius: '0 0 20px 20px',
    backgroundColor: theme.palette.black[500],
    transition: 'all 0.1s ease',

    [theme.breakpoints.up('desktop')]: {
      margin: theme.spacing(0, -4, -4),
    },
  }),
);
