import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OccurredErrorsActionsMenuButton } from './styles';
import { DropdownMenu } from 'components/common/DropdownMenu';
import { OpenLink, ThreeDots } from 'components/icons';
import useReport from 'contexts/report';
import { AssetDetailsDto } from 'openapi-api/admin-service';
import getDateTimeInUnixMs from 'utils/functions/getDateTimeInUnixMs';
import { getWindTurbinePageLink } from 'utils/functions/getWindTurbinePageLink';

interface IOccurredErrorsActionsProps {
  asset: AssetDetailsDto;
}

export const OccurredErrorsActions: FC<IOccurredErrorsActionsProps> = ({
  asset,
}) => {
  const { t } = useTranslation();
  const { reportValues, reportAssetsHasHistoryLogs } = useReport();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const unixReportPeriodFrom = useMemo(
    () => getDateTimeInUnixMs(reportValues?.period.from as string),
    [reportValues?.period.from],
  );

  const unixReportPeriodTo = useMemo(
    () => getDateTimeInUnixMs(reportValues?.period.to as string),
    [reportValues?.period.to],
  );

  const generateErrorPageLink = useCallback(
    (
      page: 'errorReport' | 'errorsHistoryLogReport',
      assetId?: string,
      assetLocationName?: string,
    ) => {
      if (!assetId) return;

      const urlParams = new URLSearchParams({
        from: unixReportPeriodFrom.toString(),
        to: unixReportPeriodTo.toString(),
        customer: reportValues?.customer.name as string,
      });

      const locationId = reportValues?.locations.find(
        (location) => location.name === assetLocationName,
      )?.id;

      return `${window.location.origin}${
        getWindTurbinePageLink({
          assetLocationId:
            typeof locationId !== 'undefined' ? String(locationId) : undefined,
          assetPublicId: assetId,
        })[page]
      }?${urlParams}`;
    },
    [reportValues, unixReportPeriodFrom, unixReportPeriodTo],
  );

  const actions = useMemo(
    () => [
      ...(asset.publicId && reportAssetsHasHistoryLogs[asset.publicId]
        ? [
            {
              label: t('pages.reports.content.showHistoryLog'),
              link: generateErrorPageLink(
                'errorsHistoryLogReport',
                asset.publicId,
                asset.locationName,
              ),
              newTab: true,
            },
          ]
        : []),
      {
        label: t('pages.reports.content.showErrors'),
        link: generateErrorPageLink(
          'errorReport',
          asset.publicId,
          asset.locationName,
        ),
        newTab: true,
      },
    ],
    [
      asset.locationName,
      asset.publicId,
      generateErrorPageLink,
      reportAssetsHasHistoryLogs,
      t,
    ],
  );

  if (!reportValues) return null;

  return isDesktop ? (
    <Box display="flex" gap={5}>
      {actions.map((action) => (
        <Box
          display="flex"
          gap={1}
          component="a"
          href={action.link}
          target="_blank"
        >
          <OpenLink />
          <Typography
            variant="buttonPrimary"
            textTransform="none"
            color="grey.800"
          >
            {action.label}
          </Typography>
        </Box>
      ))}
    </Box>
  ) : (
    <DropdownMenu
      title={
        <OccurredErrorsActionsMenuButton
          variant="text"
          size="text"
          data-testid="occurred-errors-actions"
        >
          <ThreeDots />
        </OccurredErrorsActionsMenuButton>
      }
      withExpandIcon={false}
      items={actions}
    />
  );
};
