import { ParameterGroup } from 'components/shared/ParametersSelect/constants';
import { ComparisonChartFormValues } from 'contexts/comparisonChart';
import { TranslationFunction } from 'types/general';

export const defaultValues: ComparisonChartFormValues = {
  customerName: '',
  locationName: '',
  assetName: '',
  fromDate: '',
  toDate: '',
  currentTimeFrame: false,
  parameters: [],
};

export const getParametersOptions = (t: TranslationFunction) => {
  return [
    // ELPOWER:
    {
      label: t('parameters.vrsAFErms'),
      value: 'VrsAFErms',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.vstAFErms'),
      value: 'VstAFErms',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.vtrAFErms'),
      value: 'VtrAFErms',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.irOUTrms'),
      value: 'IrOUTrms',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.isOUTrms'),
      value: 'IsOUTrms',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.itOUTrms'),
      value: 'ItOUTrms',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.vrsINVrms'),
      value: 'VrsINVrms',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.vstINVrms'),
      value: 'VstINVrms',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.vtrINVrms'),
      value: 'VtrINVrms',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.irINVrms'),
      value: 'IrINVrms',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.isINVrms'),
      value: 'IsINVrms',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.itINVrms'),
      value: 'ItINVrms',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.dCBusVoltage'),
      value: 'dc_bus_voltage',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.freqAFEf'),
      value: 'grid_frequency',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.generatorFrequency'),
      value: 'generator_frequency',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.pinv'),
      value: 'pinv',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.pafe'),
      value: 'pafe',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.temperatureAFE'),
      value: 'temperature_afe',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.temperatureInverter'),
      value: 'temperature_inverter',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.cabinetTemperature'),
      value: 'temperature_cabinet',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.totalSupplyHours'),
      value: 'total_supply_hours',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.gridInjectionHours'),
      value: 'grid_injection_hours',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.gridVoltageAFESoftStartFLG1AFEHEX1B723'),
      value: 'flg1afe_HEX1B723',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.vectorModulationFLG1AFEHEX1B622'),
      value: 'flg1afe_HEX1B622',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.waitForWindFLG1AFEHEX1B521'),
      value: 'flg1afe_HEX1B521',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.waitForGridReConnectionTimeFLG1AFEHEX0B319'),
      value: 'flg1afe_HEX0B319',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.gridAFEContractorOpenFLG1AFEHEX0B218'),
      value: 'flg1afe_HEX0B218',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.permanentFaultFLG1AFEHEX3B1531'),
      value: 'flg1afe_HEX3B1531',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.temporaryFaultFLG1AFEHEX3B1430'),
      value: 'flg1afe_HEX3B1430',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.faultForConsecutiveTemporaryFaultFLG1AFEHEX3B1329'),
      value: 'flg1afe_HEX3B1329',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.gridFrequencyBeyondLimitsFLG1AFEHEX3B1228'),
      value: 'flg1afe_HEX3B1228',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.externalGridProtectionTripg59ForUKFLG1AFEHEX2B1026'),
      value: 'flg1afe_HEX2B1026',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.voltageBeyondLimitsFLG1AFEHEX2B925'),
      value: 'flg1afe_HEX2B925',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.wrongGridsPhaseSequenceFLG1AFEHEX2B824'),
      value: 'flg1afe_HEX2B824',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.gridVoltageVIAFENotBalancedFLG1AFEHEX0B117'),
      value: 'flg1afe_HEX0B117',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.gridCurrentLiAFENotBalancedFLG1AFEHEX0B016'),
      value: 'flg1afe_HEX0B016',
      group: ParameterGroup.ELPOWER,
    },

    {
      label: t('parameters.gridPreChargeSelectionFLGAFEHEX3B1515'),
      value: 'flgafe_HEX3B1515',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.gridPLLNonLockedFLGAFEHEX3B1414'),
      value: 'flgafe_HEX3B1414',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.stopBitLogicsFLGAFEHEX0B11'),
      value: 'flgafe_HEX0B11',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.boostDCFromAFEInProgressFLGAFEHEX0B00'),
      value: 'flgafe_HEX0B00',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.vbusUnderThresholdFLGAFEHEX3B1313'),
      value: 'flgafe_HEX3B1313',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.vrmsAFEBeyondSuperiorLimitFLGAFEHEX3B1212'),
      value: 'flgafe_HEX3B1212',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.iGBTOverheatingThermalSwitchTripFLGAFEHEX2B1111'),
      value: 'flgafe_HEX2B1111',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.faultFeedbackFromGridAFEContactorFLGAFEHEX2B99'),
      value: 'flgafe_HEX2B99',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'parameters.faultLineGridUnderMinimumLimitLVFNonRTFLGAFEHEX2B88',
      ),
      value: 'flgafe_HEX2B88',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.faultForMaxGridVoltageSoftwareVlnISwFLGAFEHEX1B77'),
      value: 'flgafe_HEX1B77',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.faultForMaxGridCurrentSoftwareLlnISwFLGAFEHEX1B66'),
      value: 'flgafe_HEX1B66',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'parameters.hZFaultHwInternalControlCardHardwareProblemFLGAFEHEX1B55',
      ),
      value: 'flgafe_HEX1B55',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'parameters.driverAFEFaultHwInternalIgbtDriverProtectionFLGAFEHEX1B44',
      ),
      value: 'flgafe_HEX1B44',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.dCLinkBrakingChopperFaultFLGAFEHEX0B33'),
      value: 'flgafe_HEX0B33',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.faultForMaxLineCurrentHardwareLlnIHwFLGAFEHEX0B22'),
      value: 'flgafe_HEX0B22',
      group: ParameterGroup.ELPOWER,
    },

    {
      label: t('parameters.vectorModulationOnFLG1INVHEX1B622'),
      value: 'flg1inv_HEX1B622',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.startCommandHardwareOnFLG1INVHEX0B218'),
      value: 'flg1inv_HEX0B218',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.permanentFaultFLG1INVHEX3B1531'),
      value: 'flg1inv_HEX3B1531',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.temporaryFaultFLG1INVHEX3B1430'),
      value: 'flg1inv_HEX3B1430',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.mechanicStopFailedFLG1INVHEX2B1127'),
      value: 'flg1inv_HEX2B1127',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.softwareI2tGeneratorProtectionFLG1INVHEX1B521'),
      value: 'flg1inv_HEX1B521',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'parameters.faultContactorsRelayDriverCtrlBoardErrorFLG1INVHEX1B420',
      ),
      value: 'flg1inv_HEX1B420',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.maxTurbineOverspeedFLG1INVHEX0B319'),
      value: 'flg1inv_HEX0B319',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.generatorVoltageViINVUnbalancedFLG1INVHEX0B117'),
      value: 'flg1inv_HEX0B117',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.generatorCurrentLiINVUnbalancedFLG1INVHEX0B016'),
      value: 'flg1inv_HEX0B016',
      group: ParameterGroup.ELPOWER,
    },

    {
      label: t('parameters.stopBitLogicsFLGINVHEX0B11'),
      value: 'flginv_HEX0B11',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'parameters.setupNotCompatibleWithFwReleaseFaultFLGINVHEX3B1515',
      ),
      value: 'flginv_HEX3B1515',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'parameters.generatorVoltageVrmsINVUnderThresholdFLGINVHEX3B1414',
      ),
      value: 'flginv_HEX3B1414',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.generatorFrequencyBeyondLimitsFLGINVHEX3B1313'),
      value: 'flginv_HEX3B1313',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.preChargeDCLinkFailedFLGINVHEX2B1010'),
      value: 'flginv_HEX2B1010',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'parameters.faultFeedbackFromGeneratorINVContactorFLGINVHEX2B99',
      ),
      value: 'flginv_HEX2B99',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'parameters.faultForMaxDCLinkBusVoltageSoftwareVbusSwFLGINVHEX2B88',
      ),
      value: 'flginv_HEX2B88',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'parameters.faultForMaxGeneratorVoltageSoftwareVinvISwFLGINVHEX1B77',
      ),
      value: 'flginv_HEX1B77',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'parameters.faultForMaxGeneratorCurrentSoftwareLinvISwFLGINVHEX1B66',
      ),
      value: 'flginv_HEX1B66',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.driverINVFaultHwFLGINVHEX1B44'),
      value: 'flginv_HEX1B44',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.faultPerMaxVbusHwFLGINVHEX0B33'),
      value: 'flginv_HEX0B33',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.faultPerMaxLinvIHwFLGINVHEX0B22'),
      value: 'flginv_HEX0B22',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.dailyTotalEnergy'),
      value: 'daily_total_energy',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.monthlyTotalEnergy'),
      value: 'monthly_total_energy',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.annualTotalEnergy'),
      value: 'annual_total_energy',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.totalEnergy'),
      value: 'total_energy',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.stateMachineInverterStMach'),
      value: 'state_machine_inverter',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.stateMachineErrorManagementStError'),
      value: 'state_machine_error',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.instantaneousWindSpeed'),
      value: 'wind_speed_instant',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.dailyAverageWindSpeed'),
      value: 'wind_speed_daily_avg',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.monthlyAverageWindSpeed'),
      value: 'wind_speed_monthly_avg',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.annualAverageWindSpeed'),
      value: 'wind_speed_annual_avg',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.dogvaneDirection'),
      value: 'dogvane_direction',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.generatorStartFailedFLG2INVHEX0B032'),
      value: 'flg2inv_HEX0B032',
      group: ParameterGroup.ELPOWER,
    },

    {
      label: t('parameters.gridSwitchTrippedFLG2AFEHEX0B335'),
      value: 'flg2afe_HEX0B335',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.maxNumberOfConsecutiveLVFRTFLG2AFEHEX1B638'),
      value: 'flg2afe_HEX1B638',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.lVFAnalysisInProgressStError100FLG2AFEHEX1B537'),
      value: 'flg2afe_HEX1B537',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'parameters.faultAntiIslandingDetectionInterventionFLG2AFEHEX2B941',
      ),
      value: 'flg2afe_HEX2B941',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t(
        'parameters.limitationActivePowerFromOuterSetPointFLG2AFEHEX0B133',
      ),
      value: 'flg2afe_HEX0B133',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.limitationActivePowerForFrequencyFLG2AFEHEX0B032'),
      value: 'flg2afe_HEX0B032',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.returnSignalStarTriangleUD1FLG2AFEHEX1B436'),
      value: 'flg2afe_HEX1B436',
      group: ParameterGroup.ELPOWER,
    },
    {
      label: t('parameters.emulatedSPILimit5951FLG2AFEHEX0B234'),
      value: 'flg2afe_HEX0B234',
      group: ParameterGroup.ELPOWER,
    },
    // JANITZA:
    {
      label: t('parameters.voltageL1N'),
      value: 'voltage_L1_N',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.voltageL2N'),
      value: 'voltage_L2_N',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.voltageL3N'),
      value: 'voltage_L3_N',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.voltageL1L2'),
      value: 'voltage_L1_L2',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.voltageL2L3'),
      value: 'voltage_L2_L3',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.voltageL1L3'),
      value: 'voltage_L1_L3',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.currentL1'),
      value: 'current_L1',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.currentL2'),
      value: 'current_L2',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.currentL3'),
      value: 'current_L3',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.currentN'),
      value: 'current_N',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.activePhasePowerP1'),
      value: 'real_power_p1',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.activePhasePowerP2'),
      value: 'real_power_p2',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.activePhasePowerP3'),
      value: 'real_power_p3',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.activePower'),
      value: 'active_power',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhasePowerS1'),
      value: 'apparent_power_p1',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhasePowerS2'),
      value: 'apparent_power_p2',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhasePowerS3'),
      value: 'apparent_power_p3',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPower'),
      value: 'total_power',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.reactivePhasePowerQ1'),
      value: 'fund_reactive_power_q1',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.reactivePhasePowerQ2'),
      value: 'fund_reactive_power_q2',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.reactivePhasePowerQ3'),
      value: 'fund_reactive_power_q3',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.reactivePower'),
      value: 'reactive_power',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.cosPhiL1'),
      value: 'cosphi_l1',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.cosPhiL2'),
      value: 'cosphi_l2',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.cosPhiL3'),
      value: 'cosphi_l3',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.frequency'),
      value: 'measured_frequency',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.rotationField'),
      value: 'rotation_field',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseActiveEnergyL1'),
      value: 'real_energy_l1',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseActiveEnergyL2'),
      value: 'real_energy_l2',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseActiveEnergyL3'),
      value: 'real_energy_l3',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalActiveEnergy'),
      value: 'total_active_energy',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseActiveEnergyConsumedL1'),
      value: 'real_energy_consumed_l1',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseActiveEnergyConsumedL2'),
      value: 'real_energy_consumed_l2',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseActiveEnergyConsumedL3'),
      value: 'real_energy_consumed_l3',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalActiveEnergyConsumed'),
      value: 'total_active_energy_consumed',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseActiveEnergyDeliveredL1'),
      value: 'real_energy_delivered_l1',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseActiveEnergyDeliveredL2'),
      value: 'real_energy_delivered_l2',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseActiveEnergyDeliveredL3'),
      value: 'real_energy_delivered_l3',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalActiveEnergyDelivered'),
      value: 'total_active_energy_delivered',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseApparentEnergyL1'),
      value: 'apparent_energy_l1',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseApparentEnergyL2'),
      value: 'apparent_energy_l2',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseApparentEnergyL3'),
      value: 'apparent_energy_l3',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalApparentEnergy'),
      value: 'total_apparent_energy',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseReactiveEnergyL1'),
      value: 'reactive_energy_l1',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseReactiveEnergyL2'),
      value: 'reactive_energy_l2',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseReactiveEnergyL3'),
      value: 'reactive_energy_l3',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalReactiveEnergy'),
      value: 'total_reactive_energy',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseReactiveEnergyIndL1'),
      value: 'reactive_energy_ind_l1',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseReactiveEnergyIndL2'),
      value: 'reactive_energy_ind_l2',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalPhaseReactiveEnergyIndL3'),
      value: 'reactive_energy_ind_l3',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalReactiveEnergyInd'),
      value: 'total_reactive_energy_ind',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalReactiveEnergyCapL1'),
      value: 'reactive_energy_cap_l1',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalReactiveEnergyCapL2'),
      value: 'reactive_energy_cap_l2',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalReactiveEnergyCapL3'),
      value: 'reactive_energy_cap_l3',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.totalReactiveEnergyCap'),
      value: 'total_reactive_energy_cap',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.harmonicTHDUL1N'),
      value: 'harmonic_thd_u_l1_n',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.harmonicTHDUL2N'),
      value: 'harmonic_thd_u_l2_n',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.harmonicTHDUL3N'),
      value: 'harmonic_thd_u_l3_n',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.harmonicTHDIL1'),
      value: 'harmonic_thd_i_l1',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.harmonicTHDIL2'),
      value: 'harmonic_thd_i_l2',
      group: ParameterGroup.JANITZA,
    },
    {
      label: t('parameters.harmonicTHDIL3'),
      value: 'harmonic_thd_i_l3',
      group: ParameterGroup.JANITZA,
    },

    // TEMPERATURE:
    {
      label: t('parameters.temperatureGenPhaseA'),
      value: 'temperature_gen_phase_a',
      group: ParameterGroup.TEMPERATURE,
    },
    {
      label: t('parameters.temperatureGenPhaseB'),
      value: 'temperature_gen_phase_b',
      group: ParameterGroup.TEMPERATURE,
    },
    {
      label: t('parameters.temperatureGenPhaseC'),
      value: 'temperature_gen_phase_c',
      group: ParameterGroup.TEMPERATURE,
    },
    {
      label: t('parameters.temperaturePLCBox'),
      value: 'temperature_plc_box',
      group: ParameterGroup.TEMPERATURE,
    },
    {
      label: t('parameters.temperatureDCResistor'),
      value: 'temperature_dc_resistor',
      group: ParameterGroup.TEMPERATURE,
    },
    {
      label: t('parameters.temperatureBrakeResistor'),
      value: 'temperature_brake_resistor',
      group: ParameterGroup.TEMPERATURE,
    },
    {
      label: t('parameters.temperatureUZBoxBack'),
      value: 'temperature_uz_box_back',
      group: ParameterGroup.TEMPERATURE,
    },
    {
      label: t('parameters.temperatureUZBoxFront'),
      value: 'temperature_uz_box_front',
      group: ParameterGroup.TEMPERATURE,
    },
    // MECHANICAL:
    {
      label: t('parameters.windSpeed'),
      value: 'wind_speed',
      group: ParameterGroup.MECHANICAL,
    },
    {
      label: t('parameters.generatorSpeed'),
      value: 'generator_speed',
      group: ParameterGroup.MECHANICAL,
    },
  ];
};
