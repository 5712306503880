import * as yup from 'yup';

import { TranslationFunction } from '../../../types/general';

export const validationSchema = (
  t: TranslationFunction,
  isUsernameExist: (username: string) => Promise<boolean>,
) =>
  yup.object().shape(
    {
      existingCustomer: yup
        .string()
        .when('newCustomer', ([newCustomer], schema) => {
          if (!newCustomer)
            return schema.required(t('form.errors.fieldRequired'));
          return schema;
        }),
      newCustomer: yup
        .string()
        .when('existingCustomer', ([existingCustomer], schema) => {
          if (!existingCustomer)
            return schema
              .required(t('form.errors.fieldRequired'))
              .matches(
                /^[a-zA-Z0-9][a-zA-Z0-9-_]{8,30}[a-zA-Z0-9]$/,
                t('form.errors.incorrectUsername'),
              )
              .test({
                message: t('form.errors.uniqueUsername'),
                test: async (username = '') => {
                  const usernameExists = await isUsernameExist(username);
                  return !usernameExists;
                },
              });
          return schema;
        }),
    },
    [['newCustomer', 'existingCustomer']],
  );
