import { Box, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OrWrapper } from './styles';
import useApi from '../../../contexts/api';
import { SelectItem } from '../../../types/general';
import { useAsyncResource } from '../../../utils/hooks/useAsyncResource';
import { FormSelect } from '../form/Select';
import { NewMacAddresses } from 'components/pages/protected/AssetOnboarding/components';
import {
  AssetDtoStatusEnum,
  AssetNameDto,
  LocationDto,
} from 'openapi-api/admin-service';

export const SelectParksOrMacAddresses = () => {
  const { assetControllerApi, locationControllerApi } = useApi();
  const { t } = useTranslation();
  const { setValue, clearErrors } = useFormContext();

  const getUnassignedParks = useCallback(async () => {
    try {
      const response = await locationControllerApi.getLocationNames({
        unassigned: true,
      });

      return response.data as LocationDto[];
    } catch {}
  }, [locationControllerApi]);

  const { resource: unassignedParks } = useAsyncResource({
    fetchResource: getUnassignedParks,
  });

  const selectExistingParksItems: SelectItem[] = useMemo(
    () =>
      unassignedParks?.map(({ name }) => ({
        label: name as string,
        value: name as string,
      })) || [],
    [unassignedParks],
  );

  const getUnassignedCreatedAssets = useCallback(async () => {
    try {
      const response = await assetControllerApi.getAssetNames({
        // TODO: ask BE to rename unassigned to unconfigured
        unassigned: true,
        status: AssetDtoStatusEnum.CREATED,
      });

      return response.data as AssetNameDto[];
    } catch {}
  }, [assetControllerApi]);

  const { resource: unassignedCreatedAssets } = useAsyncResource({
    fetchResource: getUnassignedCreatedAssets,
  });

  const selectExistingMacsItems: SelectItem[] = useMemo(
    () =>
      unassignedCreatedAssets?.map(({ publicId }) => ({
        label: publicId as string,
        value: publicId as string,
      })) || [],
    [unassignedCreatedAssets],
  );

  const clearSelectedParks = useCallback(() => {
    setValue('existingParks', []);
    clearErrors('existingParks');
  }, [clearErrors, setValue]);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <FormSelect
        name="existingParks"
        label={t('components.assetOnboarding.parkName')}
        items={selectExistingParksItems}
        fullWidth
        dataTestId="selectParks"
        onItemClick={() => {
          setValue('existingMacAddresses', []);
          clearErrors('existingMacAddresses');
          setValue('newMacAddresses', []);
          clearErrors('newMacAddresses');
        }}
        multiple
      />
      <OrWrapper>
        <Typography
          variant="bodyM"
          color="grey.600"
          component="div"
          sx={{ background: 'white', px: 1, zIndex: 1 }}
        >
          {t('or')}
        </Typography>
      </OrWrapper>
      <Typography variant="h4">
        {t('components.assetOnboarding.mac')}
      </Typography>
      <Box flex={1} flexDirection="column">
        <FormSelect
          name="existingMacAddresses"
          label={t('components.assetOnboarding.mac')}
          items={selectExistingMacsItems}
          fullWidth
          dataTestId="selectExistingMacs"
          onItemClick={clearSelectedParks}
          multiple
        />
        <NewMacAddresses onAddNewMac={clearSelectedParks} />
      </Box>
    </Box>
  );
};
