import { SxProps } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DateTimeWrapper } from './styles';
import DatePicker from '../DatePicker';
import { TimePicker } from '../TimePicker';

export type ValueProps = 'fromDate' | 'toDate';

interface DateTimePickerProps {
  value?: string | null;
  onChange?: (value: Dayjs | null) => void;
  dateLabel?: string;
  timeLabel?: string;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  minTime?: Dayjs;
  maxTime?: Dayjs;
  disabled?: boolean;
  className?: string;
  sx?: SxProps;
}

export const DateTimePicker: FC<DateTimePickerProps> = ({
  value = null,
  onChange,
  dateLabel,
  timeLabel,
  minDate,
  maxDate,
  minTime,
  maxTime,
  disabled,
  sx,
  className,
}) => {
  const { t } = useTranslation();
  const dayjsValue = value ? dayjs(value) : null;

  const onDateChange = useCallback(
    (newValue: Dayjs | null) => {
      if (!newValue) {
        onChange?.(newValue);
        return;
      }

      const newDateTime = (dayjsValue || dayjs())
        .year(newValue.year())
        .month(newValue.month())
        .date(newValue.date())
        .millisecond(0);

      onChange?.(newDateTime?.isValid() ? newDateTime : null);
    },
    [dayjsValue, onChange],
  );

  const onTimeChange = useCallback(
    (newValue: Dayjs | null) => {
      if (!newValue) {
        onChange?.(newValue);
        return;
      }

      const newDateTime = (dayjsValue || dayjs())
        .hour(newValue.hour())
        .minute(newValue.minute())
        .second(newValue.second())
        .millisecond(0);

      onChange?.(newDateTime?.isValid() ? newDateTime : null);
    },
    [dayjsValue, onChange],
  );

  return (
    <DateTimeWrapper sx={sx} className={className}>
      <DatePicker
        label={dateLabel || t('components.datePicker.date')}
        value={dayjsValue}
        onChange={(newValue) => onDateChange(newValue)}
        minDate={minDate || undefined}
        maxDate={maxDate || dayjs()}
        disabled={disabled}
      />
      <TimePicker
        label={timeLabel || t('components.datePicker.time')}
        value={dayjsValue}
        onChange={(newValue) => onTimeChange(newValue)}
        minTime={minTime}
        maxTime={maxTime}
        disabled={disabled}
      />
    </DateTimeWrapper>
  );
};
