import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const LostEnergy: FC<CommonSvgProps> = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.4185 23C14.3369 23 14.2544 22.9805 14.179 22.9398C13.9736 22.8297 13.8721 22.5953 13.934 22.3735L15.6462 16.2397H12.5025C12.305 16.2397 12.1258 16.1264 12.0449 15.9491C11.9632 15.7726 11.9949 15.5648 12.125 15.4187L19.4689 7.1687C19.6204 6.9984 19.871 6.95075 20.0764 7.05465C20.2818 7.15854 20.3889 7.38587 20.3365 7.60694L19.047 13.0666H22.4977C22.6959 13.0666 22.8752 13.1814 22.9561 13.3587C23.0369 13.5368 23.0044 13.7446 22.8728 13.8907L14.7953 22.8336C14.6969 22.9422 14.5589 23 14.4185 23Z"
      stroke="#A7ABB2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M12.807 3V5.66497H14.6387C14.7801 5.66497 14.9084 5.74784 14.9672 5.87791C15.0259 6.0074 15.0048 6.15992 14.9124 6.26754L9.27372 12.8791C9.13686 13.0403 8.86314 13.0403 8.72628 12.8791L3.08759 6.26754C2.99521 6.15934 2.97411 6.0074 3.03285 5.87791C3.09158 5.74842 3.21989 5.66497 3.36132 5.66497H5.19298V3"
      stroke="#A7ABB2"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M4.79161 8.66667L4.79161 17.4869H3.61802C3.47394 17.4869 3.34322 17.5694 3.28338 17.6983C3.22354 17.8272 3.24503 17.9785 3.33915 18.0862L7.30437 22.6297C7.44381 22.7901 7.72268 22.7901 7.86212 22.6297L9.84473 20.3579"
      stroke="#A7ABB2"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
