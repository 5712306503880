import { styled } from '@mui/material';

export const ButtonsWrapper = styled('div')(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.white.main,
  zIndex: 1,

  padding: theme.spacing(2, 2.5),
  borderTop: `1px solid ${theme.palette.grey[100]}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
