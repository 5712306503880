import { styled } from '@mui/material';

export const WeatherWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(2, calc(50% - ${theme.spacing(2)}))`,
  gap: theme.spacing(4),

  [theme.breakpoints.down('desktop')]: {
    gridTemplateColumns: `repeat(2, calc(50% - ${theme.spacing(0.75)}))`,
    gap: theme.spacing(1.5),
  },
}));
