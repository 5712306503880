import React, { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import NotificationsListWrapper from './styles/NotificationsListWrapper';
import NotificationMessage from '../../../../common/Notifications/NotificationMessage';
import {
  ConnectMasterAccount,
  OpenConnectMAProps,
} from 'components/common/ConnectMasterAccount';
import { NotificationDto } from 'openapi-api/admin-service';

export interface Props {
  notifications: NotificationDto[];
  readNotification: (notification: NotificationDto) => void;
}
export const NotificationsList: FC<Props> = ({
  notifications,
  readNotification,
}) => {
  const { type } = useParams();
  const [connectMAPopupProps, setConnectMAPopupProps] =
    useState<OpenConnectMAProps>({});

  const handleCloseConnectMasterAccount = useCallback(() => {
    setConnectMAPopupProps({});
  }, []);

  return (
    <>
      <NotificationsListWrapper>
        {notifications.map((notification) => (
          <NotificationMessage
            key={`${notification?.id}-${type}`}
            notification={notification}
            onClick={readNotification}
            openConnectMAPopup={setConnectMAPopupProps}
            extended={true}
          />
        ))}
      </NotificationsListWrapper>
      {(connectMAPopupProps.assetId || connectMAPopupProps.locationName) && (
        <ConnectMasterAccount
          onClose={handleCloseConnectMasterAccount}
          onSuccess={handleCloseConnectMasterAccount}
          {...connectMAPopupProps}
        />
      )}
    </>
  );
};
