import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import {
  CommonButtonStyled,
  CounterIcon,
  UnassignedBlockWrapper,
} from './styles';
import { ArrowRight } from 'components/icons';

interface Props {
  counter: string;
  title: string;
  subtitle: string;
  buttonText: string;
  buttonLink: string;
}

export const UnassignedBlock: FC<Props> = ({
  counter,
  title,
  subtitle,
  buttonText,
  buttonLink,
}) => {
  return (
    <UnassignedBlockWrapper>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" gap={0.5} paddingY={0.5}>
          <CounterIcon />
          <Typography variant="bodyMStrong" color="secondaryGreen.200">
            {counter}
          </Typography>
        </Box>
        <Typography variant="h3" sx={{ mt: 1.5, mb: 1 }}>
          {title}
        </Typography>
        <Typography variant="bodyM" color="grey.800">
          {subtitle}
        </Typography>
      </Box>
      <Box flexShrink={0}>
        <Link to={buttonLink}>
          <CommonButtonStyled endIcon={<ArrowRight />}>
            {buttonText}
          </CommonButtonStyled>
        </Link>
      </Box>
    </UnassignedBlockWrapper>
  );
};
