import { styled } from '@mui/material/styles';

export const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),

  backgroundColor: theme.palette.white.main,
  borderRadius: '25px',
  padding: theme.spacing(3),
  [theme.breakpoints.down('desktop')]: {
    gap: theme.spacing(3),
    padding: theme.spacing(2),
  },
}));
