import { styled } from '@mui/material';

export const FieldsWrapperGeneral = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: theme.spacing(2.5),

  [theme.breakpoints.down('tablet')]: {
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3),
  },
}));
