import { styled } from '@mui/material';

export const ParkItemsCounter = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '& .MuiTypography-root': {
    color: theme.palette.grey[600],
  },

  '& svg > path': {
    stroke: theme.palette.grey[600],
  },

  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(0.5, 0),
  },
}));
