import { Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { WTLink } from './WTLink';
import CommonButton from 'components/common/CommonButton';
import { OpenConnectMAProps } from 'components/common/ConnectMasterAccount';
import useApi from 'contexts/api';
import useNotificationsContext from 'contexts/notifications';
import useUser from 'contexts/user';
import {
  AssetAddedDto,
  WindTurbineDtoStatusEnum,
} from 'openapi-api/admin-service';
import { getStringFromISODate } from 'utils/functions/getStringFromISODate';

interface Props {
  details: AssetAddedDto;
  openConnectMAPopup: (connectMAProps: OpenConnectMAProps) => void;
  extended: boolean;
  locationId?: number | string;
}

const TemplateAssetAdded: FC<Props> = ({
  details,
  openConnectMAPopup,
  extended,
  locationId,
}) => {
  const {
    publicId,
    eventDateTime,
    assetNameForAdmin,
    masterAccountName,
    locationName,
  } = details;
  const { setIgnoreClickOutside } = useNotificationsContext();
  const { t } = useTranslation();
  const { windTurbineControllerApi } = useApi();
  const { isAdmin, isSuperAdmin } = useUser();
  const [isAssetConnected, setIsAssetConnected] = useState(false);
  const [isConnectMasterAccountVisible, setIsConnectMasterAccountVisible] =
    useState(false);

  useEffect(() => {
    const setConnectionStatus = async () => {
      if (typeof publicId === 'undefined') {
        setIsAssetConnected(false);
        setIsConnectMasterAccountVisible(false);
        return;
      }
      try {
        const { connected: isConnected, status } = (
          await windTurbineControllerApi.getWindTurbine({ publicId })
        ).data;
        setIsAssetConnected(!!isConnected);
        setIsConnectMasterAccountVisible(
          !isConnected && status === WindTurbineDtoStatusEnum.CREATED,
        );
      } catch {
        setIsAssetConnected(false);
        setIsConnectMasterAccountVisible(false);
      }
    };
    setConnectionStatus();
  }, [publicId, windTurbineControllerApi]);

  const OptionalMasterAccountText = useMemo(
    () => () => {
      if (!isAssetConnected) return null;

      return (
        <>
          {`${t('masterAccountName')}: `}
          <strong>{masterAccountName}</strong>
        </>
      );
    },
    [isAssetConnected, masterAccountName, t],
  );

  if (!publicId) {
    return null;
  }

  return (
    <>
      <Typography variant={extended ? 'bodyM' : 'bodyS'}>
        <Trans
          i18nKey="notifications.assetAdded"
          values={{
            eventDateTime: getStringFromISODate(eventDateTime),
            parkName: locationName,
          }}
          components={[
            <WTLink
              key={`assetAdded-link-${publicId}`}
              locationId={locationId}
              publicId={publicId}
              linkText={assetNameForAdmin}
              newTab={extended}
            />,
            <strong />,
            <OptionalMasterAccountText />,
          ]}
        />
      </Typography>
      {isConnectMasterAccountVisible && (isAdmin || isSuperAdmin) && (
        <CommonButton
          sx={{ width: 'fit-content' }}
          size="medium"
          onClick={() => {
            setIgnoreClickOutside(true);
            openConnectMAPopup({ assetId: publicId });
          }}
        >
          {t('buttons.connectMasterAccount')}
        </CommonButton>
      )}
    </>
  );
};

export default TemplateAssetAdded;
