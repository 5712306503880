import { SxProps } from '@mui/material';
import { FC } from 'react';

import { WTLink } from './WTLink';
import routePaths from 'constants/routePaths';

interface ILocationLinkProps {
  locationId: number;
  linkText: string;
  link?: string;
  newTab?: boolean;
  sx?: SxProps;
  className?: string;
}

export const LocationLink: FC<ILocationLinkProps> = ({
  link,
  locationId,
  ...rest
}) => {
  return (
    <WTLink
      link={link || routePaths.parks.specific(locationId).root}
      publicId=""
      {...rest}
    />
  );
};
