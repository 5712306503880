/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { LocationDto } from './location-dto';

/**
 * 
 * @export
 * @interface WindTurbineDto
 */
export interface WindTurbineDto {
    /**
     * 
     * @type {number}
     * @memberof WindTurbineDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WindTurbineDto
     */
    'publicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WindTurbineDto
     */
    'status'?: WindTurbineDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof WindTurbineDto
     */
    'operationalStatus'?: WindTurbineDtoOperationalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof WindTurbineDto
     */
    'accelerationStatus'?: WindTurbineDtoAccelerationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof WindTurbineDto
     */
    'createDatetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof WindTurbineDto
     */
    'updateDatetime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WindTurbineDto
     */
    'connected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WindTurbineDto
     */
    'customerName'?: string;
    /**
     * 
     * @type {number}
     * @memberof WindTurbineDto
     */
    'customerId'?: number;
    /**
     * 
     * @type {LocationDto}
     * @memberof WindTurbineDto
     */
    'location'?: LocationDto;
    /**
     * 
     * @type {string}
     * @memberof WindTurbineDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof WindTurbineDto
     */
    'errorText'?: string;
    /**
     * 
     * @type {string}
     * @memberof WindTurbineDto
     */
    'errorType'?: WindTurbineDtoErrorTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof WindTurbineDto
     */
    'generatedEnergy'?: number;
    /**
     * 
     * @type {string}
     * @memberof WindTurbineDto
     */
    'ratedPower'?: WindTurbineDtoRatedPowerEnum;
    /**
     * 
     * @type {number}
     * @memberof WindTurbineDto
     */
    'windSpeed'?: number;
    /**
     * 
     * @type {number}
     * @memberof WindTurbineDto
     */
    'activePower'?: number;
    /**
     * 
     * @type {number}
     * @memberof WindTurbineDto
     */
    'totalActivePower'?: number;
    /**
     * 
     * @type {number}
     * @memberof WindTurbineDto
     */
    'powerCoefficient'?: number;
    /**
     * 
     * @type {number}
     * @memberof WindTurbineDto
     */
    'totalLostEnergy'?: number;
    /**
     * 
     * @type {string}
     * @memberof WindTurbineDto
     */
    'masterAccountName'?: string;
    /**
     * 
     * @type {number}
     * @memberof WindTurbineDto
     */
    'masterAccountId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum WindTurbineDtoStatusEnum {
    NOT_SET = 'NOT_SET',
    CREATED = 'CREATED',
    ONBOARDING = 'ONBOARDING',
    OFFLINE = 'OFFLINE',
    READY = 'READY',
    ERROR = 'ERROR',
    DELETED = 'DELETED'
}
/**
    * @export
    * @enum {string}
    */
export enum WindTurbineDtoOperationalStatusEnum {
    NOT_SET = 'NOT_SET',
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED'
}
/**
    * @export
    * @enum {string}
    */
export enum WindTurbineDtoAccelerationStatusEnum {
    NOT_SET = 'NOT_SET',
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED'
}
/**
    * @export
    * @enum {string}
    */
export enum WindTurbineDtoErrorTypeEnum {
    CONNECTIVITY = 'CONNECTIVITY',
    TAGS_COLLECTION_ERROR = 'TAGS_COLLECTION_ERROR',
    FAULT_COMMON_SIGNAL = 'FAULT_COMMON_SIGNAL'
}
/**
    * @export
    * @enum {string}
    */
export enum WindTurbineDtoRatedPowerEnum {
    _5 = 'POWER_5',
    _15 = 'POWER_15',
    _20 = 'POWER_20',
    _55 = 'POWER_55'
}


