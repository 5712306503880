import { Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { LocationLink } from './LocationLink';
import CommonButton from 'components/common/CommonButton';
import { OpenConnectMAProps } from 'components/common/ConnectMasterAccount';
import useApi from 'contexts/api';
import useNotificationsContext from 'contexts/notifications';
import useUser from 'contexts/user';
import { LocationAddedDto } from 'openapi-api/admin-service';
import { getStringFromISODate } from 'utils/functions/getStringFromISODate';

interface Props {
  details: LocationAddedDto;
  openConnectMAPopup: (connectMAProps: OpenConnectMAProps) => void;
  extended: boolean;
}

export const TemplateLocationAdded: FC<Props> = ({
  details,
  openConnectMAPopup,
  extended,
}) => {
  const { locationId, eventDateTime, locationName } = details;
  const { setIgnoreClickOutside } = useNotificationsContext();
  const { t } = useTranslation();
  const { locationControllerApi } = useApi();
  const { isAdmin, isSuperAdmin } = useUser();
  const [isConnectMasterAccountVisible, setIsConnectMasterAccountVisible] =
    useState(false);

  useEffect(() => {
    const setConnectionStatus = async () => {
      if (typeof locationId === 'undefined') {
        setIsConnectMasterAccountVisible(false);
        return;
      }
      try {
        const isConnected = !!(
          await locationControllerApi.getLocationWindTurbineDetails({
            locationId,
          })
        ).data?.connected;
        setIsConnectMasterAccountVisible(!isConnected);
      } catch {
        setIsConnectMasterAccountVisible(false);
      }
    };
    setConnectionStatus();
  }, [locationControllerApi, locationId]);

  if (typeof locationId === 'undefined') {
    return null;
  }

  return (
    <>
      <Typography variant={extended ? 'bodyM' : 'bodyS'}>
        <Trans
          i18nKey="notifications.locationAdded"
          values={{ eventDateTime: getStringFromISODate(eventDateTime) }}
          components={[
            <LocationLink
              key={`locationAdded-link-${locationId}`}
              locationId={locationId}
              linkText={locationName as string}
              newTab={extended}
            />,
          ]}
        />
      </Typography>
      {isConnectMasterAccountVisible && (isAdmin || isSuperAdmin) && (
        <CommonButton
          sx={{ width: 'fit-content' }}
          size="medium"
          onClick={() => {
            setIgnoreClickOutside(true);
            openConnectMAPopup({ locationName });
          }}
        >
          {t('buttons.connectMasterAccount')}
        </CommonButton>
      )}
    </>
  );
};
