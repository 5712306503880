import { styled } from '@mui/material';

export const ParkComponentHead = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(4),

  [theme.breakpoints.down('desktop')]: {
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3),
  },
}));
