import { styled } from '@mui/material';

export const MainContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3.5),
  borderBottom: `1px solid ${theme.palette.grey[100]}`,

  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(2.5, 3, 3),
  },
}));
