import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

export const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '220px',
    textAlign: 'center',
  },

  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
    {
      marginBottom: '4px',
    },
}));
