import { Box, SxProps } from '@mui/material';
import { FC, useState } from 'react';

import { TooltipIcon, TooltipStyled } from './styles';

interface Props {
  title: string;
  className?: string;
  sx?: SxProps;
}

export const InfoTooltip: FC<Props> = ({ title, className, sx }) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  return (
    <TooltipStyled
      title={title}
      placement="top"
      onOpen={() => setTooltipOpen(true)}
      onClose={() => setTooltipOpen(false)}
      open={isTooltipOpen}
      disableTouchListener
    >
      <Box
        display="flex"
        className={className}
        sx={sx}
        onClick={(e) => {
          e.preventDefault();
          setTooltipOpen((prev) => !prev);
        }}
      >
        <TooltipIcon />
      </Box>
    </TooltipStyled>
  );
};
