import { styled } from '@mui/material';

import CommonButton from 'components/common/CommonButton';

export const OccurredErrorsActionsMenuButton = styled(CommonButton)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '32px',
  width: '32px',
  minWidth: 'unset',
  padding: 0,

  '& svg': {
    pointerEvents: 'none',
    transform: 'rotate(90deg)',
  },
}));
