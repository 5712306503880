import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { defaultValues } from './constants';
import {
  ButtonsWrapper,
  CurrentStepWrapper,
  Dialogue,
  FormWrapper,
} from './styles';
import { FormValues } from './types';
import { validationSchemas } from './validationSchema';
import routePaths from '../../../../constants/routePaths';
import useApi from '../../../../contexts/api';
import CommonButton from '../../../common/CommonButton';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import { SelectOrCreateCustomer } from 'components/common/SelectOrCreateCustomer';
import { SelectParksOrMacAddresses } from 'components/common/SelectParksOrMacAddresses';
import { ArrowLeft, ArrowRight } from 'components/icons';
import { UserCredentials } from 'components/shared';
import useGlobalData from 'contexts/globalData';
import useResponsePopup from 'contexts/responsePopup';
import { MasterAccountCreateResponseDto } from 'openapi-api/admin-service';
import { useUniqueEntityValidators } from 'utils/hooks/useUniqueEntityValidators';

const AssetOnboarding = () => {
  const { t } = useTranslation();
  const {
    isAssetOnboardingFormOpened,
    setIsAssetOnboardingFormOpened,
    setUpdateWTList,
  } = useGlobalData();
  const { assetOnboardingControllerApi, masterAccountControllerApi } = useApi();
  const [showSuccess, setShowSuccess] = useState(false);
  const [createdUser, setCreatedUser] =
    useState<MasterAccountCreateResponseDto | null>(null);
  const { openPopup, closePopup } = useResponsePopup();
  const navigate = useNavigate();
  const [step, setStep] = useState<0 | 1>(0);

  const { isUsernameExist } = useUniqueEntityValidators();

  const schema = useMemo(() => {
    return validationSchemas[step](t, isUsernameExist);
  }, [isUsernameExist, step, t]);

  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    watch,
    formState: { isDirty },
    trigger,
  } = form;

  const existingCustomer = watch('existingCustomer');
  const newCustomer = watch('newCustomer');

  const closeOnboardingDialogue = useCallback(() => {
    setIsAssetOnboardingFormOpened(false);
    setStep(0);
    reset();
  }, [reset, setIsAssetOnboardingFormOpened]);

  const createMasterAccount = useCallback(
    async (username: string) => {
      if (!username) return;

      const { data } = await masterAccountControllerApi.createMasterAccount({
        masterAccountCreateRequestDto: { name: username },
      });

      setCreatedUser(data);
    },
    [masterAccountControllerApi],
  );

  const handleSubmit = form.handleSubmit(async (data: FormValues) => {
    try {
      if (!createdUser && data.newCustomer) {
        await createMasterAccount(data.newCustomer);
      }

      const owner = (data.existingCustomer || data.newCustomer) as string;

      const macAddresses = [
        ...(data.existingMacAddresses ? data.existingMacAddresses : []),
        ...(data.newMacAddresses
          ? data.newMacAddresses.map(({ value }) => value)
          : []),
      ];

      await assetOnboardingControllerApi.addMappingNew({
        customerMappingNewDto: {
          masterAccountName: owner,
          ...(data.existingParks?.length
            ? { locationNames: data.existingParks }
            : { publicIds: macAddresses }),
        },
      });
      setUpdateWTList(true);
      setShowSuccess(true);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.message) {
        const message: string = e.response.data.message;
        toast.error(message);

        if (message.endsWith('already exists.')) {
          if (message.startsWith('Customer')) {
            setStep(0);
            await trigger('newCustomer');
          }
        }
      }
    }
  });

  const canGoStep2 = useMemo(() => {
    if (existingCustomer || newCustomer) return true;
  }, [existingCustomer, newCustomer]);

  const goStep2 = useCallback(async () => {
    try {
      const isFieldValid = await trigger('newCustomer');
      if (!isFieldValid) return;
      await trigger('existingCustomer');
      setStep(1);
    } catch {}
  }, [trigger]);

  useEffect(() => {
    if (showSuccess) {
      closeOnboardingDialogue();
      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('components.assetOnboarding.successTitle'),
        children: createdUser ? (
          <UserCredentials
            variant="customer"
            username={createdUser.name}
            password={createdUser.password}
          />
        ) : undefined,
        primaryButton: {
          text: t('continueSession'),
          onClick: () => {
            closePopup();
            setShowSuccess(false);
            navigate(routePaths.parks.root);
          },
        },
      });
    }
  }, [
    showSuccess,
    closeOnboardingDialogue,
    createdUser,
    openPopup,
    closePopup,
    navigate,
    t,
  ]);

  return (
    <Dialogue
      open={isAssetOnboardingFormOpened}
      onClose={closeOnboardingDialogue}
      fullWidth
    >
      <FormProvider {...form}>
        <FormWrapper onSubmit={handleSubmit}>
          <Box height="100%" display="flex" flexDirection="column">
            <Typography
              variant="subheading"
              sx={{ pb: 1 }}
              color="green.700"
              component="div"
            >
              {t('step')} {`${step + 1}/2`}
            </Typography>
            <Typography variant="h2" sx={{ mb: 5 }} color="black.600">
              {t('components.assetOnboarding.title')}
            </Typography>

            <CurrentStepWrapper isCurrent={step === 0}>
              <Typography
                variant="bodyM"
                color="black.600"
                component="div"
                sx={{ pb: 3 }}
              >
                {t('components.assetOnboarding.subTitleMasterAccount')}
              </Typography>

              <SelectOrCreateCustomer />

              <ButtonsWrapper
                padding={2}
                display="flex"
                gap={2}
                justifyContent="center"
              >
                <CommonButton
                  variant="outlined"
                  onClick={closeOnboardingDialogue}
                  data-testid="close-button"
                >
                  {t('cancel')}
                </CommonButton>
                <CommonButton
                  variant="contained"
                  data-testid="submit"
                  endIcon={<ArrowRight />}
                  disabled={!canGoStep2}
                  onClick={goStep2}
                >
                  {t('next')}
                </CommonButton>
              </ButtonsWrapper>
            </CurrentStepWrapper>

            <CurrentStepWrapper isCurrent={step === 1}>
              <Typography
                variant="bodyM"
                color="black.600"
                component="div"
                sx={{ pb: 3 }}
              >
                {t('components.assetOnboarding.subTitlePark')}
              </Typography>

              <SelectParksOrMacAddresses />

              <ButtonsWrapper display="flex" gap={2} justifyContent="center">
                <CommonButton
                  variant="outlined"
                  onClick={() => setStep(0)}
                  data-testid="close-button"
                  startIcon={<ArrowLeft />}
                >
                  {t('back')}
                </CommonButton>
                <CommonButton
                  variant="contained"
                  data-testid="submit"
                  type="submit"
                  disabled={!isDirty}
                >
                  {t('buttons.submit')}
                </CommonButton>
              </ButtonsWrapper>
            </CurrentStepWrapper>
          </Box>
        </FormWrapper>
      </FormProvider>
    </Dialogue>
  );
};

export default AssetOnboarding;
