import { ParameterGroup } from 'components/shared/ParametersSelect/constants';

export const groupWidgets = (
  currentWidgets: {
    group: ParameterGroup;
    panelId: number;
    tKey: string;
  }[],
) => {
  const currentWidgetsGrouped: {
    group: ParameterGroup;
    widgets: {
      group: ParameterGroup;
      panelId: number;
      tKey: string;
    }[];
  }[] = [];

  currentWidgets.forEach((widget) => {
    const groupIndex = currentWidgetsGrouped.findIndex(
      ({ group }) => group === widget.group,
    );
    if (groupIndex === -1) {
      currentWidgetsGrouped.push({ group: widget.group, widgets: [widget] });
    } else {
      currentWidgetsGrouped[groupIndex].widgets.push(widget);
    }
  });

  return currentWidgetsGrouped;
};
