import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams, useSearchParams } from 'react-router-dom';

import { ErrorsHistoryLogReportWrapper } from './styles';
import { WindTurbineHistoryLogItem } from '../WindTurbineHistoryLog/WindTurbineHistoryLogItem';
import useApi from 'contexts/api';
import { HistoryLogDto } from 'openapi-api/admin-service';
import unixMsToHumanReadable from 'utils/functions/unixMsToHumanReadable';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

export const ErrorsHistoryLogReport = () => {
  const { publicId } = useParams();
  const [searchParams] = useSearchParams();
  const { assetControllerApi, historyLogControllerApi } = useApi();
  const { t } = useTranslation();

  const [historyLogs, setHistoryLogs] = useState<HistoryLogDto[]>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const getAsset = useCallback(async () => {
    if (!publicId) return undefined;

    try {
      return (
        await assetControllerApi.getAssetDetails({
          assetPublicIdsDto: { publicIds: [publicId] },
        })
      ).data;
    } catch {}
  }, [publicId, assetControllerApi]);

  const { resource: assets } = useAsyncResource({
    defaultValue: undefined,
    fetchResource: getAsset,
  });

  const loadHistoryLogs = useCallback(async () => {
    if (!publicId) return undefined;

    try {
      const result = (
        await historyLogControllerApi.getHistoryLogs({
          type: 'STATUSES_AND_ERRORS',
          assetPublicId: publicId,
          fromCreateDatetime: dayjs(
            Number(searchParams.get('from')),
          ).toISOString(),
          toCreateDatetime: dayjs(Number(searchParams.get('to'))).toISOString(),
          page,
          size: 20,
        })
      ).data;

      if (result.empty) return;

      setHistoryLogs([...historyLogs, ...(result.content ?? [])]);
      setPage(page + 1);
      setHasMore(page < (result.totalPages ?? 0));
    } catch {}
  }, [historyLogControllerApi, historyLogs, page, publicId, searchParams]);

  useEffect(() => {
    loadHistoryLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorsHistoryLogReportWrapper>
      <Grid
        container
        justifyContent="space-between"
        sx={{ mb: 4 }}
        gap={4}
        flexDirection={{ mobile: 'column', tablet: 'row' }}
      >
        <Grid>
          <Typography variant="h5" sx={{ mb: 2.5 }}>
            {t('pages.reports.errorsHistoryLogPage.title')}
          </Typography>
          <Typography variant="h1">{assets?.[0]?.turbineName}</Typography>
        </Grid>
        <Grid
          display="flex"
          flexDirection="column"
          alignItems={{ mobile: 'flex-start', tablet: 'flex-end' }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            {t('pages.reports.errorsHistoryLogPage.reportFor', {
              customerName: searchParams.get('customer'),
            })}
          </Typography>
          <Grid display="flex" gap={1.5} sx={{ mb: 1 }}>
            <Typography variant="bodySStrong">
              {t('pages.reports.errorsHistoryLogPage.locationName')}
            </Typography>
            <Typography variant="bodyS">{assets?.[0]?.locationName}</Typography>
          </Grid>
          <Grid display="flex" gap={1.5}>
            <Typography variant="bodySStrong">
              {t('pages.reports.errorsHistoryLogPage.reportPeriod')}
            </Typography>
            <Typography variant="bodyS">
              {unixMsToHumanReadable(searchParams.get('from'))}
              {' - '}
              {unixMsToHumanReadable(searchParams.get('to'))}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={(theme) => ({
          borderRadius: '12px',
          border: `1px solid ${theme.palette.grey[100]}`,
        })}
      >
        <InfiniteScroll
          dataLength={historyLogs.length}
          next={loadHistoryLogs}
          hasMore={hasMore}
          loader={<></>}
        >
          {historyLogs.map((item) => (
            <WindTurbineHistoryLogItem key={item.id} {...item} noBadge />
          ))}
        </InfiniteScroll>
      </Box>
    </ErrorsHistoryLogReportWrapper>
  );
};
