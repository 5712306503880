import { styled } from '@mui/material';

export const AccessDeniedInnerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(14),
  marginTop: theme.spacing(-4.5),

  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: theme.spacing(16),
    marginTop: theme.spacing(-2.75),
  },

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(12.5),
    marginTop: theme.spacing(-8.5),
  },
}));
