import { styled } from '@mui/material';

export const TemplateBottomRow = styled('div', {
  shouldForwardProp: (propName) => propName !== 'extended',
})<{ extended: boolean }>(({ theme, extended }) => ({
  display: 'flex',
  flexDirection: extended ? 'row' : 'column',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  alignItems: extended ? 'center' : 'flex-start',
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));
