import { fieldsToSend } from './constants';
import {
  ParkAssetsStatuses,
  ParksSortingOrder,
  ParksSortingValues,
} from '../Parks/types';
import { LocationControllerApiGetLocationsRequest } from 'openapi-api/admin-service';
import { formatObjFromQuery } from 'utils/functions/formatQuery';

export const getRequestParams = (
  searchParams: URLSearchParams,
): LocationControllerApiGetLocationsRequest => {
  const objFromQuery = formatObjFromQuery(fieldsToSend, searchParams);
  const filterByUnsetCoordinates =
    typeof objFromQuery.hasCoordinates !== 'undefined' &&
    objFromQuery.hasCoordinates === 'showParksWithoutCoordinates';

  return {
    page: +(objFromQuery.page ?? 0),
    size: +(objFromQuery.size ?? 10),
    status: objFromQuery.status as ParkAssetsStatuses,
    customerId: objFromQuery.customerId
      ? Number(objFromQuery.customerId)
      : undefined,
    search: (objFromQuery.search as string) || undefined,
    sort: objFromQuery.sort
      ? ((objFromQuery.sort as string).split(',')[0] as ParksSortingValues)
      : undefined,
    sortOrder: objFromQuery.sort
      ? ((objFromQuery.sort as string).split(',')[1] as ParksSortingOrder)
      : undefined,
    ...(filterByUnsetCoordinates ? { hasCoordinates: false } : {}),
  };
};
