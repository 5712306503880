import { styled } from '@mui/material';

export const ComparisonChartLinkWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5, 4),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(3),
  backgroundColor: theme.palette.grey[100],

  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(2.5, 3),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));
