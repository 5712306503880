import { styled } from '@mui/material';

import publicPath from 'utils/publicPath';

export const NotFoundImage = styled('div')(({ theme }) => ({
  flexShrink: 0,
  height: '161px',
  width: '384px',
  backgroundImage: `url(${publicPath('/images/not-found.svg')})`,
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',

  [theme.breakpoints.down('desktop')]: {
    height: '118px',
    width: '280px',
  },
}));
