import { SxProps } from '@mui/material';
import { ReactNode, MouseEvent } from 'react';

export enum BadgeVariant {
  Active = 'active',
  Error = 'error',
  Inactive = 'inactive',
  Warning = 'warning',
}

export enum BadgeSize {
  Meduim = 'medium',
  Small = 'small',
}

export interface BadgeProps {
  variant: BadgeVariant;
  size: BadgeSize;
  text: ReactNode;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  sx?: SxProps;
  className?: string;
  dataTestId?: string;
}
