import { styled } from '@mui/material';

export const GaugeChartWrapper = styled('div')(({ theme }) => ({
  width: '160px',
  height: '104px',
  overflow: 'hidden',

  [theme.breakpoints.down('desktop')]: {
    height: '44px',
    width: '160px',
  },

  [theme.breakpoints.down('tablet')]: {
    width: '130px',
  },
}));
