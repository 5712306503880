import { styled } from '@mui/material';

import { CircleInfo } from 'components/icons';

export const TooltipIcon = styled(CircleInfo)(({ theme }) => ({
  cursor: 'pointer',

  '& > path:nth-child(1), & > path:nth-child(3)': {
    stroke: theme.palette.grey[300],
  },
  '& > path:nth-child(2)': {
    fill: theme.palette.grey[300],
  },

  '&:hover > path:nth-child(1), &:hover > path:nth-child(3)': {
    stroke: theme.palette.black[500],
  },
  '&:hover > path:nth-child(2)': {
    fill: theme.palette.black[500],
  },
}));
