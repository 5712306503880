import { styled } from '@mui/material';

export const CopyIdBlockWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1.25),
  padding: theme.spacing(0.5, 2),
  borderRadius: '25px',
  backgroundColor: theme.palette.grey[50],
  cursor: 'pointer',
}));
