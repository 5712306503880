import { ListItemIcon, SxProps, Theme } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { FC, MouseEvent, ReactNode, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import MenuButtonWrapper from './styles/MenuButtonWrapper';
import MenuWrapper from './styles/MenuWrapper';
import { ChevronDownS, ChevronUp } from 'components/icons';

export type MenuItemType = {
  label: string;
  onClick?: () => void;
  link?: string;
  newTab?: boolean;
  pattern?: RegExp;
  icon?: JSX.Element;
  color?: string;
};

interface Props {
  items: MenuItemType[];
  title?: ReactNode;
  withExpandIcon?: boolean;
  sx?: SxProps<Theme>;
  customIcons?: {
    up?: ReactNode;
    down?: ReactNode;
  };
}

export const DropdownMenu: FC<Props> = ({
  items,
  title,
  withExpandIcon,
  sx,
  customIcons,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const chevron = useMemo(() => {
    if (!withExpandIcon) return null;
    return open
      ? customIcons?.up || <ChevronUp />
      : customIcons?.down || <ChevronDownS />;
  }, [open, withExpandIcon, customIcons]);

  return (
    <div>
      <MenuButtonWrapper onClick={handleClick} sx={sx}>
        <Typography variant="bodySStrong" color="black.900">
          {title}
        </Typography>
        {chevron}
      </MenuButtonWrapper>
      <MenuWrapper
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ minWidth: '252px' }}
      >
        {items.map((item) => (
          <MenuItem
            component={item.link ? Link : 'li'}
            to={item.link}
            key={`${item.label}-${item.link}`}
            onClick={() => {
              handleClose();
              item.onClick?.();
            }}
            sx={{ minWidth: '252px' }}
            {...(item.newTab && { target: '_blank' })}
          >
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <Typography variant="bodyM" color={item.color}>
              {item.label}
            </Typography>
          </MenuItem>
        ))}
      </MenuWrapper>
    </div>
  );
};
