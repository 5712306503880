import { SxProps } from '@mui/material';

export enum WindTurbinesTableContext {
  Park = 'park',
  Unconfigured = 'unconfigured',
}

export interface WindTurbinesTableProps {
  locationId?: number;
  showUnconfigured?: boolean;
  className?: string;
  sx?: SxProps;
}
