import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { WarningWrapper } from './styles';
import CommonButton from 'components/common/CommonButton';
import { CircleWarning } from 'components/icons';
import { RolePermissionWrapper } from 'components/shared';
import { UserDetailsDtoUserRoleEnum } from 'openapi-api/admin-service';

interface Props {
  onButtonClick: () => void;
}

export const WarningEditParkDetails: FC<Props> = ({ onButtonClick }) => {
  const { t } = useTranslation();

  return (
    <WarningWrapper>
      <Box display="flex" gap={1.5}>
        <Box flexShrink={0}>
          <CircleWarning height={24} width={24} />
        </Box>
        <Typography variant="bodySStrong">
          {t('pages.parks.warningEditParkDetails')}
        </Typography>
      </Box>
      <RolePermissionWrapper
        disallowedRoles={[UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER]}
      >
        <CommonButton
          onClick={(e) => {
            e.preventDefault();
            onButtonClick();
          }}
          sx={{
            flexShrink: 0,
            backgroundColor: 'warning.main',
            '&:hover': { backgroundColor: 'warning.main' },
          }}
        >
          {t('pages.parkDetails.editParkDetails.title')}
        </CommonButton>
      </RolePermissionWrapper>
    </WarningWrapper>
  );
};
