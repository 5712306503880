import { alpha, styled } from '@mui/material';

export const WarningCoordinatesUnsetWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(3.5),
  height: '338px',
  borderRadius: '20px',
  backgroundColor: alpha(theme.palette.warning.main, 0.3),
}));
