import { Close } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MacAddressRow } from '../styles';
import CommonButton from 'components/common/CommonButton';
import TextField from 'components/common/form/TextField';
import { Plus } from 'components/icons';

interface INewMacAddressesProps {
  onAddNewMac: () => void;
}

export const NewMacAddresses: FC<INewMacAddressesProps> = ({ onAddNewMac }) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'newMacAddresses',
  });

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap={4}
        mt={fields.length ? 4 : 0}
      >
        {fields.map((field, index) => (
          <MacAddressRow key={field.id}>
            <TextField
              label={t('components.assetOnboarding.mac')}
              fullWidth
              name={`newMacAddresses[${index}].value`}
              testId={`newMacAddresses[${index}]`}
              sx={{ mb: 0 }}
            />
            <IconButton
              onClick={() => remove(index)}
              data-testid={`newMacAddresses.remove.${index}`}
            >
              <Close />
            </IconButton>
          </MacAddressRow>
        ))}
      </Box>

      <CommonButton
        variant="text"
        onClick={() => {
          onAddNewMac();
          append({ value: '' });
        }}
        data-testid="add-mac-address"
        size="medium"
        sx={{
          mt: 2.5,
          p: 0,
          width: 'fit-content',
          textTransform: 'none',
          fontSize: 16,
        }}
      >
        <Plus />
        {t('components.assetOnboarding.addMac')}
      </CommonButton>
    </>
  );
};
