import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

export const ButtonsWrapper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(6.5),
  padding: 0,
  [theme.breakpoints.down('desktop')]: {
    marginTop: 'auto',
    paddingTop: theme.spacing(5),
  },
  [theme.breakpoints.down('tablet')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
}));
