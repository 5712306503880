import { styled } from '@mui/material';

import { ConditionIcon } from 'components/shared';

export const ConditionIconStyled = styled(ConditionIcon)(({ theme }) => ({
  '& > svg': {
    height: '32px',
    width: '32px',
  },

  [theme.breakpoints.down('desktop')]: {
    '& > svg': {
      height: '24px',
      width: '24px',
    },
  },
}));
