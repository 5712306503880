import { FC } from 'react';
import { Link } from 'react-router-dom';

import { ListingWrapper } from './styles';
import { ParkCard } from '../ParkCard';
import routePaths from 'constants/routePaths';
import { LocationWithWindTurbineMetricsDto } from 'openapi-api/admin-service';

interface Props {
  items?: LocationWithWindTurbineMetricsDto[];
  isUnassigned?: boolean;
  onEditParkDetailsButtonClick: (locationId?: number) => void;
  onConnectMasterAccountClick?: (locationName?: string) => void;
}

export const ParkCardsList: FC<Props> = ({
  items,
  isUnassigned,
  onEditParkDetailsButtonClick,
  onConnectMasterAccountClick,
}) => {
  return (
    <ListingWrapper>
      {items?.map((item) => (
        <Link
          to={routePaths.parks.specific(item.locationId!).root}
          key={item.locationName}
        >
          <ParkCard
            data={item}
            isUnassigned={isUnassigned}
            onEditParkDetailsButtonClick={onEditParkDetailsButtonClick}
            onConnectMasterAccountClick={onConnectMasterAccountClick}
          />
        </Link>
      ))}
    </ListingWrapper>
  );
};
