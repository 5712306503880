import { styled } from '@mui/material';

export const TopBlockWrapper = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.white.main,
  zIndex: 1,

  padding: theme.spacing(2, 2.5),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  alignItems: 'center',
}));
