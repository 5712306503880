import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BreadcrumbItem } from 'components/common/Breadcrumbs';
import { UserHistoryLog } from 'components/shared/UserHistoryLog';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import { isNotFoundError } from 'contexts/auth/helpers';
import {
  UserDetailsDtoStatusEnum,
  UserDetailsDtoUserRoleEnum,
} from 'openapi-api/admin-service';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

export const CustomerUserHistoryLog: FC = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { masterAccountControllerApi } = useApi();

  const getCustomerUserDetails = useCallback(async () => {
    if (!userId) return;

    try {
      return (
        await masterAccountControllerApi.getMasterAccountCustomerUserDetailsById(
          { id: Number(userId) },
        )
      ).data;
    } catch (error) {
      if (isNotFoundError(error)) {
        navigate(routePaths.notFound);
      }
    }
  }, [userId, masterAccountControllerApi, navigate]);

  const { resource: customerUserDetails } = useAsyncResource({
    fetchResource: getCustomerUserDetails,
  });

  const breadcrumbs = useMemo(
    (): BreadcrumbItem[] => [
      {
        label: t('masterAccount'),
        path: routePaths.users.customerList.profile(
          customerUserDetails?.masterAccountId!,
        ).root,
      },
    ],
    [customerUserDetails, t],
  );

  return (
    <UserHistoryLog
      breadcrumbs={breadcrumbs}
      userDetails={{
        username: customerUserDetails?.masterAccountName,
        id: Number(userId!),
        userRole:
          customerUserDetails?.customerUserRole as unknown as UserDetailsDtoUserRoleEnum,
        status:
          customerUserDetails?.customerUserStatus as unknown as UserDetailsDtoStatusEnum,
      }}
    />
  );
};
