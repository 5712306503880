/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { LocationDto } from './location-dto';

/**
 * 
 * @export
 * @interface AssetDto
 */
export interface AssetDto {
    /**
     * 
     * @type {number}
     * @memberof AssetDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'publicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'status'?: AssetDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'operationalStatus'?: AssetDtoOperationalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'accelerationStatus'?: AssetDtoAccelerationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'createDatetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'updateDatetime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AssetDto
     */
    'connected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'customerName'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssetDto
     */
    'customerId'?: number;
    /**
     * 
     * @type {LocationDto}
     * @memberof AssetDto
     */
    'location'?: LocationDto;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'errorText'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'errorType'?: AssetDtoErrorTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AssetDtoStatusEnum {
    NOT_SET = 'NOT_SET',
    CREATED = 'CREATED',
    ONBOARDING = 'ONBOARDING',
    OFFLINE = 'OFFLINE',
    READY = 'READY',
    ERROR = 'ERROR',
    DELETED = 'DELETED'
}
/**
    * @export
    * @enum {string}
    */
export enum AssetDtoOperationalStatusEnum {
    NOT_SET = 'NOT_SET',
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED'
}
/**
    * @export
    * @enum {string}
    */
export enum AssetDtoAccelerationStatusEnum {
    NOT_SET = 'NOT_SET',
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED'
}
/**
    * @export
    * @enum {string}
    */
export enum AssetDtoErrorTypeEnum {
    CONNECTIVITY = 'CONNECTIVITY',
    TAGS_COLLECTION_ERROR = 'TAGS_COLLECTION_ERROR',
    FAULT_COMMON_SIGNAL = 'FAULT_COMMON_SIGNAL'
}


