import { styled } from '@mui/material';

export const EnergyCounterIconWrapper = styled('div', {
  shouldForwardProp: (propName) =>
    propName !== 'variant' && propName !== 'counterType',
})<{
  variant: 'parkCard' | 'parkPage';
  counterType: 'activeEnergy' | 'lostEnergy';
}>(({ theme, variant, counterType }) => ({
  display: 'flex',
  padding: theme.spacing(variant === 'parkCard' ? 1.375 : 0.75),
  borderRadius: variant === 'parkCard' ? '4px' : '6px',
  backgroundColor:
    counterType === 'activeEnergy'
      ? theme.palette.green[300]
      : theme.palette.grey[100],
}));
