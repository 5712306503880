import { styled } from '@mui/material';

export const AccessDeniedImage = styled('div')(({ theme }) => ({
  height: '156px',
  width: '156px',
  backgroundImage: 'url(/images/access-denied.svg)',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',

  [theme.breakpoints.down('desktop')]: {
    height: '120px',
    width: '120px',
    marginTop: theme.spacing(2.5),
  },

  [theme.breakpoints.down('tablet')]: {
    marginTop: 0,
  },
}));
