import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { UserContext } from './Context';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import useAuth from 'contexts/auth';
import useResponsePopup from 'contexts/responsePopup';
import { UserDetailsDtoUserRoleEnum } from 'openapi-api/admin-service';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

const UserProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const navigate = useNavigate();
  const { userDetailsControllerApi } = useApi();
  const { openPopup, closePopup } = useResponsePopup();
  const { tokenApplied, shouldRefreshUserData, setShouldRefreshUserData } =
    useAuth();
  const { t } = useTranslation();

  const loadUser = useCallback(async () => {
    if (tokenApplied) {
      return (await userDetailsControllerApi.getUserDetails()).data;
    }
  }, [userDetailsControllerApi, tokenApplied]);

  const {
    resource: user,
    fetch: refresh,
    setResource: setUser,
  } = useAsyncResource({
    fetchResource: loadUser,
    defaultIsLoading: true,
    disableGlobalLoader: true,
  });

  const userRoleProps = useMemo(() => {
    switch (user?.userRole) {
      case UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_SUPER_ADMIN:
        return {
          isSuperAdmin: true,
          translatedRole: t(`userRoles.${user.userRole}`),
        };
      case UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_ADMIN:
        return {
          isAdmin: true,
          translatedRole: t(`userRoles.${user.userRole}`),
        };
      default:
        return {
          isViewer: true,
          translatedRole: t(`userRoles.${user?.userRole}`),
        };
    }
  }, [t, user?.userRole]);

  const handleForbiddenError = useCallback(async () => {
    const currentUserRole = user?.userRole;
    const fetchedUser = await loadUser();

    if (currentUserRole !== fetchedUser?.userRole) {
      setUser(fetchedUser);
      setShouldRefreshUserData(false);
      navigate(routePaths.parks.root);

      openPopup({
        variant: NotificationPopupVariants.Warning,
        title: t('popup.roleChanged.title'),
        subtitle: t('popup.roleChanged.subtitle'),
        primaryButton: {
          text: t('continueSession'),
          onClick: closePopup,
        },
      });
    }
  }, [
    user?.userRole,
    loadUser,
    setUser,
    setShouldRefreshUserData,
    openPopup,
    closePopup,
    t,
    navigate,
  ]);

  useEffect(() => {
    if (shouldRefreshUserData) {
      handleForbiddenError();
    }
  }, [shouldRefreshUserData, handleForbiddenError]);

  const contextValue = useMemo(
    () => ({
      user: user || {},
      refresh,
      ...userRoleProps,
    }),
    [refresh, user, userRoleProps],
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
