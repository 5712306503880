import { Box } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  NotFoundImage,
  NotFoundInnerWrapper,
  NotFoundSubtitle,
  NotFoundTitle,
  NotFoundWrapper,
} from './styles';
import CommonButton from 'components/common/CommonButton';
import routePaths from 'constants/routePaths';

export const NotFound: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate(routePaths.parks.root);
  }, [navigate]);

  return (
    <NotFoundWrapper>
      <NotFoundInnerWrapper>
        <NotFoundImage />
        <Box display="flex" alignItems="center" flexDirection="column">
          <NotFoundTitle variant="h2">
            {t('components.notFound.title')}
          </NotFoundTitle>
          <NotFoundSubtitle variant="h3">
            {t('components.notFound.subtitle')}
          </NotFoundSubtitle>
          <CommonButton onClick={onButtonClick}>
            {t('components.notFound.buttonText')}
          </CommonButton>
        </Box>
      </NotFoundInnerWrapper>
    </NotFoundWrapper>
  );
};
