import { SxProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

import { WarningTextBlockWrapper } from './styles';

interface Props {
  children: ReactNode;
  sx?: SxProps;
  className?: string;
}

export const WarningTextBlock: FC<Props> = ({ children, sx, className }) => {
  return (
    <WarningTextBlockWrapper sx={sx} className={className}>
      <Typography
        color="warning.main"
        sx={{ typography: { mobile: 'bodyXSStrong', desktop: 'bodySStrong' } }}
      >
        {children}
      </Typography>
    </WarningTextBlockWrapper>
  );
};
