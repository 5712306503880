import { styled } from '@mui/material';

import MoreActionsButton from 'components/common/MoreActionsButton';

export const MoreActionsButtonStyled = styled(MoreActionsButton)(
  ({ theme }) => ({
    [theme.breakpoints.only('desktop')]: {
      display: 'none',
    },
  }),
);
