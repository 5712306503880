import { createContext } from 'react';

import { ParameterOption } from 'components/shared/ParametersSelect';
import { CustomerDto } from 'openapi-api/admin-service';

export type ComparisonChartFormValues = {
  customerName?: string;
  locationName: string;
  assetName: string;
  fromDate: string;
  toDate: string;
  currentTimeFrame: boolean;
  parameters: ParameterOption[];
};

export interface IComparisonChartProviderFormValues
  extends ComparisonChartFormValues {
  assetPublicId: string;
}

export interface IComparisonChartContext {
  customers: CustomerDto[];
  formValues?: IComparisonChartProviderFormValues;
  setFormValues: React.Dispatch<
    React.SetStateAction<IComparisonChartProviderFormValues | undefined>
  >;
}

export const ComparisonChartContext = createContext<IComparisonChartContext>(
  null!,
);
