import { styled } from '@mui/material';

export const WeatherHourlyWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(3, 4, 2.5),
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: '12px',

  [theme.breakpoints.down('desktop')]: {
    gap: theme.spacing(2.5),
    padding: theme.spacing(3),
  },

  [theme.breakpoints.down('tablet')]: {
    width: '100%',
  },
}));
