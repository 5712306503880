import { Box, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import { EnergyCounterIconWrapper, EnergyCounterWrapper } from './styles';
import { InfoTooltip } from 'components/common/InfoTooltip';
import { ActiveEnergy, LostEnergy } from 'components/icons';

interface Props {
  variant: 'parkCard' | 'parkPage';
  counterType: 'activeEnergy' | 'lostEnergy';
  label: string;
  value: string;
  tooltipTitle: string;
}

export const EnergyCounter: FC<Props> = ({
  variant,
  counterType,
  label,
  value,
  tooltipTitle,
}) => {
  const icon = useMemo(() => {
    const iconProps =
      variant === 'parkCard' ? {} : { height: '24px', width: '24px' };

    return counterType === 'activeEnergy' ? (
      <ActiveEnergy {...iconProps} />
    ) : (
      <LostEnergy {...iconProps} />
    );
  }, [variant, counterType]);

  if (variant === 'parkCard') {
    return (
      <EnergyCounterWrapper variant={variant}>
        <EnergyCounterIconWrapper variant={variant} counterType={counterType}>
          {icon}
        </EnergyCounterIconWrapper>
        <Box display="flex" flexDirection="column">
          <Typography variant="bodyS" color="grey.600">
            {label}
          </Typography>
          <Typography variant="bodyLStrong" color="black.900">
            {value}
          </Typography>
        </Box>
        <InfoTooltip title={tooltipTitle} sx={{ ml: 'auto', mr: 1 }} />
      </EnergyCounterWrapper>
    );
  }

  return (
    <EnergyCounterWrapper variant={variant}>
      <Box display="flex" alignItems="center">
        <EnergyCounterIconWrapper variant={variant} counterType={counterType}>
          {icon}
        </EnergyCounterIconWrapper>
        <Typography variant="bodyM" color="black.500" ml={1.5}>
          {label}
        </Typography>
        <InfoTooltip title={tooltipTitle} sx={{ ml: 'auto' }} />
      </Box>
      <Typography variant="h4" color="black.900">
        {value}
      </Typography>
    </EnergyCounterWrapper>
  );
};
