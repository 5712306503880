import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { generateColumns } from './columns';
import { BottomPartContainer } from '../../styles';
import {
  CommonTable,
  TablePagination,
} from 'components/common/Table/CommonTable';
import useInvestigationContext from 'contexts/investigation';
import useUser from 'contexts/user';
import { getRowsPerPage } from 'utils/functions/getRowsPerPage';

export const History = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const {
    investigationsHistory,
    isHistoryLoading,
    sortModel,
    onSortModelChange,
    paginationModel,
    onPaginationModelChange,
  } = useInvestigationContext();
  const { isViewer } = useUser();

  const columns = useMemo(
    () => generateColumns({ t, isViewer }),
    [t, isViewer],
  );
  const rowsPerPageOptions = useMemo(
    () => getRowsPerPage(t, !isDesktop),
    [isDesktop, t],
  );

  if (investigationsHistory?.totalElements === 0) {
    if (isDesktop) {
      return (
        <BottomPartContainer>
          <Typography variant="h3" color="grey.600" textAlign="center">
            {t('pages.investigation.specifyRestoreRequest')}
          </Typography>
        </BottomPartContainer>
      );
    }

    return null;
  }

  return (
    <BottomPartContainer>
      <Grid>
        <Typography variant="h3" sx={{ mb: { mobile: 3, desktop: 4 } }}>
          {t('pages.investigation.historyTitle')}
        </Typography>
        <Box>
          <CommonTable
            columns={columns}
            rows={investigationsHistory?.content || []}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            sortingMode="server"
            rowCount={investigationsHistory?.totalElements || 0}
            loading={isHistoryLoading}
            sx={{
              maxWidth: '100%',
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'grey.50',
                cursor: 'pointer',
              },
            }}
          />
          <TablePagination
            count={investigationsHistory?.totalElements}
            page={paginationModel.page}
            rowsPerPage={paginationModel.pageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            onPaginationModelChange={onPaginationModelChange}
            sx={{
              ...(!isDesktop && {
                '& .MuiTablePagination-input': { width: 66 },
              }),
            }}
          />
        </Box>
      </Grid>
    </BottomPartContainer>
  );
};
