import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ParkCardsList, StatusFilter, UnassignedBlock } from './components';
import { parksSortingName } from './constants';
import { getRequestParams, getSortingItems } from './helpers';
import { ButtonsWrapper, MainContent, PageWrapper, TopLine } from './styles';
import { ParksSortingOrder, ParksSortingValues } from './types';
import { EditParkDetailsPopup } from '../Park/components';
import { AppliedFilters } from 'components/common/AppliedFilters';
import { Sorting } from 'components/common/Sorting';
import {
  FilterMenu,
  Search,
  TablePagination,
  TablePaginationVariants,
} from 'components/common/Table/CommonTable';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import { FilterTypes, FilterValues } from 'types/general';
import { getRowsPerPage } from 'utils/functions/getRowsPerPage';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';
import useDataGrid from 'utils/hooks/useDataGrid';

export const Parks = () => {
  const { t } = useTranslation();
  const { customerControllerApi, locationControllerApi } = useApi();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  const [locationIdToEdit, setLocationIdToEdit] = useState<number | undefined>(
    undefined,
  );

  const {
    searchParams,
    setSearchParams,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    onSearch,
    onFilterModelChange,
  } = useDataGrid();

  const getParksData = useCallback(async () => {
    const params = getRequestParams(searchParams);
    try {
      const response = (await locationControllerApi.getLocations(params)).data;

      return response;
    } catch {}
  }, [searchParams, locationControllerApi]);

  const { resource: parksData, fetch: refetchParks } =
    useAsyncResourceWithPulling({
      fetchResource: getParksData,
      pullingInterval: 30,
    });

  const getCustomers = useCallback(async () => {
    try {
      return (
        await customerControllerApi.allCustomers({ status: ['ACTIVE'] })
      ).data.map((customer) => ({
        label: customer.name?.toString() || '',
        value: customer.id?.toString() || '',
      }));
    } catch {
      return [];
    }
  }, [customerControllerApi]);

  const { resource: customers } = useAsyncResource({
    defaultValue: [],
    fetchResource: getCustomers,
    disableGlobalLoader: true,
  });

  const sortingItems = useMemo(() => getSortingItems(t), [t]);

  const filterValues = useMemo(
    (): FilterValues => ({
      customerId: {
        label: t('pages.parks.filters.customerId'),
        values: customers.map((customer) => ({
          label: customer.label,
          value: customer.value,
        })),
        type: FilterTypes.select,
      },
      hasCoordinates: {
        label: t('pages.parks.filters.hasCoordinates.title'),
        values: [
          {
            label: t('pages.parks.filters.hasCoordinates.all'),
            value: null,
          },
          {
            label: t(
              'pages.parks.filters.hasCoordinates.requireSpecifyingDetails',
            ),
            value: 'showParksWithoutCoordinates',
          },
        ],
        type: FilterTypes.radio,
      },
    }),
    [t, customers],
  );

  const checkedFilterValues = useMemo(
    () => ({
      customerId: { value: searchParams.get('customerId') },
      hasCoordinates: { value: searchParams.get('hasCoordinates') },
    }),
    [searchParams],
  );

  const rowsPerPageOptions = useMemo(
    () => getRowsPerPage(t, !isDesktop),
    [isDesktop, t],
  );

  useEffect(() => {
    setSearchParams(
      (prev) => {
        if (prev.get(parksSortingName) === null) {
          prev.set(
            parksSortingName,
            `${ParksSortingValues.addedDatetime},${ParksSortingOrder.desc}`,
          );
        }
        return prev;
      },
      { replace: true },
    );
  }, [setSearchParams]);

  const onEditParkDetailsButtonClick = useCallback((locationId?: number) => {
    setLocationIdToEdit(locationId);
  }, []);

  return (
    <PageWrapper>
      <TopLine>
        <Typography color="white.main" variant="h1">
          {t('pages.parks.title')}
        </Typography>
        <ButtonsWrapper>
          <Search onSearch={onSearch} />
          <Sorting
            sortingName={parksSortingName}
            items={sortingItems}
            onSortModelChange={onSortModelChange}
          />
          <FilterMenu
            checkedFilterValues={checkedFilterValues}
            filterValues={filterValues}
            onFilterModelChange={onFilterModelChange}
          />
        </ButtonsWrapper>
      </TopLine>
      <StatusFilter
        counters={parksData?.totalLocationCounts}
        onFilterModelChange={onFilterModelChange}
      />
      <MainContent>
        {!!(
          parksData?.unassignedLocationCount ||
          parksData?.unconfiguredTurbineCount
        ) && (
          <Box
            display="grid"
            gridTemplateColumns={{
              mobile: '1fr',
              tablet:
                parksData.unassignedLocationCount &&
                parksData.unconfiguredTurbineCount
                  ? '1fr 1fr'
                  : '1fr',
            }}
            gap={1.5}
            mb={{ mobile: 1.5, desktop: 5 }}
          >
            {!!parksData.unassignedLocationCount && (
              <UnassignedBlock
                counter={t('pages.parks.unassignedParks.counter', {
                  count: parksData.unassignedLocationCount,
                })}
                title={t('pages.parks.unassignedParks.title')}
                subtitle={t('pages.parks.unassignedParks.subtitle')}
                buttonText={t('pages.parks.unassignedParks.buttonText')}
                buttonLink={routePaths.parks.unassignedParks}
              />
            )}
            {!!parksData.unconfiguredTurbineCount && (
              <UnassignedBlock
                counter={t('pages.parks.unconfiguredTurbines.counter', {
                  count: parksData.unconfiguredTurbineCount,
                })}
                title={t('pages.parks.unconfiguredTurbines.title')}
                subtitle={t('pages.parks.unconfiguredTurbines.subtitle')}
                buttonText={t('pages.parks.unconfiguredTurbines.buttonText')}
                buttonLink={routePaths.parks.unconfigured().root}
              />
            )}
          </Box>
        )}
        <AppliedFilters
          checkedFilterValues={checkedFilterValues}
          filterValues={filterValues}
          onFilterModelChange={onFilterModelChange}
        />
        <ParkCardsList
          items={parksData?.content}
          onEditParkDetailsButtonClick={onEditParkDetailsButtonClick}
        />
        <TablePagination
          variant={TablePaginationVariants.DEFAULT}
          count={parksData?.totalElements}
          page={paginationModel.page}
          rowsPerPage={paginationModel.pageSize}
          rowsPerPageOptions={rowsPerPageOptions}
          onPaginationModelChange={onPaginationModelChange}
          sx={{
            mt: 1.5,
            ...(!isDesktop && {
              '& .MuiTablePagination-input': { width: 66 },
            }),
          }}
        />
      </MainContent>
      {typeof locationIdToEdit !== 'undefined' && (
        <EditParkDetailsPopup
          isOpen
          onClose={() => setLocationIdToEdit(undefined)}
          locationId={locationIdToEdit}
          onSuccess={refetchParks}
        />
      )}
    </PageWrapper>
  );
};
