import {
  ClickAwayListener,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import {
  FC,
  useCallback,
  useMemo,
  useState,
  MouseEvent,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { PopperStyled, SortingItem, Wrapper, WrapperOuter } from './styles';
import CommonButton from 'components/common/CommonButton';
import MobileBottomMenu from 'components/common/MobileBottomMenu';
import RadioCustom from 'components/common/Radio/Radio';
import { ChevronDownL } from 'components/icons';

interface Props {
  items: { label: string; value: string }[];
  onSortModelChange: (value: GridSortModel) => void;
  sortingName?: string;
  light?: boolean;
  sx?: SxProps;
}

export const Sorting: FC<Props> = ({
  items,
  onSortModelChange,
  sortingName = 'sort',
  light = false,
  sx,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [localValue, setLocalValue] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isSortingOpen = useMemo(() => !!anchorEl, [anchorEl]);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  const onClickAway = useCallback(() => {
    setAnchorEl(null);
    const sortingValue = searchParams.get(sortingName);
    if (sortingValue !== localValue) {
      setLocalValue(sortingValue);
    }
  }, [searchParams, sortingName, localValue]);

  const onWrapperClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(isSortingOpen ? null : event.currentTarget);
    },
    [isSortingOpen],
  );

  const applySorting = useCallback(
    (value: string) => {
      const field = value.split(',')[0];
      const sortDirection = value.split(',')[1] as GridSortDirection;

      onSortModelChange([{ field, sort: sortDirection }]);
      onClickAway();
    },
    [onClickAway, onSortModelChange],
  );

  const onSortingItemClick = useCallback(
    (value: string) => {
      setLocalValue(value);

      if (isDesktop) {
        applySorting(value);
        onClickAway();
      }
    },
    [isDesktop, applySorting, onClickAway],
  );

  const onApplyButtonClick = useCallback(() => {
    if (localValue !== null) {
      applySorting(localValue);
      onClickAway();
    }
  }, [applySorting, localValue, onClickAway]);

  const activeSortingItem = useMemo(():
    | { label: string; value: string }
    | undefined => {
    const value = searchParams.get(sortingName);
    return items.find((item) => item.value === value);
  }, [searchParams, sortingName, items]);

  const itemsToRender = useMemo(
    () =>
      items.map((item) => (
        <SortingItem
          key={item.value}
          onClick={() => onSortingItemClick(item.value)}
        >
          <RadioCustom checked={item.value === localValue} />
          <Typography variant="bodyM">{item.label}</Typography>
        </SortingItem>
      )),
    [items, onSortingItemClick, localValue],
  );

  useEffect(() => {
    const sortingValue = searchParams.get(sortingName);
    if (sortingValue !== null) {
      setLocalValue(sortingValue);
    }
  }, [searchParams, sortingName, setLocalValue]);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <WrapperOuter>
        <Wrapper
          onClick={onWrapperClick}
          isPopperOpened={isSortingOpen}
          light={light}
          sx={sx}
        >
          <Typography variant="bodyM">
            {t('components.sorting.sortBy')}
            {': '}
            {activeSortingItem?.label}
          </Typography>
          <ChevronDownL />
        </Wrapper>
        {isDesktop ? (
          <PopperStyled
            open={isSortingOpen}
            anchorEl={anchorEl}
            disablePortal
            placement="bottom-end"
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
            ]}
            sx={{ mt: 1 }}
          >
            {itemsToRender}
          </PopperStyled>
        ) : (
          <MobileBottomMenu
            title={t('components.sorting.sortBy')}
            isOpen={isSortingOpen}
            onClose={onClickAway}
          >
            <MobileBottomMenu.Content>{itemsToRender}</MobileBottomMenu.Content>
            <MobileBottomMenu.Buttons>
              <CommonButton onClick={onApplyButtonClick} sx={{ mx: 'auto' }}>
                {t('buttons.apply')}
              </CommonButton>
            </MobileBottomMenu.Buttons>
          </MobileBottomMenu>
        )}
      </WrapperOuter>
    </ClickAwayListener>
  );
};
