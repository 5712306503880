import { styled } from '@mui/material';

export const CurrentTimeFrameFieldWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2.5),

  [theme.breakpoints.down('tablet')]: {
    width: '100%',
    justifyContent: 'space-between',
  },
}));
