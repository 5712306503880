import { styled } from '@mui/material';

export const TextEllipsisWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'clamped' && prop !== 'linesVisible',
})<{ clamped?: boolean; linesVisible?: number }>(
  ({ clamped, linesVisible }) => ({
    ...(clamped && {
      WebkitLineClamp: linesVisible,
    }),
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
  }),
);
