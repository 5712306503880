import dayjs from 'dayjs';
import { FC, useMemo } from 'react';

import { HistoryLogItem } from 'components/shared/HistoryLog';
import {
  AccelerationStatusChangedTemplate,
  OnboardingInitiatedTemplate,
  OperationalStatusChangedTemplate,
  ParkDetailsChangedTemplate,
  UserConnectedTemplate,
  UserDisconnectedTemplate,
  WindTurbineDeletedTemplate,
  WindTurbineDetailsChangedTemplate,
} from 'components/shared/HistoryLog/templates/user';
import {
  HistoryLogDto,
  HistoryLogDtoNameEnum,
  UserChangedAssetAccelerationStatusDto,
  UserChangedAssetOperationalStatusDto,
  UserChangedParkDetailsDto,
  UserChangedWindTurbineNameDto,
  UserConnectedToWindTurbineDto,
  UserDeletedWindTurbineDto,
  UserDetailsDtoUserRoleEnum,
  UserDisconnectedFromWindTurbineDto,
  UserInitiatedWindTurbineOnboardingDto,
} from 'openapi-api/admin-service';

interface Props {
  data: HistoryLogDto;
  currentUserRole?: UserDetailsDtoUserRoleEnum;
}

export const UserHistoryLogItem: FC<Props> = ({
  data: { name, type, createDatetime, details, locationId },
  currentUserRole,
}) => {
  const additionalProps = useMemo((): {
    eventDateTime: string;
    locationId?: number;
  } => {
    return {
      locationId,
      eventDateTime: dayjs(details?.eventDateTime).format(
        'DD/MM/YYYY HH:mm:ss',
      ),
    };
  }, [locationId, details?.eventDateTime]);

  const content = useMemo(() => {
    switch (name) {
      case HistoryLogDtoNameEnum.USER_INITIATED_WT_ONBOARDING:
        return (
          <OnboardingInitiatedTemplate
            {...(details as UserInitiatedWindTurbineOnboardingDto)}
            {...additionalProps}
          />
        );
      case HistoryLogDtoNameEnum.USER_CHANGED_WT_OPERATIONAL_STATUS:
        return (
          <OperationalStatusChangedTemplate
            {...(details as UserChangedAssetOperationalStatusDto)}
            {...additionalProps}
          />
        );
      case HistoryLogDtoNameEnum.USER_CHANGED_WT_ACCELERATION_STATUS:
        return (
          <AccelerationStatusChangedTemplate
            {...(details as UserChangedAssetAccelerationStatusDto)}
            {...additionalProps}
          />
        );
      case HistoryLogDtoNameEnum.USER_CHANGED_PARK_DETAILS:
        return (
          <ParkDetailsChangedTemplate
            {...(details as UserChangedParkDetailsDto)}
            {...additionalProps}
          />
        );
      case HistoryLogDtoNameEnum.USER_CHANGED_WT_NAME:
        return (
          <WindTurbineDetailsChangedTemplate
            {...(details as UserChangedWindTurbineNameDto)}
            {...additionalProps}
          />
        );
      case HistoryLogDtoNameEnum.USER_DISCONNECTED_FROM_WT:
        return (
          <UserDisconnectedTemplate
            {...(details as UserDisconnectedFromWindTurbineDto)}
            {...additionalProps}
            currentUserRole={currentUserRole}
          />
        );
      case HistoryLogDtoNameEnum.USER_CONNECTED_TO_WT:
        return (
          <UserConnectedTemplate
            {...(details as UserConnectedToWindTurbineDto)}
            {...additionalProps}
            currentUserRole={currentUserRole}
          />
        );
      case HistoryLogDtoNameEnum.USER_DELETED_WT:
        return (
          <WindTurbineDeletedTemplate
            {...(details as UserDeletedWindTurbineDto)}
            {...additionalProps}
          />
        );
      default:
        return 'event name is undefined';
    }
  }, [currentUserRole, name, details, additionalProps]);

  return (
    <HistoryLogItem type={type} datetime={createDatetime}>
      {content}
    </HistoryLogItem>
  );
};
