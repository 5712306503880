import { Box, Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

import { OperationalStatusChip } from './components';
import {
  LinkIcon,
  TableCellStatusLine,
  WindTurbineActionsButton,
} from './styles';
import { WindTurbinesTableContext } from './types';
import CommonButton from 'components/common/CommonButton';
import { OpenConnectMAProps } from 'components/common/ConnectMasterAccount';
import { DropdownMenu } from 'components/common/DropdownMenu';
import { renderCell } from 'components/common/Table/CommonTable/helpers';
import { TextWithTooltip } from 'components/common/TextWithTooltip';
import { ThreeDots } from 'components/icons';
import { WindTurbineStatus } from 'components/pages/protected/WindTurbine/components';
import {
  WindTurbineDto,
  WindTurbineDtoOperationalStatusEnum,
  WindTurbineDtoStatusEnum,
} from 'openapi-api/admin-service';
import { TranslationFunction } from 'types/general';
import { convertWh } from 'utils/functions/converters';
import { getWindTurbinePageLink } from 'utils/functions/getWindTurbinePageLink';

type GenerateColumnsPropsType = {
  t: TranslationFunction;
  setConnectMAPopupProps?: (connectMAProps: OpenConnectMAProps) => void;
  wtActions?: {
    disconnectMasterAccount: (publicId?: string) => void;
    delete: (publicId?: string) => void;
  };
  tableContext?: WindTurbinesTableContext;
};

export const generateColumns = ({
  t,
  setConnectMAPopupProps,
  wtActions,
  tableContext = WindTurbinesTableContext.Park,
}: GenerateColumnsPropsType): GridColDef<WindTurbineDto>[] => [
  {
    field: 'name',
    headerName: t('components.windTurbinesTable.columns.name'),
    flex: tableContext === WindTurbinesTableContext.Unconfigured ? 2 : 1,
    minWidth: 200,
    sortable: true,
    disableColumnMenu: true,
    renderCell: ({
      value,
      row: { id, status, operationalStatus, publicId },
      api,
    }) => {
      return (
        <Grid
          gap={{ mobile: 1.5, tablet: 2 }}
          display="flex"
          flexDirection="column"
        >
          <TextWithTooltip
            textProps={{ variant: 'h5', color: 'black.900' }}
            tooltipProps={{ placement: 'top' }}
            text={value || publicId}
          />
          <OperationalStatusChip
            operationalStatus={
              operationalStatus ||
              WindTurbineDtoOperationalStatusEnum.DEACTIVATED
            }
          />
          <TableCellStatusLine
            windTurbineStatus={status}
            isFirstRow={
              api.getRowIndexRelativeToVisibleRows(id as number) === 0
            }
          />
        </Grid>
      );
    },
  },
  {
    field: 'status',
    headerName: t('components.windTurbinesTable.columns.status'),
    flex: 1,
    minWidth: 200,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({ value, row: { errorType } }) => (
      <WindTurbineStatus status={value} errorType={errorType} height={25} />
    ),
  },
  {
    field: 'customerName',
    headerName: t('masterAccountName'),
    flex: 1,
    minWidth: 250,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ value, row: { status, publicId } }) =>
      value
        ? renderCell(value, 'bodyM')
        : !!setConnectMAPopupProps &&
          status === WindTurbineDtoStatusEnum.CREATED && (
            <CommonButton
              variant="outlined"
              size="medium"
              onClick={() => setConnectMAPopupProps({ assetId: publicId })}
            >
              {t('buttons.connectMasterAccount')}
            </CommonButton>
          ),
  },
  {
    field: 'windSpeed',
    headerName: t('components.windTurbinesTable.columns.windSpeed'),
    flex: 1,
    minWidth: 140,
    disableColumnMenu: true,
    renderCell: ({ value }) =>
      renderCell(`${(value || 0).toFixed(1)} m/s`, 'bodyM'),
  },
  {
    field: 'activePower',
    headerName: t('components.windTurbinesTable.columns.activePower'),
    flex: 1,
    minWidth: 140,
    disableColumnMenu: true,
    renderCell: ({ value }) =>
      renderCell(`${(value || 0).toFixed(3)} W`, 'bodyM'),
  },
  {
    field: 'totalLostEnergy',
    headerName: t('components.windTurbinesTable.columns.lostEnergy'),
    flex: 1,
    minWidth: 140,
    disableColumnMenu: true,
    renderCell: ({ value }) => {
      const converted = convertWh(value || 0, t);
      return renderCell(`${converted.value} ${converted.label}`, 'bodyM');
    },
  },
  {
    field: 'actions',
    headerName: '',
    minWidth: 72,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row: { publicId, connected, status } }) =>
      wtActions &&
      (connected || status === WindTurbineDtoStatusEnum.CREATED) && (
        <Box onClick={(e) => e.stopPropagation()}>
          <DropdownMenu
            title={
              <WindTurbineActionsButton variant="text">
                <ThreeDots />
              </WindTurbineActionsButton>
            }
            withExpandIcon={false}
            items={[
              ...(connected
                ? [
                    {
                      label: t('buttons.disconnectMasterAccount'),
                      onClick: () =>
                        wtActions.disconnectMasterAccount(publicId),
                    },
                  ]
                : []),
              ...(status === WindTurbineDtoStatusEnum.CREATED
                ? [
                    {
                      label: t('pages.turbines.deleteAsset'),
                      onClick: () => wtActions.delete(publicId),
                      color: 'error.main',
                    },
                  ]
                : []),
            ]}
          />
        </Box>
      ),
  },
  {
    field: 'linkToDetailedPage',
    headerName: '',
    minWidth: 72,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row: { publicId, location } }) => (
      <Link
        to={
          getWindTurbinePageLink({
            assetLocationId: location?.id,
            assetPublicId: publicId as string,
          }).root
        }
        target="_blank"
      >
        <LinkIcon />
      </Link>
    ),
  },
];
