import { styled } from '@mui/material';

export const HistoryLogItemContentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noBadge',
})<{
  noBadge?: boolean;
}>(({ theme, noBadge = false }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(8),

  [theme.breakpoints.down('desktop')]: {
    ...(noBadge && { flexDirection: 'column' }),
    alignItems: noBadge ? 'flex-start' : 'center',
    gap: theme.spacing(1.5),
  },
}));
