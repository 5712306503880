import { Box } from '@mui/material';
import { FC } from 'react';

export enum TurbineAnimationStatus {
  ACTIVE,
  INACTIVE,
  ERROR_ACTIVE,
  ERROR_INACTIVE,
}

interface TurbineAnimationProps {
  status: TurbineAnimationStatus;
}

export const TurbineAnimation: FC<TurbineAnimationProps> = ({ status }) => {
  let bgImage = 'url(/images/turbine-inactive-background.svg)';
  let animate = false;

  switch (status) {
    case TurbineAnimationStatus.ACTIVE:
      bgImage = 'url(/images/turbine-active-background.svg)';
      animate = true;
      break;
    case TurbineAnimationStatus.ERROR_ACTIVE:
      bgImage = 'url(/images/turbine-error-background.svg)';
      animate = true;
      break;
    case TurbineAnimationStatus.ERROR_INACTIVE:
      bgImage = 'url(/images/turbine-error-background.svg)';
      animate = false;
      break;
    default:
      bgImage = 'url(/images/turbine-inactive-background.svg)';
      animate = false;
      break;
  }

  return (
    <Box width="100px" height="148px" sx={{ backgroundImage: bgImage }}>
      <img
        src={animate ? '/images/turbine.gif' : '/images/turbine.png'}
        height="148px"
        alt="turbine"
      />
    </Box>
  );
};
