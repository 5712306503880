import { InvestigationAreaContent } from './InvestigationAreaContent';
import useComparisonChartContext from 'contexts/comparisonChart';
import { WindTurbinePanelsProvider } from 'contexts/WindTurbinePanels';

export const InvestigationArea = () => {
  const { formValues } = useComparisonChartContext();

  return (
    <WindTurbinePanelsProvider
      dashboardType="ADMIN_COMPARISON_CHART"
      publicId={formValues?.assetPublicId}
    >
      <InvestigationAreaContent />
    </WindTurbinePanelsProvider>
  );
};
