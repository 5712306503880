import { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { UserIdLink } from 'components/shared/HistoryLog/styles';
import routePaths from 'constants/routePaths';
import {
  UserChangedAssetOperationalStatusDto,
  UserChangedAssetOperationalStatusDtoAllOfOperationalStatusEnum,
  UserUserRoleEnum,
} from 'openapi-api/admin-service';

export const OperationalStatusChangedTemplate: FC<
  UserChangedAssetOperationalStatusDto
> = ({ assetNameForAdmin, operationalStatus, eventDateTime, subjectUser }) => {
  const { t } = useTranslation();

  const changedFromAdminPortal = useMemo(
    () =>
      subjectUser?.userRole === UserUserRoleEnum.ADMIN_PORTAL_SUPER_ADMIN ||
      subjectUser?.userRole === UserUserRoleEnum.ADMIN_PORTAL_ADMIN,
    [subjectUser],
  );

  const userLinkPath = useMemo((): string | undefined => {
    if (changedFromAdminPortal) {
      return routePaths.users.adminList.profile(subjectUser?.id!).historyLog;
    }

    if (subjectUser?.userRole === UserUserRoleEnum.USER_PORTAL_SUPER_ADMIN) {
      return routePaths.users.customerList.profile(subjectUser.id!).historyLog;
    }

    if (
      subjectUser?.userRole === UserUserRoleEnum.USER_PORTAL_ADMIN ||
      subjectUser?.userRole === UserUserRoleEnum.USER_PORTAL_VIEWER
    ) {
      return routePaths.users.customerList.regularCustomerHistoryLog(
        subjectUser.id!,
      );
    }
  }, [changedFromAdminPortal, subjectUser]);

  const operationalStatusText = useMemo(
    () =>
      operationalStatus ===
      UserChangedAssetOperationalStatusDtoAllOfOperationalStatusEnum.ACTIVATED
        ? t('pages.turbine.on')
        : t('pages.turbine.off'),
    [operationalStatus, t],
  );

  return (
    <Trans
      i18nKey="components.historyLog.templates.asset.activities.operationalStatusChanged"
      values={{
        assetName: assetNameForAdmin,
        status: operationalStatusText.toUpperCase(),
        eventDateTime,
        portal: changedFromAdminPortal ? t('adminPortal') : t('customerPortal'),
      }}
      components={{
        userLink: userLinkPath ? (
          <UserIdLink to={userLinkPath}>{subjectUser?.userId}</UserIdLink>
        ) : (
          <>{'undefined'}</>
        ),
        bold: <strong />,
      }}
    />
  );
};
