import axios from 'axios';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import useApi from 'contexts/api';
import useResponsePopup from 'contexts/responsePopup';

export const useWindTurbineActions = () => {
  const { t } = useTranslation();
  const { assetControllerApi, assetOnboardingControllerApi } = useApi();
  const { openPopup, closePopup } = useResponsePopup();

  const handleDisconnectMasterAccount = useCallback(
    async (publicId?: string, onSuccess?: () => Promise<void>) => {
      if (!publicId) return;

      try {
        await assetOnboardingControllerApi.disconnect({
          publicId: publicId,
        });
        await onSuccess?.();
        openPopup({
          variant: NotificationPopupVariants.Success,
          title: t('components.disconnectMasterAccount.successTitle'),
          primaryButton: {
            text: t('buttons.continueSession'),
            onClick: closePopup,
          },
        });
      } catch (err) {
        openPopup({
          variant: NotificationPopupVariants.Error,
          title: t('components.errorPopup.title'),
          subtitle: t('components.errorPopup.subtitle'),
          secondaryButton: {
            text: t('buttons.cancel'),
            onClick: closePopup,
          },
        });
      }
    },
    [assetOnboardingControllerApi, openPopup, t, closePopup],
  );

  const onDisconnectMasterAccountClick = useCallback(
    (publicId?: string, onSuccess?: () => Promise<void>) => {
      openPopup({
        variant: NotificationPopupVariants.Error,
        title: t('components.disconnectMasterAccount.title'),
        subtitle: t('components.disconnectMasterAccount.subtitle'),
        primaryButton: {
          text: t('components.disconnectMasterAccount.submitButtonText'),
          onClick: () => handleDisconnectMasterAccount(publicId, onSuccess),
        },
        secondaryButton: {
          text: t('buttons.cancel'),
          onClick: closePopup,
        },
      });
    },
    [openPopup, t, handleDisconnectMasterAccount, closePopup],
  );

  const handleDeleteAsset = useCallback(
    async (publicId?: string, onSuccess?: () => Promise<void>) => {
      if (!publicId) return;

      try {
        await assetControllerApi.deleteAsset({
          assetPublicId: publicId as string,
        });
        await onSuccess?.();
        openPopup({
          variant: NotificationPopupVariants.Success,
          title: t('pages.turbine.assetWasDeleted'),
          primaryButton: {
            text: t('continueSession'),
            onClick: closePopup,
          },
        });
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.data.message) {
          toast.error(e.response.data.message);
        }
      }
    },
    [assetControllerApi, closePopup, openPopup, t],
  );

  const onDeleteAssetClick = useCallback(
    (publicId?: string, onSuccess?: () => Promise<void>) => {
      openPopup({
        variant: NotificationPopupVariants.Error,
        title: t('pages.turbine.deleteAssetWarning'),
        subtitle: t('pages.turbine.deletePopupSubtitle'),
        primaryButton: {
          text: t('pages.turbine.yesDelete'),
          onClick: () => handleDeleteAsset(publicId, onSuccess),
        },
        secondaryButton: {
          text: t('buttons.cancel'),
          onClick: closePopup,
        },
      });
    },
    [openPopup, t, handleDeleteAsset, closePopup],
  );

  return { onDeleteAssetClick, onDisconnectMasterAccountClick };
};
