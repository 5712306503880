import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  ActionsWrapper,
  EditAssetButtonDesktop,
  MasterAccountLink,
  RoundChip,
} from './styles';
import { ContainerStyled } from '../../styles';
import { EditAssetDetailsPopup } from '../EditAssetDetailsPopup';
import { MobileMenuActions } from '../MobileMenuActions';
import {
  WindTurbineControl,
  WindTurbineControlStatus,
} from '../WindTurbineControl';
import { ControlStatuses } from '../WindTurbineControl/types';
import { WindTurbineStatus } from '../WindTurbineStatus';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import CommonButton from 'components/common/CommonButton';
import {
  ConnectMasterAccount,
  OpenConnectMAProps,
} from 'components/common/ConnectMasterAccount';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import SuccessDialog from 'components/common/SuccessDialog';
import { RolePermissionWrapper } from 'components/shared';
import { CopyIdBlock } from 'components/shared/CopyIdBlock';
import { DesktopMenuActions } from 'components/shared/DesktopMenuActions';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import useResponsePopup from 'contexts/responsePopup';
import useUser from 'contexts/user';
import useWindTurbine from 'contexts/WindTurbine';
import {
  UserDetailsDtoUserRoleEnum,
  WindTurbineDto,
  WindTurbineDtoAccelerationStatusEnum,
  WindTurbineDtoOperationalStatusEnum,
  WindTurbineDtoStatusEnum,
} from 'openapi-api/admin-service';
import { mapRatedPower } from 'utils/functions/mapRatedPower';
import { usePopup } from 'utils/hooks/usePopup';

interface HeadBlockProps extends WindTurbineDto {}

export const HeadBlock: FC<HeadBlockProps> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));
  const { openPopup, closePopup } = useResponsePopup();
  const { t } = useTranslation();
  const { publicId } = useParams();
  const { isViewer } = useUser();
  const [connectMAPopupProps, setConnectMAPopupProps] =
    useState<OpenConnectMAProps>({});
  const { assetControllerApi, assetOnboardingControllerApi } = useApi();
  const [
    isEditAssetDetailsPopupOpen,
    openEditAssetDetailsPopup,
    closeEditAssetDetailsPopup,
  ] = usePopup();
  const [isDeleteSuccessOpen, openDeleteSuccess, closeDeleteSuccess] =
    usePopup();
  const navigate = useNavigate();

  const {
    windTurbine,
    handleTurbineActivityChange,
    handleTurbineAccelerationChange,
    refetch,
    showDeleteButton,
  } = useWindTurbine();

  const parentPath = useMemo(() => {
    if (windTurbine?.location?.id !== undefined) {
      return routePaths.parks.specific(windTurbine?.location?.id);
    }

    return routePaths.parks.unconfigured();
  }, [windTurbine?.location?.id]);

  const onEditAssetDetailsClick = useCallback(() => {
    openEditAssetDetailsPopup();
  }, [openEditAssetDetailsPopup]);

  const handleDisconnectMasterAccount = useCallback(async () => {
    if (!windTurbine?.publicId) return;

    try {
      await assetOnboardingControllerApi.disconnect({
        publicId: windTurbine.publicId,
      });
      await refetch();
      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('components.disconnectMasterAccount.successTitle'),
        primaryButton: {
          text: t('buttons.continueSession'),
          onClick: closePopup,
        },
      });
    } catch (err) {
      openPopup({
        variant: NotificationPopupVariants.Error,
        title: t('components.errorPopup.title'),
        subtitle: t('components.errorPopup.subtitle'),
        secondaryButton: {
          text: t('buttons.cancel'),
          onClick: closePopup,
        },
      });
    }
  }, [
    windTurbine?.publicId,
    assetOnboardingControllerApi,
    refetch,
    openPopup,
    t,
    closePopup,
  ]);

  const onDisconnectMasterAccountClick = useCallback(() => {
    openPopup({
      variant: NotificationPopupVariants.Error,
      title: t('components.disconnectMasterAccount.title'),
      subtitle: t('components.disconnectMasterAccount.subtitle'),
      primaryButton: {
        text: t('components.disconnectMasterAccount.submitButtonText'),
        onClick: handleDisconnectMasterAccount,
      },
      secondaryButton: {
        text: t('buttons.cancel'),
        onClick: closePopup,
      },
    });
  }, [openPopup, t, handleDisconnectMasterAccount, closePopup]);

  const handleDeleteAsset = useCallback(async () => {
    try {
      await assetControllerApi.deleteAsset({
        assetPublicId: publicId as string,
      });
      closePopup();
      openDeleteSuccess();
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.message) {
        toast.error(e.response.data.message);
      }
    }
  }, [assetControllerApi, publicId, closePopup, openDeleteSuccess]);

  const onDeleteAssetClick = useCallback(() => {
    openPopup({
      variant: NotificationPopupVariants.Error,
      title: t('pages.turbine.deleteAssetWarning'),
      subtitle: t('pages.turbine.deletePopupSubtitle'),
      primaryButton: {
        text: t('pages.turbine.yesDelete'),
        onClick: handleDeleteAsset,
      },
      secondaryButton: {
        text: t('buttons.cancel'),
        onClick: closePopup,
      },
    });
  }, [openPopup, t, handleDeleteAsset, closePopup]);

  const assetOperationalStatus = useMemo(
    () =>
      windTurbine?.operationalStatus ===
      WindTurbineDtoOperationalStatusEnum.ACTIVATED
        ? ControlStatuses.ACTIVE
        : ControlStatuses.INACTIVE,
    [windTurbine?.operationalStatus],
  );

  const assetOperationalStatusText = useMemo(() => {
    const text =
      assetOperationalStatus === ControlStatuses.ACTIVE
        ? t('active')
        : t('inactive');

    return text.toUpperCase();
  }, [assetOperationalStatus, t]);

  const accelerationStatus = useMemo(
    () =>
      windTurbine?.accelerationStatus ===
      WindTurbineDtoAccelerationStatusEnum.ACTIVATED
        ? ControlStatuses.ACTIVE
        : ControlStatuses.INACTIVE,
    [windTurbine?.accelerationStatus],
  );

  const accelerationStatusText = useMemo(() => {
    if (accelerationStatus === ControlStatuses.ACTIVE) {
      return t('pages.turbine.on').toUpperCase();
    }

    return t('pages.turbine.off').toUpperCase();
  }, [accelerationStatus, t]);

  const onCloseDeleteSuccess = useCallback(() => {
    closeDeleteSuccess();
    navigate(parentPath.root);
  }, [closeDeleteSuccess, navigate, parentPath.root]);

  const masterAccountNameBlock = useMemo(() => {
    if (!windTurbine?.connected) return null;

    if (isViewer) {
      return (
        <Typography variant="h5">{windTurbine.masterAccountName}</Typography>
      );
    }

    return (
      <MasterAccountLink
        to={
          routePaths.users.customerList.profile(
            String(windTurbine.masterAccountId),
          ).root
        }
      >
        <Typography variant="h5">{windTurbine.masterAccountName}</Typography>
      </MasterAccountLink>
    );
  }, [isViewer, windTurbine]);

  const isConnectMasterAccountButtonAvailable = useMemo(
    () =>
      !windTurbine?.connected &&
      windTurbine?.status === WindTurbineDtoStatusEnum.CREATED,
    [windTurbine?.connected, windTurbine?.status],
  );

  return (
    <>
      <Grid container columnSpacing={4} rowSpacing={4}>
        <Grid
          container
          item
          mobile={12}
          desktop={6}
          display="flex"
          flexDirection="column"
        >
          <Breadcrumbs
            items={[
              {
                label: t('pages.parks.title'),
                path: routePaths.parks.root,
              },
              {
                label: windTurbine?.location?.id
                  ? windTurbine.location.name
                  : t('pages.unconfiguredWindTurbines.title'),
                path: parentPath.root,
              },
              {
                label: publicId as string,
                path: parentPath.windTurbine(windTurbine?.publicId as string)
                  .root,
              },
            ]}
          />
          <Grid container sx={{ mt: { mobile: 3, desktop: 5 } }}>
            <Grid item container gap={1.5} alignItems="center">
              <WindTurbineStatus
                status={windTurbine?.status}
                errorType={windTurbine?.errorType}
                height={20}
              />
              {!!windTurbine?.location?.name && (
                <>
                  <RoundChip color="grey" colorKey="200" size={4} />
                  <Typography variant="subheading">
                    {windTurbine.location.name}
                  </Typography>
                </>
              )}
              {!!windTurbine?.ratedPower && (
                <>
                  <RoundChip color="grey" colorKey="200" size={4} />
                  <Typography variant="subheading">
                    {`${mapRatedPower(windTurbine.ratedPower)}kW`}
                  </Typography>
                </>
              )}
            </Grid>
            <Typography
              variant="h1"
              sx={{ mt: { mobile: 1.5, tablet: 1, desktop: 2 } }}
            >
              {windTurbine?.name || publicId}
            </Typography>
          </Grid>

          <Grid
            container
            sx={{ mt: { mobile: 3, desktop: 'auto' }, gap: 2.5 }}
            alignItems="center"
          >
            <Box display="flex" flexDirection="column" gap={2}>
              {isMobile && masterAccountNameBlock}
              <CopyIdBlock
                label={`MAC: ${publicId}`}
                value={publicId!}
                sx={{ width: '212px' }}
              />
            </Box>
            {!!windTurbine?.location && (
              <RolePermissionWrapper
                disallowedRoles={[
                  UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER,
                ]}
              >
                <Grid
                  gap={1}
                  sx={{ display: { mobile: 'none', desktop: 'flex' } }}
                >
                  <CommonButton
                    variant="outlined"
                    size="medium"
                    onClick={onEditAssetDetailsClick}
                    sx={{ ml: 2 }}
                  >
                    {t('pages.turbine.editAssetDetails')}
                  </CommonButton>
                </Grid>
              </RolePermissionWrapper>
            )}
            {!isMobile && (
              <Box display="flex" mr={2.5} ml="auto">
                {masterAccountNameBlock}
              </Box>
            )}
            <RolePermissionWrapper
              disallowedRoles={[UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER]}
            >
              <ActionsWrapper isWTConnected={!!windTurbine?.connected}>
                {isConnectMasterAccountButtonAvailable && (
                  <CommonButton
                    variant="contained"
                    size="large"
                    onClick={() =>
                      setConnectMAPopupProps({ assetId: publicId })
                    }
                    sx={{
                      display: { mobile: 'flex', desktop: 'none' },
                      flexGrow: { mobile: 1, tablet: 0 },
                    }}
                  >
                    {t('buttons.connectMasterAccount')}
                  </CommonButton>
                )}
                <MobileMenuActions
                  onEditAssetDetailsClick={onEditAssetDetailsClick}
                  onDisconnectMasterAccountClick={
                    onDisconnectMasterAccountClick
                  }
                  onDeleteAssetClick={onDeleteAssetClick}
                />
              </ActionsWrapper>
            </RolePermissionWrapper>
          </Grid>
        </Grid>
        <Grid container item mobile={12} desktop={6} rowSpacing={4}>
          <EditAssetButtonDesktop container item gap={2} minHeight="82px">
            <RolePermissionWrapper
              disallowedRoles={[UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER]}
            >
              {isConnectMasterAccountButtonAvailable && (
                <CommonButton
                  variant="contained"
                  size="large"
                  onClick={() => setConnectMAPopupProps({ assetId: publicId })}
                >
                  {t('buttons.connectMasterAccount')}
                </CommonButton>
              )}
              {(!!windTurbine?.connected || showDeleteButton) && (
                <DesktopMenuActions
                  items={[
                    ...(windTurbine?.connected
                      ? [
                          {
                            label: t('buttons.disconnectMasterAccount'),
                            onClick: onDisconnectMasterAccountClick,
                          },
                        ]
                      : []),
                    ...(showDeleteButton
                      ? [
                          {
                            label: t('pages.turbines.deleteAsset'),
                            onClick: onDeleteAssetClick,
                            color: 'error.main',
                          },
                        ]
                      : []),
                  ]}
                />
              )}
            </RolePermissionWrapper>
          </EditAssetButtonDesktop>
          <Grid
            container
            item
            columnSpacing={{ tablet: 2.5, desktop: 2 }}
            rowSpacing={{ mobile: 2.5 }}
          >
            <Grid item mobile={12} tablet={6}>
              {windTurbine?.operationalStatus ? (
                <WindTurbineControl
                  name={t('pages.turbine.turbineActivity')}
                  value={
                    windTurbine.operationalStatus ===
                    WindTurbineDtoOperationalStatusEnum.ACTIVATED
                  }
                  onClick={handleTurbineActivityChange}
                  errorStatus={
                    windTurbine.status === WindTurbineDtoStatusEnum.ERROR
                  }
                  dataTestId="wind-turbine-activity-switch"
                  disabled={isViewer}
                  withAnimation
                >
                  <WindTurbineControlStatus
                    status={assetOperationalStatus}
                    text={assetOperationalStatusText}
                    dataTestId="wind-turbine-activity-status"
                  />
                </WindTurbineControl>
              ) : (
                <ContainerStyled />
              )}
            </Grid>
            <Grid item mobile={12} tablet={6}>
              {windTurbine?.accelerationStatus ? (
                <WindTurbineControl
                  name={t('pages.turbine.accelerationMode')}
                  value={
                    windTurbine?.accelerationStatus ===
                    WindTurbineDtoAccelerationStatusEnum.ACTIVATED
                  }
                  onClick={handleTurbineAccelerationChange}
                  disabled={isViewer}
                >
                  <WindTurbineControlStatus
                    status={accelerationStatus}
                    text={accelerationStatusText}
                  />
                </WindTurbineControl>
              ) : (
                <ContainerStyled />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <EditAssetDetailsPopup
        isOpen={isEditAssetDetailsPopupOpen}
        onClose={closeEditAssetDetailsPopup}
      />
      <SuccessDialog
        title={t('pages.turbine.assetWasDeleted')}
        buttonText={t('continueSession')}
        onClose={onCloseDeleteSuccess}
        open={isDeleteSuccessOpen}
      />
      {(connectMAPopupProps.assetId || connectMAPopupProps.locationName) && (
        <ConnectMasterAccount
          onClose={() => setConnectMAPopupProps({})}
          onSuccess={() => {
            setConnectMAPopupProps({});
            refetch();
          }}
          {...connectMAPopupProps}
        />
      )}
    </>
  );
};
