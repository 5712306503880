import {
  Autocomplete,
  AutocompleteCloseReason,
  AutocompleteRenderGroupParams,
  Checkbox,
  PaperProps,
  Typography,
} from '@mui/material';
import {
  FC,
  HTMLAttributes,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ParametersSelectPaper } from './components';
import {
  CloseIconWrapper,
  GroupHeader,
  GroupItems,
  MenuItemStyled,
  MultipleSelectChip,
  SelectWrapper,
  TextFieldStyled,
} from './styles';
import { ParameterOption, ParametersSelectProps } from './types';
import { Checked, ChevronDownL, Close } from 'components/icons';

export const ParametersSelect: FC<ParametersSelectProps> = ({
  label,
  value: selectedOptions = [],
  options = [],
  fullWidth,
  onClose,
  disabled,
  buttonsProps,
  selectionLimit,
  customTemplate,
  sx,
  className,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const [innerValues, setInnerValues] = useState(selectedOptions);
  const [showSelectionLimitError, setShowSelectionLimitError] = useState(false);

  useEffect(() => {
    setInnerValues(selectedOptions);
  }, [selectedOptions]);

  const isOptionSelected = useCallback(
    (optionValue?: string) =>
      !!innerValues.find((option) => option.value === optionValue),
    [innerValues],
  );

  const handleClose = useCallback(
    (event: SyntheticEvent, reason: AutocompleteCloseReason) => {
      onClose?.(event, reason);
      setInnerValues(selectedOptions);
      setShowSelectionLimitError(false);
    },
    [onClose, selectedOptions],
  );

  const handleOptionClick = useCallback(
    (clickedOption: ParameterOption, isSelected: boolean) => {
      if (
        !isSelected &&
        typeof selectionLimit !== 'undefined' &&
        innerValues.length >= selectionLimit
      ) {
        setShowSelectionLimitError(true);
        return innerValues;
      }

      setShowSelectionLimitError(false);

      let newValues = [];
      if (isSelected) {
        newValues = innerValues.filter(
          (parameter) => parameter.value !== clickedOption.value,
        );
      } else newValues = [...innerValues, clickedOption];

      setInnerValues(newValues);

      return newValues;
    },
    [innerValues, selectionLimit],
  );

  const renderTags = useCallback(
    (tags: ParameterOption[]) => {
      return (
        <>
          {tags.map((selectedOption) => (
            <MultipleSelectChip
              label={
                <>
                  {selectedOption.label}
                  <CloseIconWrapper
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      const newValues = tags.filter(
                        (tag) => tag.value !== selectedOption.value,
                      );
                      setInnerValues(newValues);
                      buttonsProps.onApply(newValues);
                    }}
                  >
                    <Close />
                  </CloseIconWrapper>
                </>
              }
              key={selectedOption.value}
              variant="outlined"
            />
          ))}
        </>
      );
    },
    [buttonsProps],
  );

  const renderGroup = useCallback((params: AutocompleteRenderGroupParams) => {
    return (
      <li key={params.key}>
        <GroupHeader>
          <Typography variant="bodyMStrong" textTransform="uppercase">
            {params.group}
          </Typography>
        </GroupHeader>
        <GroupItems>{params.children}</GroupItems>
      </li>
    );
  }, []);

  const renderOption = useCallback(
    (_: HTMLAttributes<HTMLLIElement>, option: ParameterOption) => {
      const isSelected = isOptionSelected(option.value);

      return (
        <MenuItemStyled
          key={option.value}
          value={option.value}
          selected={isSelected}
          onClick={() => handleOptionClick(option, isSelected)}
        >
          <Checkbox
            checked={isSelected}
            size="medium"
            disableRipple
            checkedIcon={<Checked />}
          />
          {option.label}
        </MenuItemStyled>
      );
    },
    [handleOptionClick, isOptionSelected],
  );

  const paperButtonProps = useMemo(
    () => ({
      apply: {
        onApply: (e: SyntheticEvent) => {
          buttonsProps.onApply(innerValues);
          setShowSelectionLimitError(false);
          onClose?.(e, 'blur');
        },
        disabled: !innerValues.length,
      },
      clear: {
        onClear: () => {
          setInnerValues([]);
          buttonsProps.onClearAll();
          setShowSelectionLimitError(false);
        },
      },
    }),
    [buttonsProps, innerValues, onClose],
  );

  const customTemplateProps = useMemo(
    () =>
      customTemplate && {
        ...customTemplate,
        onSelect: (e: SyntheticEvent) => {
          customTemplate.onSelect();
          setShowSelectionLimitError(false);
          onClose?.(e, 'blur');
        },
      },
    [customTemplate, onClose],
  );

  return (
    <SelectWrapper
      disabled={disabled}
      sx={sx}
      className={className}
      fullWidth={fullWidth}
    >
      <Autocomplete
        options={options}
        value={selectedOptions}
        groupBy={(option) => option.group}
        renderGroup={renderGroup}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextFieldStyled
            label={label}
            {...params}
            placeholder={
              otherProps.open
                ? t('pages.comparisonChart.form.searchParameter')
                : undefined
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {typeof selectionLimit !== 'undefined' && (
                    <Typography
                      variant="bodyS"
                      color="grey.600"
                      whiteSpace="nowrap"
                      sx={{
                        position: 'absolute',
                        top: 'calc(50% - 10.5px)',
                        right: '43px',
                      }}
                    >
                      {t('components.select.maxN', {
                        n: selectionLimit,
                      })}
                    </Typography>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
              style:
                typeof selectionLimit !== 'undefined'
                  ? {
                      paddingRight: '95px',
                    }
                  : undefined,
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderTags={renderTags}
        renderOption={renderOption}
        onClose={handleClose}
        disableClearable
        multiple
        popupIcon={<ChevronDownL />}
        // providing custom paper props is available in mui 6.1.0 (see https://github.com/mui/material-ui/issues/38049)
        // @ts-ignore-next-line
        PaperComponent={ParametersSelectPaper}
        slotProps={{
          paper: {
            options: options,
            buttonsProps: paperButtonProps,
            selectionLimit: selectionLimit,
            showSelectionLimitError: showSelectionLimitError,
            customTemplate: customTemplateProps,
            // providing custom paper props is available in mui 6.1.0 (see https://github.com/mui/material-ui/issues/38049)
          } as PaperProps,
          popper: {
            placement: 'bottom',
            modifiers: [{ name: 'flip', enabled: false }],
          },
        }}
        {...otherProps}
      />
    </SelectWrapper>
  );
};
