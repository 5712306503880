import { FilterTypes, FilterValues, TranslationFunction } from 'types/general';

export const notificationsFilterValues = (
  t: TranslationFunction,
): FilterValues => ({
  status: {
    type: FilterTypes.radio,
    label: t('pages.notifications.filterStatus'),
    values: [
      {
        label: t('pages.notifications.filterAll'),
        value: 'all',
      },
      {
        label: t('pages.notifications.filterRead'),
        value: 'read',
      },
      {
        label: t('pages.notifications.filterUnread'),
        value: 'unread',
      },
    ],
  },
  dateRange: {
    label: t('components.filterMenu.filterTimePeriod'),
    type: FilterTypes.dates,
    values: [],
  },
});

export const fieldsToSend = [
  'search',
  'dateRange',
  'status',
  'type',
  'size',
  'page',
];
