import {
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EditParkDetailsPopup,
  WarningCoordinatesUnset,
  WeatherDaily,
  WeatherHourly,
} from './components';
import {
  MoreActionsButtonStyled,
  ParkComponentHead,
  TabsSectionStyled,
  WeatherWrapper,
  Wrapper,
} from './styles';
import { EnergyCounter } from '../Parks/components/EnergyCounter';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import CommonButton from 'components/common/CommonButton';
import { MoreActionsMenu } from 'components/common/MoreActionsMenu';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import { ITab } from 'components/common/TabsSection/TabsSection';
import { RolePermissionWrapper } from 'components/shared';
import { CopyIdBlock } from 'components/shared/CopyIdBlock';
import { DesktopMenuActions } from 'components/shared/DesktopMenuActions';
import { WarningTextBlock } from 'components/shared/WarningTextBlock';
import { WindTurbinesTable } from 'components/shared/WindTurbinesTable';
import routePaths from 'constants/routePaths';
import { useParkContext } from 'contexts/park';
import useResponsePopup from 'contexts/responsePopup';
import { UserDetailsDtoUserRoleEnum } from 'openapi-api/admin-service';
import { convertWh } from 'utils/functions/converters';
import { getShortUUID } from 'utils/functions/getShortUUID';

export const ParkComponent: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { details, weather, refetch } = useParkContext();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [isEditParkOpened, setEditParkOpened] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const { openPopup, closePopup } = useResponsePopup();

  const isParkNameUnset = useMemo(
    () => details?.locationName === details?.orangePiId,
    [details],
  );

  const weatherTabs = useMemo(
    (): ITab[] => [
      {
        label: (
          <Typography variant="bodyMStrong">
            {t('pages.parkDetails.weather.todayWeatherTitle')}
          </Typography>
        ),
        value: <WeatherHourly />,
        key: 'today',
      },
      {
        label: (
          <Typography variant="bodyMStrong">
            {t('pages.parkDetails.weather.dailyWeatherTitle')}
          </Typography>
        ),
        value: <WeatherDaily />,
        key: 'daily',
      },
    ],
    [t],
  );

  const activeEnergy = useMemo(
    () => convertWh(details?.totalActiveEnergy || 0, t),
    [details, t],
  );

  const lostEnergy = useMemo(
    () => convertWh(details?.totalLostEnergy || 0, t),
    [details, t],
  );

  const weatherBlock = useMemo(() => {
    if (
      typeof details?.latitude === 'undefined' ||
      typeof details.longitude === 'undefined'
    ) {
      return (
        <RolePermissionWrapper
          disallowedRoles={[UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER]}
        >
          <WarningCoordinatesUnset
            onButtonClick={() => setEditParkOpened(true)}
            dataTestId="park-coordinates-unset-notice"
          />
        </RolePermissionWrapper>
      );
    }

    if (!weather) return null;

    if (isMobile) {
      return <TabsSectionStyled tabs={weatherTabs} />;
    }

    return (
      <WeatherWrapper>
        <WeatherHourly />
        <WeatherDaily />
      </WeatherWrapper>
    );
  }, [details, weather, weatherTabs, isMobile]);

  const onConnectMasterAccountClick = useCallback(() => {
    // TODO: add master account connection after FE FSA-1589
  }, []);

  const handleDisconnectMasterAccount = useCallback(() => {
    // TODO: add master account connection after BE
  }, []);

  const onDisconnectMasterAccountClick = useCallback(() => {
    openPopup({
      variant: NotificationPopupVariants.Error,
      title: t('pages.parkDetails.disconnectMasterAccount.title'),
      subtitle: t('pages.parkDetails.disconnectMasterAccount.subtitle'),
      primaryButton: {
        text: t('pages.parkDetails.disconnectMasterAccount.submitButtonText'),
        onClick: handleDisconnectMasterAccount,
      },
      secondaryButton: {
        text: t('buttons.cancel'),
        onClick: closePopup,
      },
    });
  }, [t, openPopup, closePopup, handleDisconnectMasterAccount]);

  if (typeof details === 'undefined') {
    return null;
  }

  return (
    <Wrapper data-testid="park-details-page-wrapper">
      <Box
        display="flex"
        flexDirection="column"
        gap={{ mobile: 3, desktop: 4 }}
      >
        <ParkComponentHead>
          <Box display="flex" flexDirection="column">
            <Breadcrumbs
              items={[
                {
                  label: t('pages.parks.title'),
                  path: routePaths.parks.root,
                },
                {
                  label: isParkNameUnset
                    ? getShortUUID(details.locationName || 'undefined')
                    : details.locationName || '',
                  path: '',
                },
              ]}
            />
            <Box
              display="flex"
              flexDirection="column"
              gap={1.5}
              marginTop={{ mobile: 3, desktop: 5 }}
            >
              <Typography variant="subheading">
                {t('pages.parkDetails.windTurbinesCounter', {
                  count: details.turbineCount || 0,
                })}
              </Typography>
              <Typography variant="h1">
                {isParkNameUnset
                  ? getShortUUID(details.orangePiId || 'undefined')
                  : details.locationName}
              </Typography>
              {isParkNameUnset && (
                <WarningTextBlock>
                  {t('warningDefaultParkName')}
                </WarningTextBlock>
              )}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginTop={{ mobile: 3, desktop: 2.5 }}
            >
              <Box
                display="flex"
                flexDirection={{ mobile: 'column-reverse', desktop: 'row' }}
                alignItems={{ mobile: 'flex-start', desktop: 'center' }}
                justifyContent="space-between"
                gap={2}
                width="100%"
              >
                <Box display="flex" alignItems="center" gap={1.5}>
                  {details.orangePiId && (
                    <Tooltip title={details.orangePiId}>
                      <CopyIdBlock
                        label={`ID: ${getShortUUID(details.orangePiId)}`}
                        value={details.orangePiId}
                        sx={{ width: '190px' }}
                      />
                    </Tooltip>
                  )}
                  <RolePermissionWrapper
                    disallowedRoles={[
                      UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER,
                    ]}
                  >
                    <CommonButton
                      variant="outlined"
                      size="small"
                      sx={{
                        display: {
                          mobile: 'none',
                          desktop: 'inline-flex',
                        },
                        py: 0.75,
                      }}
                      onClick={() => setEditParkOpened(true)}
                    >
                      {t('pages.parkDetails.editParkDetails.title')}
                    </CommonButton>
                  </RolePermissionWrapper>
                </Box>
                <Typography variant="h5">{details.customerName}</Typography>
              </Box>
              <RolePermissionWrapper
                disallowedRoles={[
                  UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER,
                ]}
              >
                <MoreActionsButtonStyled
                  onClick={() => setMobileMenuOpen(true)}
                  data-testid="park-actions-menu-button"
                />
              </RolePermissionWrapper>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={2.5}>
            <RolePermissionWrapper
              disallowedRoles={[UserDetailsDtoUserRoleEnum.ADMIN_PORTAL_VIEWER]}
            >
              <Box
                display={{ mobile: 'none', desktop: 'flex' }}
                justifyContent="flex-end"
              >
                {details.connected ? (
                  <DesktopMenuActions
                    items={[
                      {
                        label: t('buttons.disconnectMasterAccount'),
                        onClick: onDisconnectMasterAccountClick,
                      },
                    ]}
                  />
                ) : (
                  <CommonButton onClick={onConnectMasterAccountClick}>
                    {t('buttons.connectMasterAccount')}
                  </CommonButton>
                )}
              </Box>
            </RolePermissionWrapper>
            <Box
              display="grid"
              gridTemplateColumns={{ mobile: '1fr', tablet: '1fr 1fr' }}
              gap={{ mobile: 1.5, desktop: 1 }}
            >
              <EnergyCounter
                variant="parkPage"
                counterType="activeEnergy"
                label={t('pages.parkDetails.activeEnergyCounter')}
                value={`${activeEnergy.value} ${activeEnergy.label}`}
                tooltipTitle={t('pages.parkDetails.activeEnergyTooltip')}
              />
              <EnergyCounter
                variant="parkPage"
                counterType="lostEnergy"
                label={t('pages.parkDetails.lostEnergyCounter')}
                value={`${lostEnergy.value} ${lostEnergy.label}`}
                tooltipTitle={t('pages.parkDetails.lostEnergyTooltip')}
              />
            </Box>
          </Box>
        </ParkComponentHead>
        {weatherBlock}
        {details.locationId !== undefined && (
          <WindTurbinesTable locationId={details.locationId} />
        )}
      </Box>
      {!!isMobileMenuOpen && (
        <MoreActionsMenu
          title={details.locationName}
          items={[
            {
              label: t('pages.parkDetails.editParkDetails.title'),
              onClick: () => setEditParkOpened(true),
              dataTestId: 'park-actions-menu-editPark-item',
            },
            ...(details.connected
              ? [
                  {
                    label: t('buttons.disconnectMasterAccount'),
                    onClick: onDisconnectMasterAccountClick,
                  },
                ]
              : [
                  {
                    label: t('buttons.connectMasterAccount'),
                    onClick: onConnectMasterAccountClick,
                  },
                ]),
          ]}
          onClose={() => setMobileMenuOpen(false)}
          dataTestId="park-actions-menu"
        />
      )}
      {isEditParkOpened && (
        <EditParkDetailsPopup
          isOpen
          onClose={() => setEditParkOpened(false)}
          locationId={details.locationId!}
          onSuccess={refetch}
          dataTestId="edit-park-details"
        />
      )}
    </Wrapper>
  );
};
