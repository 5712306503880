import { styled } from '@mui/material/styles';

export const BottomPartContainer = styled('div')(({ theme }) => ({
  flexGrow: 1,
  minHeight: '460px',

  backgroundColor: theme.palette.grey[50],
  borderRadius: '25px 25px 0 0',
  padding: theme.spacing(7.5),
  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(3, 3, 5, 3),
  },
}));
