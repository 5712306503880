import { alpha, styled } from '@mui/material';

export const WarningWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 3.5),
  backgroundColor: alpha(theme.palette.warning.main, 0.3),

  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'unset',
    gap: theme.spacing(1.5),
    padding: theme.spacing(2, 3, 2.5),
  },
}));
