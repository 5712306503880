import { useMediaQuery, useTheme } from '@mui/material';
import { FC, useMemo, useState } from 'react';

import { ButtonStyled, ThreeDotsIcon } from './styles';
import { DropdownMenu } from '../DropdownMenu';
import { MoreActionsMenu } from '../MoreActionsMenu';
import { IMoreActionsMenuItem } from '../MoreActionsMenu/types';

interface Props {
  title?: string;
  items?: IMoreActionsMenuItem[];
}

export const MoreActions: FC<Props> = ({ title, items = [] }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  const buttonComponent = useMemo(
    () => (
      <ButtonStyled
        variant="outlined"
        endIcon={<ThreeDotsIcon />}
        onClick={isDesktop ? undefined : () => setMobileMenuOpen(true)}
        data-testid="more-actions-button"
      />
    ),
    [isDesktop],
  );

  if (isDesktop) {
    return (
      <DropdownMenu
        title={buttonComponent}
        withExpandIcon={false}
        items={items}
      />
    );
  }

  return (
    <>
      {buttonComponent}
      {isMobileMenuOpen && (
        <MoreActionsMenu
          title={title}
          onClose={() => setMobileMenuOpen(false)}
          items={items}
        />
      )}
    </>
  );
};
