import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ChartCard } from './components';
import {
  categoryToGroupMap,
  getCustomTemplateOptions,
  getParametersCategoriesOptions,
  panelsMapping,
  ParameterCategory,
} from './constants';
import { groupWidgets } from './helpers';
import {
  ComparisonChartLinkWrapper,
  FormWrapper,
  GridTable,
  GridTableWrapper,
  HeadWrapper,
  LinkIcon,
  PageWrapper,
} from './styles';
import { investigationDateTimeFormat } from '../Investigation/components/History/constants';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import CommonButton from 'components/common/CommonButton';
import {
  ParameterOption,
  ParametersSelect,
} from 'components/shared/ParametersSelect';
import routePaths from 'constants/routePaths';
import useApi from 'contexts/api';
import { WindTurbinePanelsProvider } from 'contexts/WindTurbinePanels';
import getDateTimeInUnixMs from 'utils/functions/getDateTimeInUnixMs';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

const InvestigationData = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { requestId } = useParams();
  const { restorationRequestControllerApi } = useApi();

  const [page, setPage] = useState(0);

  const parametersOptions = getParametersCategoriesOptions(t);
  const [parameters, setParameters] = useState<ParameterOption[]>([]);
  const [isParametersOpen, setIsParametersOpen] = useState(false);
  const handleOpenParameters = useCallback(() => {
    setIsParametersOpen(true);
  }, []);
  const handleCloseParameters = useCallback(() => {
    setIsParametersOpen(false);
  }, []);
  const selectParametersButtonsProps = useMemo(
    () => ({
      onApply: (params: ParameterOption[]) => {
        setParameters(params);
      },
      onClearAll: () => setParameters([]),
    }),
    [],
  );
  const customTemplateProps = useMemo(
    () => ({
      title: t('pages.investigationData.parametersSelect.customTemplate.title'),
      subtitle: (
        <Trans
          i18nKey="pages.investigationData.parametersSelect.customTemplate.subtitle"
          components={[
            <Typography
              key={`customTemplate-buttonText`}
              variant="bodySStrong"
            />,
          ]}
        />
      ),
      buttonText: t(
        'pages.investigationData.parametersSelect.customTemplate.buttonText',
      ),
      onSelect: () => setParameters(getCustomTemplateOptions(t)),
    }),
    [t],
  );

  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const isTablet = useMediaQuery(theme.breakpoints.only('tablet'));

  const getInvestigationData = useCallback(async () => {
    if (typeof requestId === 'undefined') {
      return;
    }
    try {
      const result = (
        await restorationRequestControllerApi.getRestorationRequestById({
          id: Number(requestId),
        })
      ).data;
      return result;
    } catch {}
  }, [requestId, restorationRequestControllerApi]);

  const { resource: investigationData } = useAsyncResource({
    fetchResource: getInvestigationData,
  });

  const pageSize = useMemo(() => {
    if (isDesktop) return 20;
    if (isTablet) return 10;
    return 5;
  }, [isDesktop, isTablet]);

  const currentTotal = useMemo(() => (page + 1) * pageSize, [page, pageSize]);

  const filteredWidgets = useMemo(() => {
    return Object.keys(panelsMapping)
      .filter((category) => parameters.find(({ value }) => value === category))
      .map((category) =>
        panelsMapping[category].map((panel) => ({
          ...panel,
          group: categoryToGroupMap[category as ParameterCategory],
        })),
      )
      .flat();
  }, [parameters]);

  const currentWidgets = useMemo(() => {
    return filteredWidgets?.slice(0, currentTotal);
  }, [currentTotal, filteredWidgets]);

  const currentWidgetsGrouped = groupWidgets(currentWidgets);

  const hasMore = useMemo(
    () => filteredWidgets.length > currentTotal,
    [currentTotal, filteredWidgets.length],
  );

  return (
    <WindTurbinePanelsProvider
      dashboardType="ADMIN_INVESTIGATION"
      publicId={investigationData?.assetPublicId}
      restorationId={investigationData?.restorationId}
    >
      <PageWrapper>
        <HeadWrapper>
          <Box
            display="flex"
            flexDirection={isDesktop ? 'row' : 'column'}
            justifyContent={isDesktop ? 'space-between' : 'unset'}
            gap={2}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={1.5}
              flexBasis="70%"
            >
              <Breadcrumbs
                items={[
                  {
                    label: t('pages.investigation.title'),
                    path: routePaths.investigation.root,
                  },
                  {
                    label: `${investigationData?.turbineName || ''}`,
                    path: routePaths.investigation.specific(
                      investigationData?.id || '',
                    ),
                  },
                ]}
              />
              <Typography variant="h2">
                {investigationData?.turbineName}
              </Typography>
            </Box>
            <Box>
              <Grid container spacing={0.5}>
                <Grid container item spacing={1.5}>
                  <Grid item width="160px">
                    <Typography variant="bodyS" color="grey.900">
                      {`${t('masterAccountName')}:`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="bodySStrong" color="grey.900">
                      {investigationData?.customerName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item spacing={1.5}>
                  <Grid item width="160px">
                    <Typography variant="bodyS" color="grey.900">
                      {`${t('parkName')}:`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="bodySStrong" color="grey.900">
                      {investigationData?.parkName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item spacing={1.5} flexWrap="nowrap">
                  <Grid item width="160px" flexShrink={0}>
                    <Typography variant="bodyS" color="grey.900">
                      {`${t('timeFrame')}:`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="bodySStrong" color="grey.900">
                      {`${dayjs(investigationData?.startDatetime).format(
                        investigationDateTimeFormat,
                      )} - ${dayjs(investigationData?.endDatetime).format(
                        investigationDateTimeFormat,
                      )}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <FormWrapper>
            <Box p={{ mobile: 3, desktop: 4 }}>
              <ParametersSelect
                name="parameters"
                label={t('pages.comparisonChart.form.parameters')}
                options={parametersOptions}
                value={parameters}
                open={isParametersOpen}
                onOpen={handleOpenParameters}
                onClose={handleCloseParameters}
                buttonsProps={selectParametersButtonsProps}
                customTemplate={customTemplateProps}
                fullWidth
              />
            </Box>
            <ComparisonChartLinkWrapper>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="h6">
                  {t('pages.comparisonChart.form.title')}
                </Typography>
                <Typography variant="bodyM">
                  {t('pages.comparisonChart.subtitle')}
                </Typography>
              </Box>
              <Box
                component="a"
                href={routePaths.comparisonChart.prefilled(
                  investigationData?.customerName as string,
                  investigationData?.parkName as string,
                  investigationData?.turbineName as string,
                  investigationData?.startDatetime as string,
                  investigationData?.endDatetime as string,
                )}
                target="_blank"
                sx={{ width: { mobile: '100%', tablet: '270px' } }}
              >
                <CommonButton color="black" endIcon={<LinkIcon />} fullWidth>
                  {t('pages.comparisonChart.title')}
                </CommonButton>
              </Box>
            </ComparisonChartLinkWrapper>
          </FormWrapper>
        </HeadWrapper>
        <GridTableWrapper>
          {!currentWidgets.length ? (
            <Box flexGrow={1} alignSelf="center" alignContent="center">
              <Typography variant="h3" color="grey.600" textAlign="center">
                {t(
                  'pages.investigationData.specifyInvestigationParameterAndCategories',
                )}
              </Typography>
            </Box>
          ) : (
            <>
              {currentWidgetsGrouped.map(({ group, widgets }) => (
                <Box display="flex" flexDirection="column" gap={1.5}>
                  <Typography variant="subheading" color="black.900">
                    {group}
                  </Typography>
                  <GridTable>
                    {widgets.map(({ panelId, tKey }) => (
                      <ChartCard
                        title={t(tKey)}
                        panelId={panelId}
                        fromDate={getDateTimeInUnixMs(
                          investigationData?.startDatetime as string,
                        )}
                        toDate={getDateTimeInUnixMs(
                          investigationData?.endDatetime as string,
                        )}
                        key={panelId}
                      />
                    ))}
                  </GridTable>
                </Box>
              ))}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flexGrow: 1,
                }}
              >
                {hasMore && (
                  <CommonButton
                    variant="outlined"
                    color="secondary"
                    sx={{ backgroundColor: 'transparent' }}
                    onClick={() => setPage(page + 1)}
                  >
                    {t('pages.viewTags.loadMore')}
                  </CommonButton>
                )}
              </Box>
            </>
          )}
        </GridTableWrapper>
      </PageWrapper>
    </WindTurbinePanelsProvider>
  );
};

export default InvestigationData;
