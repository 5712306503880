import { Typography, styled } from '@mui/material';

export const AccessDeniedTitle = styled(Typography)(({ theme }) => ({
  fontSize: '54px',
  lineHeight: '110%',
  textAlign: 'center',
  color: theme.palette.black[900],

  [theme.breakpoints.down('desktop')]: {
    fontSize: '40px',
  },
}));
