import { Box, Grid } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { WIDGETS } from '../../constants';
import { MechanicalParameter } from '../MechanicalParameter';
import { WindTurbineChart } from '../WindTurbineChart';
import { WindTurbineParameters } from '../WindTurbineParameters';
import { WindTurbinePanelsProvider } from 'contexts/WindTurbinePanels';

export const WindTurbineGeneralInfoContent: FC = () => {
  const { t } = useTranslation();

  return (
    <WindTurbinePanelsProvider dashboardType="GENERAL">
      <Box display="flex" flexDirection="column" gap={{ mobile: 4, tablet: 5 }}>
        <Grid
          container
          columnSpacing={{ tablet: 1, desktop: 3 }}
          rowSpacing={{ mobile: 1.5 }}
        >
          <Grid item mobile={12} tablet={4}>
            <MechanicalParameter
              title={t('pages.turbine.mechanicalParameter')}
              name={t('pages.turbine.windSpeed')}
              widget={WIDGETS.WIND_SPEED}
            />
          </Grid>
          <Grid item mobile={12} tablet={4}>
            <MechanicalParameter
              title={t('pages.turbine.mechanicalParameter')}
              name={t('pages.turbine.generatorSpeed')}
              widget={WIDGETS.GENERATOR_SPEED}
            />
          </Grid>
          <Grid item mobile={12} tablet={4}>
            <MechanicalParameter
              title={t('pages.turbine.networkParameter')}
              name={t('pages.turbine.activePower')}
              widget={WIDGETS.ACTIVE_POWER}
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={{ tablet: 2.5, desktop: 4 }}
          rowSpacing={{ mobile: 2.5 }}
        >
          <Grid item mobile={12} tablet={6}>
            <WindTurbineChart
              title={t('pages.turbine.windSpeed')}
              tooltipText={t('pages.turbine.windSpeedTooltip')}
              panelId={WIDGETS.WIND_SPEED_CHART}
            />
          </Grid>
          <Grid item mobile={12} tablet={6}>
            <WindTurbineChart
              title={t('pages.turbine.generatorSpeed')}
              tooltipText={t('pages.turbine.generatorSpeedTooltip')}
              panelId={WIDGETS.GENERATOR_SPEED_CHART}
            />
          </Grid>
          <Grid item mobile={12} tablet={6}>
            <WindTurbineChart
              title={t('pages.turbine.totalLostEnergy')}
              tooltipText={t('pages.turbine.totalLostEnergyTooltip')}
              panelId={WIDGETS.TOTAL_LOST_ENERGY_CHART}
              subheaderPanelId={WIDGETS.TOTAL_LOST_ENERGY_COUNTER}
            />
          </Grid>
          <Grid item mobile={12} tablet={6}>
            <WindTurbineChart
              title={t('pages.turbine.activePower')}
              tooltipText={t('pages.turbine.activePowerTooltip')}
              panelId={WIDGETS.ACTIVE_POWER_CHART}
              subheaderPanelId={WIDGETS.ACTIVE_POWER_COUNTER}
            />
          </Grid>
        </Grid>
        <Box display="grid" gridTemplateColumns="1fr" gap={7}>
          <Box
            display="grid"
            gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
            gap={5}
          >
            <WindTurbineParameters
              name={t('pages.turbine.networkParameters.title')}
              widgetsInfoItems={Object.values(
                t('pages.turbine.networkParameters.data', {
                  returnObjects: true,
                }),
              )}
              widgets={[
                WIDGETS.NETWORK_PARAMETERS_VERTICAL_1,
                WIDGETS.NETWORK_PARAMETERS_VERTICAL_2,
                WIDGETS.NETWORK_PARAMETERS_VERTICAL_3,
              ]}
              widgetsMobile={[
                WIDGETS.NETWORK_PARAMETERS_VERTICAL_1_MOBILE,
                WIDGETS.NETWORK_PARAMETERS_VERTICAL_2_MOBILE,
                WIDGETS.NETWORK_PARAMETERS_VERTICAL_3_MOBILE,
              ]}
            />
            <WindTurbineParameters
              name={t('pages.turbine.frequencyConverterParams.title')}
              widgetsInfoItems={t(
                'pages.turbine.frequencyConverterParams.data',
                {
                  returnObjects: true,
                },
              )}
              widgets={[
                WIDGETS.ELECTRICAL_PARAMETERS_VERTICAL_1,
                WIDGETS.ELECTRICAL_PARAMETERS_VERTICAL_2,
                WIDGETS.ELECTRICAL_PARAMETERS_VERTICAL_3,
              ]}
              widgetsMobile={[
                WIDGETS.ELECTRICAL_PARAMETERS_VERTICAL_1_MOBILE,
                WIDGETS.ELECTRICAL_PARAMETERS_VERTICAL_2_MOBILE,
                WIDGETS.ELECTRICAL_PARAMETERS_VERTICAL_3_MOBILE,
              ]}
            />
          </Box>
          <Box>
            <WindTurbineParameters
              name={t('pages.turbine.summaryParameters.title')}
              widgetsInfoItems={t('pages.turbine.summaryParameters.data', {
                returnObjects: true,
              })}
              widgets={[
                WIDGETS.SUMMARY_PARAMETERS_VERTICAL_1,
                WIDGETS.SUMMARY_PARAMETERS_VERTICAL_2,
              ]}
              widgetsMobile={[
                WIDGETS.SUMMARY_PARAMETERS_VERTICAL_1_MOBILE,
                WIDGETS.SUMMARY_PARAMETERS_VERTICAL_2_MOBILE,
              ]}
              sx={{
                gridTemplateColumns: { mobile: '1fr', desktop: '1fr 1fr' },
              }}
            />
          </Box>
          <Box>
            <WindTurbineParameters
              name={t('pages.turbine.temperatureParameters.title')}
              widgetsInfoItems={t('pages.turbine.temperatureParameters.data', {
                returnObjects: true,
              })}
              widgets={[
                WIDGETS.TEMPERATURE_PARAMETERS_1,
                WIDGETS.TEMPERATURE_PARAMETERS_2,
              ]}
              widgetsMobile={[
                WIDGETS.TEMPERATURE_PARAMETERS_1_MOBILE,
                WIDGETS.TEMPERATURE_PARAMETERS_2_MOBILE,
              ]}
              sx={{
                gridTemplateColumns: { mobile: '1fr', desktop: '1fr 1fr' },
              }}
            />
          </Box>
        </Box>
      </Box>
    </WindTurbinePanelsProvider>
  );
};
