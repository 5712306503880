import { styled } from '@mui/material';

export const PerformanceDiagramsWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: theme.spacing(4),
  rowGap: theme.spacing(5),

  [theme.breakpoints.down('desktop')]: {
    gridTemplateColumns: '1fr',
  },
}));
