import routePaths from 'constants/routePaths';

type GetWindTurbinePageLinkPropsType = {
  assetLocationId?: string | number;
  assetPublicId: string | number;
};

export const getWindTurbinePageLink = ({
  assetLocationId,
  assetPublicId,
}: GetWindTurbinePageLinkPropsType) => {
  if (assetLocationId !== undefined) {
    return routePaths.parks
      .specific(assetLocationId)
      .windTurbine(assetPublicId);
  }

  return routePaths.parks.unconfigured().windTurbine(assetPublicId);
};
