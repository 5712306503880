import { styled, TextField } from '@mui/material';

export const TextFieldStyled = styled(TextField)(({ theme }) => ({
  textAlign: 'left',
  '& .MuiInputBase-root, .MuiOutlinedInput-root': {
    padding: theme.spacing(2.75, 2, 1),
    gap: theme.spacing(0.5),
    '& .MuiInputBase-input, .MuiOutlinedInput-input': {
      minWidth: '150px',
      padding: 0,
      '&::placeholder': {
        ...theme.typography.bodyM,
        color: theme.palette.grey[400],
      },
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-root': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
  },
}));
