import { Box, Paper, PaperProps, Typography } from '@mui/material';
import { FC, ReactNode, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ButtonsWrapper,
  CustomTemplateContentWrapper,
  TopBlockWrapper,
} from './styles';
import { ParameterOption } from '../../types';
import CommonButton from 'components/common/CommonButton';
import {
  InformerBlock,
  InformerBlockVariants,
} from 'components/common/InformerBlock';

interface IParametersSelectPaperProps extends Partial<PaperProps> {
  options: readonly ParameterOption[];
  buttonsProps: {
    apply: {
      onApply: (event: SyntheticEvent) => void;
      disabled?: boolean;
    };
    clear: {
      onClear: () => void;
    };
  };
  selectionLimit?: number;
  showSelectionLimitError: boolean;
  customTemplate?: {
    title: string;
    subtitle: ReactNode;
    buttonText: string;
    onSelect: (event: SyntheticEvent) => void;
  };
  children?: ReactNode;
}

export const ParametersSelectPaper: FC<IParametersSelectPaperProps> = ({
  options,
  buttonsProps,
  selectionLimit,
  showSelectionLimitError,
  customTemplate,
  children,
  ...otherProps
}) => {
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        borderRadius: '12px',
        boxShadow: `0px 5px 10px 0px rgba(30, 34, 40, 0.10), 0px 0px 1px 0px rgba(30, 34, 40, 0.08)`,
        '& .MuiAutocomplete-listbox': {
          padding: 0,
          ...(options.length && {
            maxHeight: '335px',
            overflowY: 'auto',
          }),
        },
      }}
      onMouseDown={(event) => event.preventDefault()}
      {...otherProps}
    >
      {(showSelectionLimitError || customTemplate) && (
        <TopBlockWrapper>
          {showSelectionLimitError && (
            <InformerBlock
              variant={InformerBlockVariants.Error}
              title={t('pages.comparisonChart.form.selectionLimitError', {
                selectionLimit,
              })}
              sx={{ width: '100%' }}
            />
          )}
          {!!customTemplate && (
            <InformerBlock
              variant={InformerBlockVariants.Success}
              sx={{
                width: '100%',
                flexDirection: { tablet: 'row' },
              }}
            >
              <CustomTemplateContentWrapper>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5,
                  }}
                >
                  <Typography variant="bodyMStrong">
                    {customTemplate.title}
                  </Typography>
                  <Typography variant="bodyS">
                    {customTemplate.subtitle}
                  </Typography>
                </Box>
                <CommonButton
                  size="small"
                  color="primary"
                  sx={{ width: 'fit-content', flexShrink: 0 }}
                  onClick={customTemplate.onSelect}
                >
                  {customTemplate.buttonText}
                </CommonButton>
              </CustomTemplateContentWrapper>
            </InformerBlock>
          )}
        </TopBlockWrapper>
      )}
      {children}
      <ButtonsWrapper>
        <CommonButton
          onClick={buttonsProps.apply.onApply}
          disabled={buttonsProps.apply.disabled}
        >
          {t('components.select.apply')}
        </CommonButton>
        <CommonButton
          variant="text"
          size="large"
          sx={{ textTransform: 'none' }}
          onClick={buttonsProps.clear.onClear}
        >
          {t('components.select.clearAll')}
        </CommonButton>
      </ButtonsWrapper>
    </Paper>
  );
};
